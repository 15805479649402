import {
  DEFAULT_API_ERROR_MESSAGE,
  EXPIRED_LINK,
  GMAIL_CONNECTION_ERROR,
  GMAIL_USER_NOT_FOUND,
  INCORRECT_LOGIN,
  INVALID_ARGUMENT,
  INVALID_ROLE,
  INVALID_TOKEN,
  LOGIN_WITH_EMAIL,
  NO_TOKEN,
  NO_USERS_FOUND,
  PASSWORDS_DO_NOT_MATCH,
  PROHIBITED,
  REQUIRED_EMAIL,
  REQUIRED_TOKEN,
  TOKEN_NOT_GENERATED,
  USED_INVALID_TOKEN,
  USER_ALREADY_EXIST,
  USER_DOES_NOT_EXIST,
  USER_INACTIVE,
  USER_NOT_REGISTERED,
  VERIFICATION_MAIL_ERROR,
  WRONG_CREDENTIALS,
} from '../utils/errors';

import {
  PASSWORD_UPDATED,
  RECOVER_PASSWORD_MAIL_SENT,
  SUCCESSFUL_LOGIN,
  USERS_DELETED,
  USERS_FOUND,
  USER_CREATED,
  USER_EDITED,
  USER_FOUND,
  USER_VERIFIED,
} from '../utils/success';

export default {
  'login.header': 'Bem-vindo ao TiL!',
  'login.header.subtitle': 'A Rede Social de Finanças',
  'login.title': 'Entrar',
  'login.subtitle': 'Faça login na plataforma de backoffice',
  'login.email': 'E-mail',
  'login.email.required': 'E-mail obrigatório',
  'login.email.invalidFormat': 'Formato de e-mail inválido',
  'login.password': 'Senha',
  'login.password.required': 'Senha obrigatória',
  'login.login': 'Entrar',
  'login.termsAndConditions': 'Termos e Condições',
  'login.missingAccount': 'Não tem uma conta?',
  'login.signUp': 'Cadastre-se',
  'login.or': 'ou',
  'login.continueGoogle': 'Entrar com Google',
  'login.forgottenPassword': 'Esqueceu a Senha?',
  'login.readTermsConditions.required': 'Você precisa aceitar os termos e condições antes de entrar',
  'login.registerUser.checkEmail': 'Usuário criado com sucesso. Verifique seu e-mail para ativar sua conta',
  'login.registerUser.invalidEmail': 'Formato de e-mail inválido',
  'login.registerUser.cannotBeEmpty': 'não pode estar vazio',
  'navigation.navbar.admin.header.title': 'Administração',
  'navigation.navbar.admin.subheader.title.users': 'Usuários',
  'navigation.topbar.account.account': 'Conta',
  'navigation.topbar.account.closeSession': 'Encerrar Sessão',
  'navigation.topbar.account.error.unableCloseSession': 'Não foi possível encerrar a sessão',
  'navigation.topbar.settings.title': 'Configurações',
  'navigation.topbar.settings.theme.title': 'Tema',
  'navigation.topbar.settings.language.title': 'Idioma',
  'navigation.topbar.settings.language.option.english': 'Inglês',
  'navigation.topbar.settings.language.option.spanish': 'Espanhol',
  'navigation.topbar.settings.save.button': 'Salvar',
  'noAuthorization.title': 'Sem autorização',
  'noAuthorization.firstMessage': 'Ops! Você não tem permissão para acessar esta página. Por favor, peça permissão ao administrador da sua conta, clique ',
  'noAuthorization.here': 'aqui',
  'noAuthorization.secondMessage': ' para ir à sua página principal, ou clique ',
  'noAuthorization.thirdMessage': ' para trocar de usuário.',
  'userInformation.signUpSaveButton': 'Registre-se',
  'userInformation.googleSignUpSaveButton': 'Registre-se com Google',
  'userInformation.editButton': 'Editar informações',
  'userInformation.error.name': 'Nome',
  'userInformation.error.lastname': 'Sobrenome',
  'userInformation.error.nickname': 'Apelido',
  'userInformation.error.country': 'País',
  'userInformation.error.email': 'E-mail',
  'userInformation.error.password': 'Senha',
  'userInformation.error.repeatedNickname': 'Apelido em uso. Escolha outro apelido',
  'userInformation.textFieldLabel.name': 'Nome *',
  'userInformation.textFieldLabel.lastname': 'Sobrenome *',
  'userInformation.textFieldLabel.nickname': 'Apelido *',
  'userInformation.textFieldLabel.country': 'País *',
  'userInformation.textFieldLabel.jobTitle': 'Cargo',
  'userInformation.textFieldLabel.company': 'Empresa',
  'userInformation.textFieldLabel.personalUrl': 'URL Pessoal',
  'userInformation.textFieldLabel.phone': 'Telefone',
  'userInformation.textFieldLabel.email': 'E-mail *',
  'userInformation.textFieldLabel.description': 'Descrição',
  'userInformation.randomAvatar.button': 'Novo avatar',
  'termsAndConditions.title': 'Termos e Condições',
  'termsAndConditions.disclaimer': 'Avisos Legais:',
  'termsAndConditions.noFinancialAdvice': 'Nenhum Conselho Financeiro Fornecido: ',
  'termsAndConditions.noFinancialAdviceParagraph': 'As informações fornecidas nesta plataforma, incluindo simulações ou materiais educativos, são apenas para fins informativos. Elas não constituem aconselhamento financeiro ou recomendações para comprar, vender ou negociar ativos digitais. Os usuários são exclusivamente responsáveis por suas decisões de investimento e devem procurar aconselhamento financeiro independente antes de participar de qualquer atividade de negociação.',
  'termsAndConditions.simulationEducation': 'Simulações para Fins Educativos: ',
  'termsAndConditions.simulationEducationParagraph': 'Qualquer simulação ou cenário de negociação apresentado nesta plataforma é apenas para fins educativos. Eles não são indicativos das condições reais do mercado ou de resultados garantidos. Os usuários não devem confiar apenas nessas simulações ao tomar decisões de investimento. A plataforma não garante a precisão, completude ou confiabilidade das simulações, e os usuários reconhecem que os resultados reais de negociação podem variar.',
  'termsAndConditions.userResponsibility': 'Responsabilidade do Usuário: ',
  'termsAndConditions.userResponsibilityParagraph': 'Os usuários desta plataforma são exclusivamente responsáveis por suas próprias atividades de negociação e decisões de investimento. A plataforma e seus proprietários não assumem qualquer responsabilidade por perdas incorridas como resultado da negociação de ativos digitais. Os usuários devem realizar uma pesquisa completa, considerar sua tolerância ao risco e buscar aconselhamento profissional antes de participar de qualquer atividade de negociação. A plataforma e seus proprietários não são responsáveis por quaisquer perdas financeiras ou danos decorrentes de ações ou decisões do usuário baseadas nas informações fornecidas na plataforma.',
  'termsAndConditions.beforeUsing': 'Antes de usar o TiL, esta plataforma, você reconhece e concorda que as Simulações do TiL são destinadas exclusivamente para fins educacionais e de simulação e não constituem aconselhamento ou recomendações de investimento. Embora as simulações sejam baseadas em dados reais, elas são de natureza educativa e não devem ser seguidas sem uma análise prévia por um consultor financeiro qualificado antes de implementar qualquer ideia de investimento. O desempenho passado não é indicativo de resultados futuros, e nossa plataforma não é responsável por quaisquer perdas resultantes da confiança em qualquer Simulação do TiL. Os usuários aceitam total responsabilidade por suas próprias escolhas de investimento e devem se abster de fornecer aconselhamento financeiro na plataforma. Ao usar nossa plataforma, você entende e concorda com esses termos, reconhecendo as limitações das ferramentas e do conteúdo fornecido.',
  'termsAndConditions.agreeButton': 'Concordo',
  'termsAndConditions.cancelButton': 'Cancelar',
  'userVerification.password.required': 'Senha obrigatória',
  'userVerification.password.oneLetter': 'A senha deve conter pelo menos uma letra',
  'userVerification.password.oneNumber': 'A senha deve conter pelo menos um número',
  'userVerification.password.tooShort': 'A senha deve ter pelo menos 8 caracteres',
  'userVerification.ConfirmPassword.required': 'Confirmação de senha obrigatória',
  'userVerification.ConfirmPassword.match': 'As senhas devem coincidir',
  'topbar.navigation.home': 'Início',
  'topbar.navigation.video': 'Vídeo',
  'topbar.navigation.myPortfolio': 'Meu Portfólio',
  'topbar.navigation.assets': 'Ativos',
  'topbar.navigation.network': 'Rede',
  'topbar.navigation.ranking': 'Classificação',
  'topbar.navigation.messages': 'Mensagens',
  'topbar.navigation.notifications': 'Notificações',
  'landing.nav.home': 'Início',
  'landing.nav.about': 'Sobre nós',
  'landing.nav.features': 'Funcionalidades',
  'landing.nav.testimonials': 'Depoimentos',
  'landing.nav.contact': 'Contato',
  'landing.welcome.title': 'Bem-vindo ao TiL',
  'landing.welcome.expansion': 'TradesiLike',
  'landing.welcome.slogan': 'A Rede Social de Finanças',
  'landing.welcome.message': 'Junte-se a nós nesta jornada de conhecimento financeiro. Gerencie portfólios virtuais, compita e interaja com outros usuários e aprenda a implementar estratégias financeiras eficazes',
  'landing.welcome.getStarted': 'Comece agora',
  'landing.about.question': 'O que é o TiL?',
  'landing.about.response': 'TiL é a Rede Social de Aprendizado em Finanças e Dinheiro. Nosso nome reflete nossa missão de tornar o aprendizado sobre finanças e negociação virtual acessível e agradável para todos. Nossa plataforma permite que os usuários gerenciem portfólios virtuais de ações, criptomoedas e commodities com preços em tempo real. A missão do TiL é socializar e democratizar as finanças pessoais, por meio de uma plataforma onde os usuários podem interagir com outros, compartilhar estratégias, aprender finanças de forma divertida e competir com outros em um jogo simulado contínuo.',
  'landing.features.title.part1': 'Jogue e aprenda com ',
  'landing.features.title.part2': ' e suas funcionalidades',
  'landing.features.firstFeature.title': 'Componentes Sociais',
  'landing.features.firstFeature.text': 'Mantenha-se conectado com amigos, acompanhe seu progresso, converse, compartilhe atualizações e muito mais.',
  'landing.features.secondFeature.title': 'Rankings Competitivos',
  'landing.features.secondFeature.text': 'Compita com outros usuários e veja como suas estratégias se comparam.',
  'landing.features.thirdFeature.title': 'Componentes Sociais',
  'landing.features.thirdtFeature.text': 'Experimente a gestão de portfólio em tempo real com atualizações de preços ao vivo.',
  'landing.features.fourthFeature.title': 'Comparação Justa',
  'landing.features.fourthFeature.text': 'Nossa fórmula exclusiva baseada em tempo garante uma comparação justa do desempenho do portfólio.',
  'landing.features.fifthFeature.title': 'Ativos Diversificados',
  'landing.features.fifthFeature.text': 'Gerencie um portfólio que inclui ações, criptomoedas e commodities.',
  'landing.features.sixthFeature.title': 'Foco Educacional',
  'landing.features.sixthFeature.text': 'Aprenda a obter retornos consistentes entendendo o momento certo e as estratégias para a recalibração do portfólio.',
  'landing.howWorks.title': 'Como o TiL Funciona?',
  'landing.howWorks.firstStep.title': 'Cadastre-se:',
  'landing.howWorks.firstStep.content': 'Crie sua conta gratuita em minutos.',
  'landing.howWorks.secondStep.title': 'Construa Seu Portfólio:',
  'landing.howWorks.secondStep.content': 'Escolha entre uma ampla variedade de ativos, incluindo ações, criptomoedas e commodities.',
  'landing.howWorks.thirdStep.title': 'Compita e Melhore:',
  'landing.howWorks.thirdStep.content': 'Compare seu desempenho com outros usando nosso sistema de classificação justa.',
  'landing.howWorks.fourthStep.title': 'Obtenha Novas Ideias:',
  'landing.howWorks.fourthStep.content': 'Compare as negociações que você gosta com os jogadores de melhor desempenho. Aprenda novos insights e obtenha novas ideias de suas estratégias mais bem-sucedidas.',
  'landing.testimonial.title': 'O Que Nossos Usuários Estão Dizendo:',
  'landing.testimonial.first.name': 'Gabriel Souza',
  'landing.testimonial.first.testimonial': '"O TiL me deu confiança para tomar decisões de investimento informadas. A comunidade é fantástica, e aprender aqui é natural e agradável."',
  'landing.testimonial.second.name': 'Sarah Miller',
  'landing.testimonial.second.content': '"O TiL mudou a forma como vejo as finanças pessoais. Aprender com os outros e aplicar o que aprendo tornou a educação financeira divertida e empoderadora."',
  'landing.testimonial.third.name': 'James Cooper',
  'landing.testimonial.third.content': '"O TiL me mantém engajado com seus recursos interativos. Participar de clubes financeiros e competir com outros me ajudou a aprender mais do que eu esperava."',
  'landing.testimonial.fourth.name': 'Priya Sharma',
  'landing.testimonial.fourth.content': '"Aprender finanças no TiL é prático e divertido. Os clubes e classificações me motivam a continuar melhorando meu entendimento sobre gestão financeira."',
  'landing.joinUs.title': 'Pronto para Elevar seu Jogo Financeiro?',
  'landing.joinUs.content': 'Junte-se à rede TiL hoje e comece sua jornada financeira rumo a um futuro com melhor potencial de aprendizado, diversão e finanças.',
  'landing.joinUs.buttonText': 'Crie Sua Conta Gratuita Agora',
  'landing.contactUs.title': 'Contate-nos:',
  'landing.contactUs.text': 'Para qualquer dúvida ou suporte, entre em contato conosco pelo e-mail support@tradesilike.com.',
  'landing.followUs.title': 'Siga-nos:',
  'landing.followUs.text': 'Fique atualizado nos seguindo em',
  'landing.clauses.start': 'Qualquer informação fornecida pelo TiL é exclusivamente para fins educacionais e de entretenimento e não constitui aconselhamento financeiro.',
  'landing.clauses.1': '1. Proibição de Declarações Falsas: Os usuários estão estritamente proibidos de fazer declarações falsas ou fornecer informações enganosas sobre qualquer ativo digital listado na plataforma. Isso inclui espalhar notícias falsas ou qualquer informação destinada a manipular o mercado. Usuários encontrados culpados de fazer declarações falsas enfrentarão suspensão imediata da conta.',
  'landing.clauses.2': '2. Proibição de Negociação com Informações Privilegiadas: Os usuários estão proibidos de realizar negociações com informações privilegiadas ou usar informações não públicas para negociar ativos digitais na plataforma. Qualquer usuário encontrado participando de atividades de negociação com informações privilegiadas terá sua conta encerrada.',
  'landing.clauses.3': '3. Proibição de Front Running: Os usuários estão estritamente proibidos de realizar atividades de front-running com base nos dados do TiL, o que envolve fazer ordens para se beneficiar de informações antecipadas sobre transações pendentes. Isso inclui negociar antes das ordens de clientes ou usar informações privilegiadas para obter uma vantagem injusta. Usuários encontrados realizando front-running enfrentarão penalidades severas, incluindo suspensão permanente da conta.',
  'landing.clauses.4': '4. Proibição de Wash Trading: Os usuários estão proibidos de realizar wash trading, que envolve criar uma atividade de negociação artificial ao comprar e vender simultaneamente o mesmo ativo digital. Wash trading é estritamente proibido, pois distorce os preços do mercado e engana outros usuários.',
  'landing.clauses.5': '5. Proibição de Spoofing: Os usuários estão proibidos de realizar spoofing, que envolve fazer uma grande ordem para criar uma falsa impressão de oferta ou demanda, e depois cancelar a ordem. Essa tática manipulativa é estritamente proibida, e usuários encontrados realizando spoofing enfrentarão penalidades severas, incluindo suspensão da conta.',
  'landing.clauses.6': '6. Proibição de Cornering de Mercado: Os usuários estão proibidos de tentar manipular o mercado ao dominar ou controlar uma parte significativa de um ativo específico. Qualquer usuário encontrado tentando fazer cornering no mercado pode enfrentar penalidades severas, incluindo suspensão da conta.',
  'landing.clauses.7': '7. Obrigações de Relato: Os usuários são obrigados a relatar prontamente qualquer atividade suspeita ou tentativa de manipulação do mercado que encontrem enquanto utilizam a plataforma. Isso inclui relatar qualquer caso potencial de manipulação de mercado, negociação com informações privilegiadas ou outras atividades manipulativas. O não relato dessas atividades pode resultar em penalidades, incluindo responsabilidade por danos causados pela manipulação.',
  'landing.clauses.8': '8. Cooperação com Autoridades Regulatórias: Os usuários devem cooperar plenamente com as autoridades regulatórias em quaisquer investigações relacionadas à manipulação de mercado ou atividades fraudulentas. Isso inclui fornecer as informações solicitadas e auxiliar em qualquer processo de investigação.',
  'landing.clauses.9': '9. Monitoramento Contínuo: A plataforma implementará sistemas e procedimentos robustos para monitorar continuamente as atividades dos usuários em busca de sinais de manipulação de mercado, mesmo sendo esta simulação um jogo virtual. Isso pode incluir o uso de tecnologias avançadas de vigilância e técnicas de análise de dados para identificar padrões ou comportamentos suspeitos.',
  'landing.clauses.10': '10. Educação e Conscientização dos Usuários: A plataforma educará ativamente os usuários sobre os riscos de manipulação de mercado e a importância de manter mercados justos e transparentes. Isso pode incluir o fornecimento de recursos educacionais, diretrizes e comunicação regular para garantir que os usuários estejam cientes de suas responsabilidades e das consequências de participar de manipulação de mercado.',
  'landing.clauses.dataProtection': 'Proteção de Dados: A plataforma TiL está comprometida em garantir a proteção dos dados dos usuários, mantendo um nível adequado de privacidade. A TiL opera em estrita conformidade com todas as regulamentações locais aplicáveis. Além disso, a TiL está dedicada a prevenir qualquer prática anticoncorrencial, mantendo os mais altos padrões de conduta legal e ética.',
  'post.likes': 'Curtidas',
  'post.like': 'Curtir',
  'post.comments': 'Comentários',
  'post.comment': 'Comentar',
  'post.share': 'Compartilhar',
  'post.add': 'Add publicação...',
  'post.error.sendComment': 'Não foi possível enviar seu comentário. Por favor, tente novamente mais tarde.',
  'post.error.sendReply': 'Não foi possível enviar sua resposta. Por favor, tente novamente mais tarde.',
  'post.error.getCommentQty': 'Não foi possível obter o número de comentários. Por favor, tente novamente mais tarde.',
  'post.error.getComments': 'Não foi possível obter os comentários. Por favor, tente novamente mais tarde.',
  'post.error.publishFailed': 'Ocorreu um erro ao publicar sua postagem. Por favor, tente novamente em instantes.',
  'post.success.published': 'Sua postagem foi publicada com sucesso!',
  'post.error.invalidImage': 'Por favor, suba uma imagem',
  'post.linkCopied': 'Link copiado para a área de transferência!',
  'portfolioGraph.seeMore.button': 'Ver mais',
  'home.error.unableToGetPosts': 'As últimas postagens não puderam ser carregadas. Por favor, tente novamente em um momento',
  'home.error.unableToGetDailyMovers': 'Preços dos movimentos diários não estão disponíveis',
  'home.error.unableToGetPortfolios': 'Portfólios não puderam ser carregados. Por favor, tente novamente em um momento.',
  'home.portfolio.createPortfolio': 'Criar Portfólio',
  'home.portfolio.createPortfolioSubtitle': 'Para começar sua jornada de trading, você precisa criar um portfólio',
  'home.portfolio.portfolioName': 'Nome do Portfólio',
  'home.portfolio.benchmark': 'Benchmark',
  'home.portfolio.noBenchmarks': 'Benchmarks não encontrados',
  'home.error.unableToCreatePortfolio': 'Portfólio não pôde ser criado. Por favor, tente novamente em um momento',
  'home.error.unableToGetRankingPortfolios': 'Portfólios do ranking não puderam ser carregados. Por favor, tente novamente em um momento',
  'home.error.unableToGetHomeGraphInformation': 'Informações do gráfico de início não puderam ser carregadas. Por favor, tente novamente em um momento',
  'home.success.portfolioCreated': 'Portfólio criado. Você está pronto para começar!',
  'home.noMorePosts': 'Não há mais postagens',
  'groups.of': 'de',
  'groups.error': 'Não foi possível carregar os grupos. Por favor, tente novamente em um momento',
  'comment.add': 'Adicionar comentário...',
  'comment.reply': 'Responder',
  'rankingList.noPortfoliosFound': 'Não há portfólios para este período de tempo',
  'share.title': 'Postagem Compartilhada',
  'topbar.goToLogin': 'Ir para Login',
  // API ERRORS
  [NO_TOKEN]: NO_TOKEN,
  [INVALID_TOKEN]: INVALID_TOKEN,
  [INVALID_ROLE]: INVALID_ROLE,
  [REQUIRED_TOKEN]: REQUIRED_TOKEN,
  [EXPIRED_LINK]: EXPIRED_LINK,
  [PROHIBITED]: PROHIBITED,
  [USED_INVALID_TOKEN]: USED_INVALID_TOKEN,
  [USER_INACTIVE]: USER_INACTIVE,
  [USER_DOES_NOT_EXIST]: USER_DOES_NOT_EXIST,
  [WRONG_CREDENTIALS]: WRONG_CREDENTIALS,
  [PASSWORDS_DO_NOT_MATCH]: PASSWORDS_DO_NOT_MATCH,
  [TOKEN_NOT_GENERATED]: TOKEN_NOT_GENERATED,
  [REQUIRED_EMAIL]: REQUIRED_EMAIL,
  [USER_NOT_REGISTERED]: USER_NOT_REGISTERED,
  [VERIFICATION_MAIL_ERROR]: VERIFICATION_MAIL_ERROR,
  [GMAIL_USER_NOT_FOUND]: GMAIL_USER_NOT_FOUND,
  [GMAIL_CONNECTION_ERROR]: GMAIL_CONNECTION_ERROR,
  [USER_ALREADY_EXIST]: USER_ALREADY_EXIST,
  [INVALID_ARGUMENT]: INVALID_ARGUMENT,
  [NO_USERS_FOUND]: NO_USERS_FOUND,
  [LOGIN_WITH_EMAIL]: LOGIN_WITH_EMAIL,
  [INCORRECT_LOGIN]: INCORRECT_LOGIN,
  [DEFAULT_API_ERROR_MESSAGE]: DEFAULT_API_ERROR_MESSAGE,
  // API SUCCESS
  [USER_VERIFIED]: USER_VERIFIED,
  [SUCCESSFUL_LOGIN]: SUCCESSFUL_LOGIN,
  [RECOVER_PASSWORD_MAIL_SENT]: RECOVER_PASSWORD_MAIL_SENT,
  [PASSWORD_UPDATED]: PASSWORD_UPDATED,
  [USER_CREATED]: USER_CREATED,
  [USERS_FOUND]: USERS_FOUND,
  [USER_EDITED]: USER_EDITED,
  [USER_FOUND]: USER_FOUND,
  [USERS_DELETED]: USERS_DELETED,
};
