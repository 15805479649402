import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  portfolioModalContentTitle: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  portfolioModalContentSubtitle: {
    color: theme.palette.primary.main,
  },
  portfolioModalContentDivider: {
    borderWidth: 1.5,
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(2)} 0`,
    width: '100%',
  },
  portfolioModalContentButtonContainer: {
    marginTop: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  portfolioModalContentButton: {
    textTransform: 'capitalize',
    maxWidth: '200px',
  },
}));

export default useStyles;
