const getOrdinalSuffix = (num: number, language: string): string => {
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (language === 'es') {
    switch (lastDigit) {
      case 1:
        return `${num}ero`;
      case 2:
        return `${num}do`;
      case 3:
        return `${num}ero`;
      case 4:
        return `${num}to`;
      case 5:
        return `${num}to`;
      case 6:
        return `${num}to`;
      case 7:
        return `${num}mo`;
      case 8:
        return `${num}vo`;
      case 9:
        return `${num}no`;
      default:
        return `${num}mo`;
    }
  }

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${num}th`;
  }

  switch (lastDigit) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export default getOrdinalSuffix;
