/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UserSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  _id?: string;
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "John" */
  name?: string;
  /** @example "Doe" */
  lastname?: string;
  /** @example "example@gmail.com" */
  email?: string;
  /** @example null */
  password?: string | null;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  userVerificationToken?: string;
  /** @example null */
  resetPasswordToken?: string | null;
  /** @example "jdoe" */
  nickname: string;
  /** @example "user" */
  role?: string;
  /** @example false */
  active?: boolean;
  /** @example null */
  googleId?: string | null;
  /** @example "Colombia" */
  country: string;
  /** @example null */
  personalUrl?: string | null;
  /** @example null */
  jobTitle?: string | null;
  /** @example null */
  company?: string | null;
  /** @example false */
  deleted?: boolean;
  /** @example "https://avatar.iran.liara.run/public/boy" */
  avatarUrl: string;
  /**
   * @format date-time
   * @example "2023-03-07T19:43:46.303Z"
   */
  createdAt?: string;
  /**
   * @format date-time
   * @example "2023-03-07T19:43:46.303Z"
   */
  updatedAt?: string;
}

export interface UpdateUserSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  id?: string;
  /** @example "María" */
  name?: string;
  /** @example "Rodríguez" */
  lastname?: string;
  /** @example true */
  active?: boolean;
  /** @example "user" */
  role?: string;
}

export interface UpdateUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario editado exitosamente" */
  message?: string;
  editedUser?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "example@gmail.com" */
    email?: string;
    /** @example null */
    password?: string | null;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example "jdoe" */
    nickname: string;
    /** @example "user" */
    role?: string;
    /** @example false */
    active?: boolean;
    /** @example null */
    googleId?: string | null;
    /** @example "Colombia" */
    country: string;
    /** @example null */
    personalUrl?: string | null;
    /** @example null */
    jobTitle?: string | null;
    /** @example null */
    company?: string | null;
    /** @example false */
    deleted?: boolean;
    /** @example "https://avatar.iran.liara.run/public/boy" */
    avatarUrl: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  };
}

export interface GetUsersPerPageResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuarios encontrados exitosamente" */
  message?: string;
  users?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "example@gmail.com" */
    email?: string;
    /** @example null */
    password?: string | null;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example "jdoe" */
    nickname: string;
    /** @example "user" */
    role?: string;
    /** @example false */
    active?: boolean;
    /** @example null */
    googleId?: string | null;
    /** @example "Colombia" */
    country: string;
    /** @example null */
    personalUrl?: string | null;
    /** @example null */
    jobTitle?: string | null;
    /** @example null */
    company?: string | null;
    /** @example false */
    deleted?: boolean;
    /** @example "https://avatar.iran.liara.run/public/boy" */
    avatarUrl: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  }[];
  /** @example 1 */
  totalUsers?: number;
}

export interface DeleteUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario/s eliminado/s exitosamente" */
  message?: string;
}

export interface GetUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario encontrado" */
  message?: string;
  user?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "example@gmail.com" */
    email?: string;
    /** @example null */
    password?: string | null;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example "jdoe" */
    nickname: string;
    /** @example "user" */
    role?: string;
    /** @example false */
    active?: boolean;
    /** @example null */
    googleId?: string | null;
    /** @example "Colombia" */
    country: string;
    /** @example null */
    personalUrl?: string | null;
    /** @example null */
    jobTitle?: string | null;
    /** @example null */
    company?: string | null;
    /** @example false */
    deleted?: boolean;
    /** @example "https://avatar.iran.liara.run/public/boy" */
    avatarUrl: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  };
}

export interface GetUsersNicknamesResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuarios encontrados exitosamente" */
  message?: string;
  allUsersNicknames?: {
    id?: string;
    nickname?: string;
  }[];
}

export interface RegisterRequest {
  /**
   * @minLength 3
   * @maxLength 255
   * @pattern ^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "María"
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "Rodríguez"
   */
  lastname: string;
  /** @example "@algoTrader" */
  nickname: string;
  country: string;
  /** @example "user" */
  role?: string;
  personalUrl?: string | null;
  /** @example "Software Engineer" */
  jobTitle?: string | null;
  company?: string | null;
  phone?: string | null;
  /** @example "New trader on the block" */
  description?: string | null;
  avatarUrl?: string | null;
  /** @example "709uajdsknfanifd=" */
  googleId?: string | null;
}

export interface PutDeletedUserRequest {
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "María"
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 255
   * @pattern ^[a-zA-Z0-9]
   * @example "Rodríguez"
   */
  lastname: string;
  /** @example "user" */
  role?: string;
}

export interface LoginRequest {
  /**
   * @minLength 3
   * @maxLength 255
   * @pattern ^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
}

export interface VerificationRequest {
  /**
   * @minLength 8
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
  /** @example "password123" */
  confirmPassword: any;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token: string;
}

export interface ResetPasswordRequest {
  /**
   * @minLength 8
   * @pattern ^[a-zA-Z0-9]
   * @example "password123"
   */
  password: string;
  /** @example "password123" */
  confirmPassword: any;
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token: string;
}

export interface RecoverPasswordRequest {
  /**
   * Email de usuario
   * @format email
   * @minLength 3
   * @maxLength 255
   */
  email: string;
}

export interface PostGmailLoginRequest {
  /**
   * Access Token desde Gmail
   * @example "TODO"
   */
  accessToken: string;
}

export interface RegisterResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario creado con éxito" */
  message?: string;
  user?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "example@gmail.com" */
    email?: string;
    /** @example null */
    password?: string | null;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example "jdoe" */
    nickname: string;
    /** @example "user" */
    role?: string;
    /** @example false */
    active?: boolean;
    /** @example null */
    googleId?: string | null;
    /** @example "Colombia" */
    country: string;
    /** @example null */
    personalUrl?: string | null;
    /** @example null */
    jobTitle?: string | null;
    /** @example null */
    company?: string | null;
    /** @example false */
    deleted?: boolean;
    /** @example "https://avatar.iran.liara.run/public/boy" */
    avatarUrl: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  };
  /** @example "Usuario creado con éxito" */
  token?: string;
}

export interface LoginResponse {
  /** @example true */
  success?: boolean;
  /** @example "Login exitoso" */
  message?: string;
  user?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "example@gmail.com" */
    email?: string;
    /** @example null */
    password?: string | null;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example "jdoe" */
    nickname: string;
    /** @example "user" */
    role?: string;
    /** @example false */
    active?: boolean;
    /** @example null */
    googleId?: string | null;
    /** @example "Colombia" */
    country: string;
    /** @example null */
    personalUrl?: string | null;
    /** @example null */
    jobTitle?: string | null;
    /** @example null */
    company?: string | null;
    /** @example false */
    deleted?: boolean;
    /** @example "https://avatar.iran.liara.run/public/boy" */
    avatarUrl: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  };
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token?: string;
}

export interface ErrorResponse {
  /** @example true */
  success?: boolean;
  /** @example "Login fallido" */
  message?: string;
  /** @example "Error en el servidor" */
  errorMessage?: string;
}

export interface VerificationResponse {
  /** @example true */
  success?: boolean;
  /** @example "Usuario verificado correctamente" */
  message?: string;
}

export interface ResetPasswordResponse {
  /** @example true */
  success?: boolean;
  /** @example "Contraseña actualizada" */
  message?: string;
}

export interface RecoverPasswordResponse {
  /** @example true */
  success?: boolean;
  /** @example "Correo de recuperar contraseña enviado" */
  message?: string;
}

export interface PostGmailLoginResponse {
  /** @example true */
  success?: boolean;
  /** @example "Login con Gmail exitoso" */
  message?: string;
  gmailInformation?: {
    id: string;
    /** @format email */
    email: string;
    verified_email: boolean;
    name: string;
    given_name: string;
    family_name: string;
    picture?: string;
    /**
     * @minLength 2
     * @maxLength 2
     */
    locale: string;
    hd?: string;
  };
  user?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "example@gmail.com" */
    email?: string;
    /** @example null */
    password?: string | null;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example "jdoe" */
    nickname: string;
    /** @example "user" */
    role?: string;
    /** @example false */
    active?: boolean;
    /** @example null */
    googleId?: string | null;
    /** @example "Colombia" */
    country: string;
    /** @example null */
    personalUrl?: string | null;
    /** @example null */
    jobTitle?: string | null;
    /** @example null */
    company?: string | null;
    /** @example false */
    deleted?: boolean;
    /** @example "https://avatar.iran.liara.run/public/boy" */
    avatarUrl: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  };
  /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
  token?: string;
}

export interface PostSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  userId: string;
  /** @example "669814726c9ca473fff3ee4d" */
  groupId?: string;
  text: string;
  /** @example "669814726c9ca473fff3ee4d" */
  _id: string;
  /** @example "669814726c9ca473fff3ee4d" */
  id: string;
  imageUrl?: string;
  videoUrl?: string;
  /** @default false */
  deleted?: boolean;
  /**
   * @format date-time
   * @example "2024-09-28T02:28:13.054875+00:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2024-09-28T02:28:13.054875+00:00"
   */
  updatedAt: string;
}

export interface GetPostPerPageAggregateSchema {
  id: string;
  text: string;
  imageUrl?: string;
  videoUrl?: string;
  user: {
    /** @example "669814726c9ca473fff3ee4d" */
    id: string;
    /** @example "John" */
    name: string;
    /** @example "Doe" */
    lastname: string;
    /** @example "jdoe" */
    nickname: string;
    /** @example "https://avatar.iran.liara.run/public/boy" */
    avatarUrl: string;
  };
  /** @min 0 */
  likeCount: number;
  /** @min 0 */
  commentCount: number;
  /** @format date-time */
  createdAt: string;
  likedByCurrentUser: boolean;
}

export interface GetPostsPerPageSchema {
  /**
   * @format float
   * @example 10
   */
  limit: number;
  /**
   * @format float
   * @example 0
   */
  page: number;
  /** @example "669814726c9ca473fff3ee4d" */
  userId: string;
}

export interface PostPostSchema {
  file?: any;
  data: string;
}

export interface PutLikesOnPostSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  userId: string;
  /** @example "669814726c9ca473fff3ee4d" */
  postId: string;
}

export interface GetPostByIdSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  postId: string;
}

export interface GetPostsPerPageResponse {
  /** @example true */
  success: boolean;
  /** @example "Publicaciones encontradas exitosamente" */
  message: string;
  posts: {
    id: string;
    text: string;
    imageUrl?: string;
    videoUrl?: string;
    user: {
      /** @example "669814726c9ca473fff3ee4d" */
      id: string;
      /** @example "John" */
      name: string;
      /** @example "Doe" */
      lastname: string;
      /** @example "jdoe" */
      nickname: string;
      /** @example "https://avatar.iran.liara.run/public/boy" */
      avatarUrl: string;
    };
    /** @min 0 */
    likeCount: number;
    /** @min 0 */
    commentCount: number;
    /** @format date-time */
    createdAt: string;
    likedByCurrentUser: boolean;
  }[];
}

export interface PostPostResponse {
  /** @example true */
  success?: boolean;
  /** @example "Publicación creada exitosamente" */
  message?: string;
  post?: {
    /** @example "669814726c9ca473fff3ee4d" */
    userId: string;
    /** @example "669814726c9ca473fff3ee4d" */
    groupId?: string;
    text: string;
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id: string;
    imageUrl?: string;
    videoUrl?: string;
    /** @default false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2024-09-28T02:28:13.054875+00:00"
     */
    createdAt: string;
    /**
     * @format date-time
     * @example "2024-09-28T02:28:13.054875+00:00"
     */
    updatedAt: string;
  }[];
}

export interface PutLikesOnPostResponse {
  /** @example true */
  success?: boolean;
  /** @example "Publicación creada exitosamente" */
  message?: string;
  isLikedByUser?: boolean;
}

export interface GetPostByIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Publicación encontrada exitosamente" */
  message?: string;
  post?: {
    /** @example "669814726c9ca473fff3ee4d" */
    userId: string;
    /** @example "669814726c9ca473fff3ee4d" */
    groupId?: string;
    text: string;
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id: string;
    imageUrl?: string;
    videoUrl?: string;
    /** @default false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2024-09-28T02:28:13.054875+00:00"
     */
    createdAt: string;
    /**
     * @format date-time
     * @example "2024-09-28T02:28:13.054875+00:00"
     */
    updatedAt: string;
    user: {
      /** @example "669814726c9ca473fff3ee4d" */
      id: string;
      /** @example "John" */
      name: string;
      /** @example "Doe" */
      lastname: string;
      /** @example "jdoe" */
      nickname: string;
      /** @example "https://avatar.iran.liara.run/public/boy" */
      avatarUrl: string;
    };
    /** @min 0 */
    likeCount: number;
    /** @min 0 */
    commentCount: number;
  };
}

export interface AssetSchema {
  /** @example "669814726c9ca473fff3ee4d" */
  _id: string;
  /** @example "669814726c9ca473fff3ee4d" */
  id: string;
  /** @example "Apple Inc." */
  name: string;
  /**
   * @format float
   * @example 21
   */
  currentPrice: number;
  /**
   * @format float
   * @example 21
   */
  lastPrice: number;
  /** @example "AAPL" */
  ticker: string;
  /** @example "stock" */
  type: "stock" | "crypto" | "commodity";
  /**
   * @format date-time
   * @example "2024-10-08T08:00:00Z"
   */
  createdAt?: string;
  /**
   * @format date-time
   * @example "2024-10-08T08:00:00Z"
   */
  updatedAt?: string;
}

export interface GetAssetSchema {
  /**
   * @format float
   * @example 10
   */
  quantity: number;
}

export interface GetAssetsResponse {
  /** @example true */
  success?: boolean;
  /** @example "encontrados exitosamente" */
  message?: string;
  assets: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id: string;
    /** @example "Apple Inc." */
    name: string;
    /**
     * @format float
     * @example 21
     */
    currentPrice: number;
    /**
     * @format float
     * @example 21
     */
    lastPrice: number;
    /** @example "AAPL" */
    ticker: string;
    /** @example "stock" */
    type: "stock" | "crypto" | "commodity";
    /**
     * @format date-time
     * @example "2024-10-08T08:00:00Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2024-10-08T08:00:00Z"
     */
    updatedAt?: string;
  }[];
}

export interface GetAssetsForBenchmarkResponse {
  /** @example true */
  success?: boolean;
  /** @example "encontrados exitosamente" */
  message?: string;
  assets: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id: string;
    /** @example "Apple Inc." */
    name: string;
    /**
     * @format float
     * @example 21
     */
    currentPrice: number;
    /**
     * @format float
     * @example 21
     */
    lastPrice: number;
    /** @example "AAPL" */
    ticker: string;
    /** @example "stock" */
    type: "stock" | "crypto" | "commodity";
    /**
     * @format date-time
     * @example "2024-10-08T08:00:00Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2024-10-08T08:00:00Z"
     */
    updatedAt?: string;
  }[];
}

export interface HoldingSchema {
  _id: string;
  portfolio: string;
  asset: string;
  /** @format float */
  quantity: number;
  /** @format float */
  averagePrice: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface HoldingTimeFrameSchema {
  _id: string;
  portfolio: string;
  asset: string;
  /** @format float */
  quantity: number;
  /** @format float */
  averagePrice: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  dateTime: string;
}

export interface PortafolioSchema {
  /** @example "670454a766131532a450a5fa" */
  _id: string;
  /** @example "670454a766131532a450a5fa" */
  id: string;
  userId: string;
  benchmarkId?: string;
  name: string;
  /** @default false */
  deleted: boolean;
  /**
   * @format date-time
   * @example "2023-10-01T00:00:00Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2023-10-01T00:00:00Z"
   */
  updatedAt: string;
  latestHoldings?: {
    timeFrame: string;
    assetId?: string;
    /** @format float */
    quantity: number;
    /** @format float */
    averagePrice: number;
  }[];
}

export interface GetPortfoliosFromUserSchema {
  userId: string;
}

export interface PostPortfolioSchema {
  userId: string;
  benchmarkId: string;
  name: string;
}

export interface GetPortfoliosForRankingSchema {
  timeFrame: "1d" | "1w" | "1m" | "1y";
}

export interface GetPortfolioHomeGraphInformationSchema {
  timeFrame: "1d" | "1w" | "1m" | "1y";
  /** @example "670454a766131532a450a5fa" */
  userId: string;
}

export interface GetPortfoliosFromUserResponse {
  /** @example true */
  success?: boolean;
  /** @example "Portafolios encontrados exitosamente" */
  message?: string;
  portfolios?: {
    /** @example "670454a766131532a450a5fa" */
    _id: string;
    /** @example "670454a766131532a450a5fa" */
    id: string;
    userId: string;
    benchmarkId?: string;
    name: string;
    /** @default false */
    deleted: boolean;
    /**
     * @format date-time
     * @example "2023-10-01T00:00:00Z"
     */
    createdAt: string;
    /**
     * @format date-time
     * @example "2023-10-01T00:00:00Z"
     */
    updatedAt: string;
    latestHoldings?: {
      timeFrame: string;
      assetId?: string;
      /** @format float */
      quantity: number;
      /** @format float */
      averagePrice: number;
    }[];
    user: {
      /** @example "669814726c9ca473fff3ee4d" */
      id: string;
      /** @example "jdoe" */
      nickname: string;
      /** @example "Colombia" */
      country: string;
      /** @example "https://avatar.iran.liara.run/public/boy" */
      avatarUrl: string;
    };
  }[];
}

export interface PostPortfolioResponse {
  /** @example true */
  success?: boolean;
  /** @example "Portafolio creado exitosamente" */
  message?: string;
  portfolio?: {
    /** @example "670454a766131532a450a5fa" */
    _id: string;
    /** @example "670454a766131532a450a5fa" */
    id: string;
    userId: string;
    benchmarkId?: string;
    name: string;
    /** @default false */
    deleted: boolean;
    /**
     * @format date-time
     * @example "2023-10-01T00:00:00Z"
     */
    createdAt: string;
    /**
     * @format date-time
     * @example "2023-10-01T00:00:00Z"
     */
    updatedAt: string;
    latestHoldings?: {
      timeFrame: string;
      assetId?: string;
      /** @format float */
      quantity: number;
      /** @format float */
      averagePrice: number;
    }[];
  };
}

export interface GetPortfoliosForRankingResponse {
  /** @example true */
  success?: boolean;
  /** @example "Portafolios encontrados exitosamente" */
  message?: string;
  portfolios?: {
    id: string;
    name: string;
    user: {
      /** @example "669814726c9ca473fff3ee4d" */
      id: string;
      /** @example "jdoe" */
      nickname: string;
      /** @example "Colombia" */
      country: string;
      /** @example "https://avatar.iran.liara.run/public/boy" */
      avatarUrl: string;
    };
    /** @format float */
    change: number;
    /** @format float */
    balance: number;
  }[];
}

export interface GetPortfolioHomeGraphInformationResponse {
  /** @example true */
  success?: boolean;
  /** @example "Información de gráfico obtenida exitosamente" */
  message?: string;
  portfolios?: Record<
    string,
    Record<
      string,
      {
        /** @format float */
        portfolio?: number;
        /** @format float */
        benchmark?: number;
      }
    >
  >[];
}

export interface GroupUserRankingSchema {
  name: string;
  /**
   * @format float
   * @default 1
   */
  groupSize?: number;
  members?: {
    id: string;
    avatarUrl: string;
  }[];
}

export interface GetUserGroupRankingSchema {
  /** @example "67059052ecf2599a9a546825" */
  userId: string;
}

export interface GetUserGroupsRankingResponse {
  /** @example true */
  success?: boolean;
  /** @example "Ranking de grupos encontrados exitosamente" */
  message?: string;
  groups?: {
    name: string;
    /**
     * @format float
     * @default 1
     */
    groupSize?: number;
    members?: {
      id: string;
      avatarUrl: string;
    }[];
  }[];
}

export interface CommentSchema {
  _id: string;
  userId: string;
  postId: string;
  groupId: string;
  parentCommentId: string | null;
  content: string;
  /** @default false */
  deleted?: boolean;
  /**
   * @format date-time
   * @example "2024-09-28T02:28:13.054875+00:00"
   */
  createdAt?: string;
  /**
   * @format date-time
   * @example "2024-09-28T02:28:13.054875+00:00"
   */
  updatedAt?: string;
}

export interface ReplySchema {
  _id: string;
  userId: string;
  postId: string;
  groupId: string;
  parentCommentId: string | null;
  content: string;
  /** @default false */
  deleted?: boolean;
  /**
   * @format date-time
   * @example "2024-09-28T02:28:13.054875+00:00"
   */
  createdAt?: string;
  /**
   * @format date-time
   * @example "2024-09-28T02:28:13.054875+00:00"
   */
  updatedAt?: string;
  replyUserDetails?: {
    /** @example "669814726c9ca473fff3ee4d" */
    _id?: string;
    /** @example "669814726c9ca473fff3ee4d" */
    id?: string;
    /** @example "John" */
    name?: string;
    /** @example "Doe" */
    lastname?: string;
    /** @example "example@gmail.com" */
    email?: string;
    /** @example null */
    password?: string | null;
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
    userVerificationToken?: string;
    /** @example null */
    resetPasswordToken?: string | null;
    /** @example "jdoe" */
    nickname: string;
    /** @example "user" */
    role?: string;
    /** @example false */
    active?: boolean;
    /** @example null */
    googleId?: string | null;
    /** @example "Colombia" */
    country: string;
    /** @example null */
    personalUrl?: string | null;
    /** @example null */
    jobTitle?: string | null;
    /** @example null */
    company?: string | null;
    /** @example false */
    deleted?: boolean;
    /** @example "https://avatar.iran.liara.run/public/boy" */
    avatarUrl: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2023-03-07T19:43:46.303Z"
     */
    updatedAt?: string;
  };
}

export interface GetCommentsByPostSchema {
  postId: string;
}

export interface PostCommentSchema {
  userId?: string;
  postId?: string;
  groupId?: string;
  parentCommentId?: string | null;
  content: string;
}

export interface GetCommentQtyByPostIdSchema {
  postId: string;
}

export interface GetCommentsByPostResponse {
  /** @example true */
  success?: boolean;
  /** @example "Comentarios del post encontrados exitosamente" */
  message?: string;
  comments?: {
    _id: string;
    userId: string;
    postId: string;
    groupId: string;
    parentCommentId: string | null;
    content: string;
    /** @default false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2024-09-28T02:28:13.054875+00:00"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2024-09-28T02:28:13.054875+00:00"
     */
    updatedAt?: string;
    replies: {
      _id: string;
      userId: string;
      postId: string;
      groupId: string;
      parentCommentId: string | null;
      content: string;
      /** @default false */
      deleted?: boolean;
      /**
       * @format date-time
       * @example "2024-09-28T02:28:13.054875+00:00"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @example "2024-09-28T02:28:13.054875+00:00"
       */
      updatedAt?: string;
      replyUserDetails?: {
        /** @example "669814726c9ca473fff3ee4d" */
        _id?: string;
        /** @example "669814726c9ca473fff3ee4d" */
        id?: string;
        /** @example "John" */
        name?: string;
        /** @example "Doe" */
        lastname?: string;
        /** @example "example@gmail.com" */
        email?: string;
        /** @example null */
        password?: string | null;
        /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
        userVerificationToken?: string;
        /** @example null */
        resetPasswordToken?: string | null;
        /** @example "jdoe" */
        nickname: string;
        /** @example "user" */
        role?: string;
        /** @example false */
        active?: boolean;
        /** @example null */
        googleId?: string | null;
        /** @example "Colombia" */
        country: string;
        /** @example null */
        personalUrl?: string | null;
        /** @example null */
        jobTitle?: string | null;
        /** @example null */
        company?: string | null;
        /** @example false */
        deleted?: boolean;
        /** @example "https://avatar.iran.liara.run/public/boy" */
        avatarUrl: string;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        createdAt?: string;
        /**
         * @format date-time
         * @example "2023-03-07T19:43:46.303Z"
         */
        updatedAt?: string;
      };
    }[];
    userDetails?: {
      /** @example "669814726c9ca473fff3ee4d" */
      _id?: string;
      /** @example "669814726c9ca473fff3ee4d" */
      id?: string;
      /** @example "John" */
      name?: string;
      /** @example "Doe" */
      lastname?: string;
      /** @example "example@gmail.com" */
      email?: string;
      /** @example null */
      password?: string | null;
      /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2Nzg4MjMwMjYyOTgsImVtYWlsIjoiZXhlQGdtYWlsLmNvbSIsImlhdCI6MTY3ODIxODIyNn0.i95FbUjB5dysL1M8WJOtnRojiQrxsn1Mj7r57339qug" */
      userVerificationToken?: string;
      /** @example null */
      resetPasswordToken?: string | null;
      /** @example "jdoe" */
      nickname: string;
      /** @example "user" */
      role?: string;
      /** @example false */
      active?: boolean;
      /** @example null */
      googleId?: string | null;
      /** @example "Colombia" */
      country: string;
      /** @example null */
      personalUrl?: string | null;
      /** @example null */
      jobTitle?: string | null;
      /** @example null */
      company?: string | null;
      /** @example false */
      deleted?: boolean;
      /** @example "https://avatar.iran.liara.run/public/boy" */
      avatarUrl: string;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      createdAt?: string;
      /**
       * @format date-time
       * @example "2023-03-07T19:43:46.303Z"
       */
      updatedAt?: string;
    };
  }[];
}

export interface PostCommentResponse {
  /** @example true */
  success?: boolean;
  /** @example "Comentario creado exitosamente" */
  message?: string;
  comment?: {
    _id: string;
    userId: string;
    postId: string;
    groupId: string;
    parentCommentId: string | null;
    content: string;
    /** @default false */
    deleted?: boolean;
    /**
     * @format date-time
     * @example "2024-09-28T02:28:13.054875+00:00"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2024-09-28T02:28:13.054875+00:00"
     */
    updatedAt?: string;
  }[];
}

export interface GetCommentQtyByPostIdResponse {
  /** @example true */
  success?: boolean;
  /** @example "Comentario creado exitosamente" */
  message?: string;
  /** @format float */
  commentQty: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Template REST API Docs
 * @version 0.1.2
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  asset = {
    /**
     * @description Get a quantity of assets
     *
     * @tags Asset
     * @name AssetList
     * @request GET:/asset
     * @secure
     */
    assetList: (
      query?: {
        quantity?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAssetsResponse, void>({
        path: `/asset`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  assetsBenchmark = {
    /**
     * @description Get assets for benchmark
     *
     * @tags Asset
     * @name AssetsBenchmarkList
     * @request GET:/assets-benchmark
     * @secure
     */
    assetsBenchmarkList: (params: RequestParams = {}) =>
      this.request<GetAssetsForBenchmarkResponse, void>({
        path: `/assets-benchmark`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  register = {
    /**
     * @description Creates a new user that must afterwards verify the account with a token
     *
     * @tags Auth
     * @name RegisterCreate
     * @request POST:/register
     * @secure
     */
    registerCreate: (data: RegisterRequest, params: RequestParams = {}) =>
      this.request<RegisterResponse, void>({
        path: `/register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  login = {
    /**
     * @description Authenticates a user by email and password and returns a JWT token
     *
     * @tags Auth
     * @name LoginCreate
     * @request POST:/login
     * @secure
     */
    loginCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, ErrorResponse | void>({
        path: `/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  recoverPassword = {
    /**
     * @description Sends an email to the user with instructions to recover password
     *
     * @tags Auth
     * @name RecoverPasswordCreate
     * @request POST:/recover-password
     * @secure
     */
    recoverPasswordCreate: (data: RecoverPasswordRequest, params: RequestParams = {}) =>
      this.request<RecoverPasswordResponse, void>({
        path: `/recover-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  resetPassword = {
    /**
     * @description Resets a user's password
     *
     * @tags Auth
     * @name ResetPasswordCreate
     * @request POST:/reset-password
     * @secure
     */
    resetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.request<ResetPasswordResponse, void>({
        path: `/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  userVerification = {
    /**
     * @description Verifies user account with a token
     *
     * @tags Auth
     * @name UserVerificationCreate
     * @request POST:/user-verification
     * @secure
     */
    userVerificationCreate: (data: VerificationRequest, params: RequestParams = {}) =>
      this.request<VerificationResponse, void>({
        path: `/user-verification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  gmailLogin = {
    /**
     * @description Logs in with gmail. If the user exists in database, retrieves it. If not, user is created
     *
     * @tags User
     * @name GmailLoginList
     * @request GET:/gmail-login
     * @secure
     */
    gmailLoginList: (
      query: {
        accessToken: string;
      },
      data?: any,
      params: RequestParams = {},
    ) =>
      this.request<PostGmailLoginResponse, void>({
        path: `/gmail-login`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  comment = {
    /**
     * @description Get all comments by postId
     *
     * @tags Comments
     * @name GetCommentsByPostList
     * @request GET:/comment/get-comments-by-post
     * @secure
     */
    getCommentsByPostList: (
      query?: {
        postId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsByPostResponse, void>({
        path: `/comment/get-comments-by-post`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get quantity of comments by postId
     *
     * @tags Comments
     * @name GetCommentQtyList
     * @request GET:/comment/get-comment-qty
     * @secure
     */
    getCommentQtyList: (
      query?: {
        postId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentQtyByPostIdResponse, void>({
        path: `/comment/get-comment-qty`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a new comment
     *
     * @tags Comments
     * @name CommentCreate
     * @request POST:/comment
     * @secure
     */
    commentCreate: (data: PostCommentSchema, params: RequestParams = {}) =>
      this.request<PostCommentResponse, void>({
        path: `/comment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  groups = {
    /**
     * @description Get all groups ranking from a userId
     *
     * @tags Groups
     * @name UserGroupsRankingList
     * @request GET:/groups/user-groups-ranking
     * @secure
     */
    userGroupsRankingList: (
      query?: {
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetUserGroupsRankingResponse, void>({
        path: `/groups/user-groups-ranking`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * @description Responds if the app is up and running
     *
     * @tags Healthcheck
     * @name HealthList
     * @request GET:/health
     * @secure
     */
    healthList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/health`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  userPortfolios = {
    /**
     * @description Get all portfolios from a user
     *
     * @tags Portfolio
     * @name UserPortfoliosList
     * @request GET:/user-portfolios
     * @secure
     */
    userPortfoliosList: (
      query?: {
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPortfoliosFromUserResponse, void>({
        path: `/user-portfolios`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  portfolio = {
    /**
     * @description Creates a new portfolio
     *
     * @tags Portfolio
     * @name PortfolioCreate
     * @request POST:/portfolio
     * @secure
     */
    portfolioCreate: (data: any, params: RequestParams = {}) =>
      this.request<PostPortfolioResponse, void>({
        path: `/portfolio`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  portfoliosRanking = {
    /**
     * @description Get all portfolios for ranking
     *
     * @tags Portfolio
     * @name PortfoliosRankingList
     * @request GET:/portfolios-ranking
     * @secure
     */
    portfoliosRankingList: (
      query?: {
        timeFrame?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPortfoliosForRankingResponse, void>({
        path: `/portfolios-ranking`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  portfoliosHomeGraph = {
    /**
     * @description Get all information for home graph
     *
     * @tags Portfolio
     * @name PortfoliosHomeGraphList
     * @request GET:/portfolios-home-graph
     * @secure
     */
    portfoliosHomeGraphList: (
      query: {
        timeFrame: string;
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPortfolioHomeGraphInformationResponse, void>({
        path: `/portfolios-home-graph`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  posts = {
    /**
     * @description Get all posts with pagination
     *
     * @tags Post
     * @name PostsList
     * @request GET:/posts
     * @secure
     */
    postsList: (
      query?: {
        page?: number;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPostsPerPageResponse, void>({
        path: `/posts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a new post and uploads an image or a video, if present
     *
     * @tags Post
     * @name PostsCreate
     * @request POST:/posts
     * @secure
     */
    postsCreate: (
      data: {
        /** @format binary */
        file?: File;
        /** The JSON string containing additional data */
        data: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostPostResponse, void>({
        path: `/posts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all posts with pagination
     *
     * @tags Post
     * @name PostsDetail
     * @request GET:/posts/{postId}
     * @secure
     */
    postsDetail: (postId?: string, params: RequestParams = {}) =>
      this.request<GetPostByIdResponse, void>({
        path: `/posts/${postId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  postLikes = {
    /**
     * @description Likes a post
     *
     * @tags Likes
     * @name PostLikesUpdate
     * @request PUT:/post-likes
     * @secure
     */
    postLikesUpdate: (data: PutLikesOnPostSchema, params: RequestParams = {}) =>
      this.request<PutLikesOnPostResponse, void>({
        path: `/post-likes`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * @description Get users information
     *
     * @tags User
     * @name UsersList
     * @request GET:/users
     * @secure
     */
    usersList: (
      query?: {
        page?: number;
        limit?: number;
        searchValue?: string;
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetUsersPerPageResponse, void>({
        path: `/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update a user information
     *
     * @tags User
     * @name UsersUpdate
     * @request PUT:/users/{id}
     * @secure
     */
    usersUpdate: (id: string, data: UpdateUserSchema, params: RequestParams = {}) =>
      this.request<UpdateUserResponse, void>({
        path: `/users/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a user by its ID. Needs to be accessible to every user,
     *
     * @tags User
     * @name UsersDetail
     * @request GET:/users/{id}
     * @secure
     */
    usersDetail: (id: string, data?: any, params: RequestParams = {}) =>
      this.request<GetUserResponse, void>({
        path: `/users/${id}`,
        method: "GET",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete a users array by IDs.
     *
     * @tags User
     * @name UsersDelete
     * @request DELETE:/users/{userIds}
     * @secure
     */
    usersDelete: (userIds: string[], params: RequestParams = {}) =>
      this.request<DeleteUserResponse, void>({
        path: `/users/${userIds}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * @description get a current user information
     *
     * @tags User
     * @name UserList
     * @request GET:/user
     * @secure
     */
    userList: (data?: any, params: RequestParams = {}) =>
      this.request<GetUserResponse, void>({
        path: `/user`,
        method: "GET",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  usersNicknames = {
    /**
     * @description Get users nicknames
     *
     * @tags User
     * @name UsersNicknamesList
     * @request GET:/users-nicknames
     * @secure
     */
    usersNicknamesList: (params: RequestParams = {}) =>
      this.request<GetUsersNicknamesResponse, void>({
        path: `/users-nicknames`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
