import React from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  Avatar,
  CardContent,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import HowToReg from '@mui/icons-material/HowToReg';
import Insights from '@mui/icons-material/Insights';
import Assessment from '@mui/icons-material/Assessment';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material';

import Page from '../../components/Layout/Page';
import useStyles from './styles';
import Navbar from './components/Navbar/index';

const h1 = require('../../assets/images/h1.png');
const m1 = require('../../assets/images/m1.png');
const h2 = require('../../assets/images/h2.png');
const m2 = require('../../assets/images/m2.png');

function LandingView() {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const navLinks = [
    { label: intl.formatMessage({ id: 'landing.nav.home' }), href: '#home' },
    { label: intl.formatMessage({ id: 'landing.nav.about' }), href: '#about' },
    { label: intl.formatMessage({ id: 'landing.nav.features' }), href: '#features' },
    { label: intl.formatMessage({ id: 'landing.nav.testimonials' }), href: '#testimonials' },
    { label: intl.formatMessage({ id: 'landing.nav.contact' }), href: '#contact' },
  ];

  const loginRedirectionClick = () => {
    history.push('/login');
  };

  const testimonials = [
    {
      name: intl.formatMessage({ id: 'landing.testimonial.first.name' }),
      image: h1,
      text: intl.formatMessage({ id: 'landing.testimonial.first.testimonial' }),
    },
    {
      name: intl.formatMessage({ id: 'landing.testimonial.second.name' }),
      image: m1,
      text: intl.formatMessage({ id: 'landing.testimonial.second.content' }),
    },
    {
      name: intl.formatMessage({ id: 'landing.testimonial.third.name' }),
      image: h2,
      text: intl.formatMessage({ id: 'landing.testimonial.third.content' }),
    },
    {
      name: intl.formatMessage({ id: 'landing.testimonial.fourth.name' }),
      image: m2,
      text: intl.formatMessage({ id: 'landing.testimonial.fourth.content' }),
    },
  ];

  return (
    <Page title="TradesiLike" className={classes.root}>
      {/* HOME */}
      <Box id='home' className={classes.backgroundImageBox}>
        {/* NAVBAR */}
        <Box className={classes.navbarBox}>
          <Navbar links={navLinks} />
        </Box>
        {/* WELCOME */}
        <Box className={classes.welcomeTextBox}>
          <Typography className={classes.welcomeTitle}>
            <FormattedMessage id='landing.welcome.title' />
          </Typography>
          <Typography className={classes.welcomeTitleExpansion}>
            <FormattedMessage id='landing.welcome.expansion' />
          </Typography>
          <Typography className={classes.welcomeTitleSlogan}>
            <FormattedMessage id='landing.welcome.slogan' />
          </Typography>
          <Typography className={classes.welcomeTitleText}>
            <FormattedMessage id='landing.welcome.message' />
          </Typography>
          <Button
            className={classes.getStartedButton}
            size="large"
            type="submit"
            variant="contained"
            onClick={loginRedirectionClick}
          >
            <FormattedMessage id='landing.welcome.getStarted' />
          </Button>
        </Box>
      </Box>
      {/* ABOUT */}
      <Box id='about' className={`${classes.rowBox} ${classes.aboutSection}`}>
        <Box>
          <Typography className={classes.aboutQuestionText}>
            <FormattedMessage id='landing.about.question' />
          </Typography>
          <Typography className={classes.aboutResponseText}>
            <FormattedMessage id='landing.about.response' />
          </Typography>
        </Box>
        <Box className={classes.aboutImage}>
        </Box>
      </Box>
      {/* FEATURES */}
      <Box id="features" className={classes.featureBox}>
        <Typography className={classes.featureTitle}>
          <FormattedMessage id='landing.features.title.part1' />
          <span style={{ color: '#8346FF' }}>
            <FormattedMessage id='landing.welcome.expansion' />
          </span>
          <FormattedMessage id='landing.features.title.part2' />
        </Typography>
      </Box>
      <Box className={classes.featureCardsBox}>
        <Box className={`${classes.featureCard} ${classes.purpleBackground}`}>
          <HowToReg className={classes.icon} />
          <Typography className={classes.titleFeatureCard}>
            <FormattedMessage id='landing.features.firstFeature.title' />
          </Typography>
          <Typography className={classes.contentFeatureCard}>
            <FormattedMessage id='landing.features.firstFeature.text' />
          </Typography>
        </Box>
        <Box className={`${classes.featureCard} ${classes.whiteBackground}`}>
          <LibraryBooks className={`${classes.icon} ${classes.purpleColorText}`} />
          <Typography className={classes.titleFeatureCard}>
            <FormattedMessage id='landing.features.secondFeature.title' />
          </Typography>
          <Typography className={classes.contentFeatureCard}>
            <FormattedMessage id='landing.features.secondFeature.text' />
          </Typography>
        </Box>
        <Box className={`${classes.featureCard} ${classes.purpleBackground}`}>
          <Insights className={classes.icon} />
          <Typography className={classes.titleFeatureCard}>
            <FormattedMessage id='landing.features.thirdFeature.title' />
          </Typography>
          <Typography className={classes.contentFeatureCard}>
            <FormattedMessage id='landing.features.thirdtFeature.text' />
          </Typography>
        </Box>
        <Box className={`${classes.featureCard} ${classes.whiteBackground}`}>
          <LibraryBooks className={`${classes.icon} ${classes.purpleColorText}`} />
          <Typography className={classes.titleFeatureCard}>
            <FormattedMessage id='landing.features.fourthFeature.title' />
          </Typography>
          <Typography className={classes.contentFeatureCard}>
            <FormattedMessage id='landing.features.fourthFeature.text' />
          </Typography>
        </Box>
        <Box className={`${classes.featureCard} ${classes.purpleBackground}`}>
          <Assessment className={classes.icon} />
          <Typography className={classes.titleFeatureCard}>
            <FormattedMessage id='landing.features.fifthFeature.title' />
          </Typography>
          <Typography className={classes.contentFeatureCard}>
            <FormattedMessage id='landing.features.fifthFeature.text' />
          </Typography>
        </Box>
        <Box className={`${classes.featureCard} ${classes.whiteBackground}`}>
          <LibraryBooks className={`${classes.icon} ${classes.purpleColorText}`} />
          <Typography className={classes.titleFeatureCard}>
            <FormattedMessage id='landing.features.sixthFeature.title' />
          </Typography>
          <Typography className={`${classes.contentFeatureCard} ${classes.textColorGrey}`}>
            <FormattedMessage id='landing.features.sixthFeature.text' />
          </Typography>
        </Box>
      </Box>
      {/* HOW DOES TIL WORKS */}
      <Box className={`${classes.rowBox} ${classes.howWorksSection}`}>
        <Box className={classes.howWorksImage}>
        </Box>
        <Box>
          <Typography className={`${classes.mainTitle} ${classes.purpleColorText}`}>
            <FormattedMessage id='landing.howWorks.title' />
          </Typography>
          <Box className={classes.howWorksCardSection}>
            {/* CARD WITH NUMBER */}
            <Box className={`${classes.rowBox} ${classes.howWorksBox}`}>
              <Box className={`${classes.howWorksNumberBox} ${classes.purpleBackground}`}>
                <Typography className={classes.howWorksNumber}>1</Typography>
              </Box>
              <Box>
                <Typography className={`${classes.howWorksTitle} ${classes.textColorGrey}`}>
                  <FormattedMessage id='landing.howWorks.firstStep.title' />
                </Typography>
                <Typography className={`${classes.howWorksContent} ${classes.textColorGrey}`}>
                  <FormattedMessage id='landing.howWorks.firstStep.content' />
                </Typography>
              </Box>
            </Box>
            {/* CARD WITH NUMBER */}
            <Box className={`${classes.rowBox} ${classes.howWorksBox}`}>
              <Box className={`${classes.howWorksNumberBox} ${classes.purpleBackground}`}>
                <Typography className={classes.howWorksNumber}>2</Typography>
              </Box>
              <Box>
                <Typography className={`${classes.howWorksTitle} ${classes.textColorGrey}`}>
                  <FormattedMessage id='landing.howWorks.secondStep.title' />
                </Typography>
                <Typography className={`${classes.howWorksContent} ${classes.textColorGrey}`}>
                  <FormattedMessage id='landing.howWorks.secondStep.content' />
                </Typography>
              </Box>
            </Box>
            {/* CARD WITH NUMBER */}
            <Box className={`${classes.rowBox} ${classes.howWorksBox}`}>
              <Box className={`${classes.howWorksNumberBox} ${classes.purpleBackground}`}>
                <Typography className={classes.howWorksNumber}>3</Typography>
              </Box>
              <Box>
                <Typography className={`${classes.howWorksTitle} ${classes.textColorGrey}`}>
                  <FormattedMessage id='landing.howWorks.thirdStep.title' />
                </Typography>
                <Typography className={classes.howWorksContent}>
                  <FormattedMessage id='landing.howWorks.thirdStep.content' />
                </Typography>
              </Box>
            </Box>
            {/* CARD WITH NUMBER */}
            <Box className={`${classes.rowBox} ${classes.howWorksBox}`}>
              <Box className={`${classes.howWorksNumberBox} ${classes.purpleBackground}`}>
                <Typography className={classes.howWorksNumber}>4</Typography>
              </Box>
              <Box>
                <Typography className={`${classes.howWorksTitle} ${classes.textColorGrey}`}>
                  <FormattedMessage id='landing.howWorks.fourthStep.title' />
                </Typography>
                <Typography className={classes.howWorksContent}>
                  <FormattedMessage id='landing.howWorks.fourthStep.content' />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* TESTIMONIALS */}
      <Box id="testimonials" className={`${classes.rowBox} ${classes.testimonialSection}`}>
        <Box className={`${classes.rowBox} ${classes.paddingZero}`}>
          <Box className={classes.testimonialTitleBox}>
            <Typography className={`${classes.mainTitle} ${classes.textColorGrey}`}>
              <FormattedMessage id='landing.testimonial.title' />
            </Typography>
          </Box>
          <Box className={`${classes.rowBox} ${classes.testimonialsBox}`}>
            {testimonials.map((testimonial, index) => (
              <Card key={index} className={classes.testimonialCard}>
                <Avatar src={testimonial.image} alt={testimonial.name} className={classes.avatar} />
                <CardContent className={classes.testimonialCardContent}>
                  <Typography className={classes.testimonialName}>{testimonial.name}</Typography>
                  <Typography className={classes.testimonialText}>
                    {testimonial.text}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
        <Box className={classes.testimonialPurleBox}>
          <img
            // eslint-disable-next-line global-require
            src={require('../../assets/images/double-quote.png')}
            className={classes.doubleQuote}
          ></img>
        </Box>
      </Box>
      {/* JOIN US */}
      <Box className={classes.joinUsSection}>
        <Typography className={classes.joinUsTitle}>
          <FormattedMessage id="landing.joinUs.title"></FormattedMessage>
        </Typography>
        <Typography className={classes.joinUsText}>
          <FormattedMessage id="landing.joinUs.content"></FormattedMessage>
        </Typography>
        <Button
          className={classes.joinUsButton}
          size="large"
          type="submit"
          variant="contained"
          onClick={loginRedirectionClick}
        >
          <FormattedMessage id='landing.joinUs.buttonText' />
        </Button>
      </Box>
      {/* TERMS AND CONDITIONS */}
      <Box className={classes.clausesSection}>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.start"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.1"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.2"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.3"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.4"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.5"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.6"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.7"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.8"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.9"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.10"></FormattedMessage>
        </Typography>
        <Typography className={classes.clausesText}>
          <FormattedMessage id="landing.clauses.dataProtection"></FormattedMessage>
        </Typography>
      </Box>
      {/* Footer */}
      <Box id="contact" className={`${classes.rowBox} ${classes.footerSection}`} >
        <Box className={` ${classes.footerBox} ${classes.logoContainer}`}>
          <img
            // eslint-disable-next-line global-require
            src={require('../../assets/images/logo.png')}
            className={classes.footerImage}
            ></img>
        </Box>
        <Box className={classes.footerBox}>
          <Typography className={classes.footerTitle}>
            <FormattedMessage id='landing.contactUs.title'/>
          </Typography>
          <Typography className={classes.footerText}>
            <FormattedMessage id='landing.contactUs.text'/>
          </Typography>
        </Box>
        <Box className={classes.footerBox}>
          <Typography className={classes.footerTitle}>
            <FormattedMessage id='landing.followUs.title'/>
          </Typography>
          <Typography className={classes.footerText}>
            <FormattedMessage id='landing.followUs.text'/>
          </Typography>
        </Box>
        <Box className={`${classes.footerBox} ${classes.socialContainer}`}>
          <LinkedIn className={classes.socialMediaIcon} />
          <Facebook className={classes.socialMediaIcon} />
          <Twitter className={classes.socialMediaIcon} />
        </Box>
      </Box>
    </Page>
  );
}

export default LandingView;
