import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  mainHomeContainer: {
    display: 'flex',
    flexFlow: 'column-reverse',
    paddingTop: '20px',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row',
    },
  },
  mainHomePostContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
  },
  mainHomeGraphContainer: {
    display: 'none',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '0',
    },
  },
  mainContentLoader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContentCircularProgress: {
    top: '50%',
    right: '50%',
    transform: 'translate(50%,-50%)',
    position: 'absolute',
  },
  dailyMover: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  leftArea: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      marginRight: '25px',
    },
  },
  rightArea: {
    margin: '0px 20px',
    [theme.breakpoints.up('md')]: {
      margin: '0px 25px',
    },
  },
  createPostBox: {
    margin: '0px',
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '0',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '0',
    },
  },
  postBox: {
    margin: '0px',
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '0',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '0',
    },
  },
}));

export default useStyles;
