import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import { GetAssetsResponse } from '../../../requests/api/apiTypes';

const DailyMover: React.FC<Pick<GetAssetsResponse, 'assets'>> = ({ assets }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.dailyMoverBox}>
        <Typography className={classes.dailyMoverText}>
          Daily movers
        </Typography>
      </Box>
      <Box className={classes.loopMainBox}>
        <Box className={classes.loopWrapper}>
          {assets.length > 0
            && [...assets, ...assets].map((asset, index) => {
              // eslint-disable-next-line no-nested-ternary
              const priceColor = asset.currentPrice > asset.lastPrice
                ? classes.priceUp
                : asset.currentPrice < asset.lastPrice
                  ? classes.priceDown
                  : classes.priceEqual;

              return (
                <Box key={index} className={classes.loopItem}>
                  <Typography className={classes.assetName}>
                    {`${asset.name}: `}
                  </Typography>
                  <Typography className={`${classes.assetPrice} ${priceColor}`}>
                    {`$${asset.currentPrice}`}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default DailyMover;
