import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Landing from './views/Landing';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';

import { ChildrenProps, RouteConfig } from './types';
import Unauthorized from './views/Unauthorized';
import DashboardLayout from './components/Navigation';
import Home from './views/Home';
import SharePostView from './views/Share';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Landing />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  // TODO: Si el usuario no puede realizar su propio registro
  // (desde fuera del Dashboard) comentar esta ruta.
  {
    id: 4,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 5,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 6,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    id: 8,
    exact: true,
    path: '/sin-autorizacion',
    guard: (props: ChildrenProps) => <AuthGuard {...props} />,
    component: () => <Unauthorized />,
  },
  {
    id: 9,
    exact: true,
    path: '/share/:postId',
    layout: DashboardLayout,
    component: () => <SharePostView />,
  },
  {
    id: 10,
    path: '/home',
    guard: (props: ChildrenProps) => <AuthGuard {...props} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/home',
        component: () => <Home />,
      },
    ],
  },
];

const renderRoutes = (routes: RouteConfig[]) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route: RouteConfig, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component as React.ElementType;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
