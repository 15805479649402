import { Theme, lighten } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { THEMES } from '../../../constants';

const navigationXLWidth = '1320px';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  textColor: {
    color: theme.palette.text.tertiary,
  },
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT ? {
      backgroundColor: theme.palette.primary.main,
    } : {}),
    ...(theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {}),
  },
  toolbar: {
    minHeight: 72,
    backgroundColor: theme.palette.primary.main,
    width: navigationXLWidth,
    margin: '0px auto',
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '50px',
    filter: theme.logo.filter,
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popoverSettings: {
    width: 320,
    padding: theme.spacing(2),
  },
  icon: {
    filter: theme.settings.filter,
  },
  individualTab: {
    color: 'white',
    textTransform: 'capitalize',
    minWidth: '0px',
    padding: '0px 14px',
    '&.Mui-selected': {
      color: 'white',
    },
    fontSize: '12px',
  },
  goToLoginButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  goToLoginButton: {
    borderRadius: '100px',
    textTransform: 'capitalize',
    fontWeight: '700',
    background: '#0BF5D9',
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    color: '#8346FF',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&:hover': {
      backgroundColor: lighten('#0BF5D9', 0.6),
    },
  },
  goToLoginButtonText: {
    textDecoration: 'none',
  },
}));

export default useStyles;
