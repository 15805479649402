import { makeStyles } from '@mui/styles';
import { darken, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    alignItems: 'start',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    marginBottom: '20px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    padding: '15px 20px 0px 5px',
    gap: '5px',
    margin: '0px 20px',
  },
  avatar: {
    width: '35px',
    height: '35px',
  },
  inputContainer: {
    flex: 1,
  },
  input: {
    width: '100%',
    padding: '4px 8px',
    fontSize: '12px',
    fontFamily: 'Inter',
    lineHeight: '24px',
  },
  iconButton: {
    padding: '10px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
  },
  iconButtonDisabled: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'rgba(0, 0, 0, 0.4)',
    '&.Mui-disabled': {
      background: '#BDBDBD',
      color: 'white',
    },
  },
  replyComment: {
    marginLeft: '70px',
    textTransform: 'capitalize',
    fontFamily: 'Inter',

  },
}));

export default useStyles;
