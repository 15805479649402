import { makeStyles } from '@mui/styles';
import { darken, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    alignItems: 'start',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    marginBottom: '20px',
  },
  container: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    padding: '15px 20px 0px 15px',
    gap: '5px',
    margin: '0px 0px 10px 20px',
  },
  avatar: {
    width: '35px',
    height: '35px',
  },
  inputContainer: {
    flex: 1,
  },
  input: {
    width: '100%',
    padding: '4px 8px',
    fontSize: '12px',
    fontFamily: 'Inter',
    lineHeight: '24px',
  },
  iconButton: {
    height: '35px',
    width: '35px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
  },
  iconButtonDisabled: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'rgba(0, 0, 0, 0.4)',
    '&.Mui-disabled': {
      background: '#BDBDBD',
      color: 'white',
    },
  },
  replyComment: {
    padding: 0,
    marginTop: '2px',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontSize: '12px',
  },
  userInfo: {
    display: 'flex',
    gap: '10px',
    width: '100%',
  },
  commentContainer: {
    flex: 1,
  },
  username: {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Inter',
  },
  nickname: {
    fontSize: '12px',
    fontWeight: '600',
    fontFamily: 'Inter',
  },
  content: {
    marginTop: 5,
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '18.15px',
    color: '#000002',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
}));

export default useStyles;
