import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const navigationXLWidth = '1320px';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 72,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    // flex: '1 1 auto',
    margin: '0px auto',
    width: navigationXLWidth,
    height: '100%',
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    scrollbarWidth: 'none', /* Firefox */
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none', /* WebKit */
    },

  },
}));

export default useStyles;
