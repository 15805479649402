import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocalFireDepartment } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Post from '../../components/Post';
import Page from '../../components/Layout/Page';
import {
  GetAssetsResponse,
  GetPortfolioHomeGraphInformationResponse,
  GetPortfoliosForRankingResponse,
  GetPortfoliosFromUserResponse,
  GetPostsPerPageResponse,
} from '../../requests/api/apiTypes';
import NewsTicker from '../../components/Navigation/DailyMover';
import ProfileCard from './components/UserInfo';
import RankingList from '../../components/General/RankingList';
import useStyles from './styles';
import PortfolioGraph from '../../components/Graphs/PortfolioGraph';
import CreatePost from './components/CreatePost';
import CustomModal from '../../components/General/CustomModal';
import { ReduxState } from '../../types';
import PortfolioModalContent from './components/Portfolio/PortfolioModalContent';
import apiClient from '../../requests/api/apiClient';
import GroupRanking from './components/GroupRanking';
import useElementOnScreen from '../../hooks/useElementOnScreen';

const Home = () => {
  const classes = useStyles();
  const intl = useIntl();
  const throttleTimeout = useRef<NodeJS.Timeout | null>(null);

  const { user } = useSelector((state: ReduxState) => state.account);
  const { selectedTimeFrame } = useSelector((state: ReduxState) => state.timeFrame);
  const [mainContentLoading, setMainContentLoading] = useState({
    dailyMover: true,
    posts: true,
    ranking: true,
    homeGraph: true,
  });
  const [userPortfoliosLoading, setUserPortfoliosLoading] = useState(true);
  const [posts, setPosts] = useState<GetPostsPerPageResponse['posts'] | undefined>([]);
  const [isPostLikedByUser, setIsPostLikedByUser] = useState<Record<string, boolean>>({});
  const [userPortfolios, setUserPortfolios] = useState<GetPortfoliosFromUserResponse['portfolios']>([]);
  const [rankingPortfolios, setRankingPortfolios] = useState<GetPortfoliosForRankingResponse['portfolios']>([]);
  const [homeGraphPortfolios, setHomeGraphPortfolios] = useState<GetPortfolioHomeGraphInformationResponse['portfolios']>([]);
  const [dailyMoverAsset, setDailyMoverAsset] = useState<GetAssetsResponse['assets'] | undefined>();
  const [postFetchParams, setPostFetchParams] = useState({
    limit: 5,
    page: 0,
  });
  const [noMorePosts, setNoMorePosts] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getInformationCondition = userPortfolios && userPortfolios.length > 0;

  const fetchPortfolios = async () => {
    try {
      const { data } = await apiClient.userPortfolios.userPortfoliosList({ userId: user?.id });
      setUserPortfolios(data?.portfolios || []);
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'home.error.unableToGetPortfolios' }), { variant: 'error' });
    } finally {
      setUserPortfoliosLoading(false);
    }
  };

  const fetchPost = async () => {
    if (user) {
      try {
        // TODO - manage posts coming from backend, see how to show them
        const payload = {
          limit: postFetchParams.limit,
          page: postFetchParams.page,
          userId: user?.id ?? '',
        };
        const { data } = await apiClient.posts.postsList(payload);
        setPosts((prevState) => {
          const loadedPosts = prevState ?? [];
          const combinedPosts = [...loadedPosts, ...data.posts];

          const uniquePostsMap = new Map(combinedPosts.map((post) => [post.id, post]));

          return Array.from(uniquePostsMap.values());
        });
        setIsPostLikedByUser(data.posts
          ?.reduce((acc, val) => ({ ...acc, [val.id]: val.likedByCurrentUser }), {}) ?? {});
        setNoMorePosts(!data.posts?.length);
      } catch (err) {
        enqueueSnackbar(intl.formatMessage({ id: 'home.error.unableToGetPosts' }), { variant: 'error' });
      } finally {
        setMainContentLoading((prevState) => ({ ...prevState, posts: false }));

        if (throttleTimeout.current) {
          clearTimeout(throttleTimeout.current);
          throttleTimeout.current = null;
        }
      }
    }
  };

  const fetchAssets = async () => {
    try {
      const payload = {
        quantity: 0,
      };
      const { data } = await apiClient.asset.assetList(payload);
      setDailyMoverAsset(data.assets);
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'home.error.unableToGetDailyMovers' }), { variant: 'error' });
    } finally {
      setMainContentLoading((prevState) => ({ ...prevState, dailyMover: false }));
    }
  };

  const fetchRanking = async () => {
    try {
      const { data } = await apiClient.portfoliosRanking
        .portfoliosRankingList({ timeFrame: selectedTimeFrame });
      setRankingPortfolios(data.portfolios);
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'home.error.unableToGetRankingPortfolios' }), { variant: 'error' });
    } finally {
      setMainContentLoading((prevState) => ({ ...prevState, ranking: false }));
    }
  };

  const fetchHomeGraphInformation = async () => {
    try {
      const { data } = await apiClient.portfoliosHomeGraph.portfoliosHomeGraphList({
        userId: user?.id as string,
        timeFrame: selectedTimeFrame,
      });
      setHomeGraphPortfolios(data.portfolios);
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'home.error.unableToGetHomeGraphInformation' }), { variant: 'error' });
    } finally {
      setMainContentLoading((prevState) => ({ ...prevState, homeGraph: false }));
    }
  };

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  useEffect(() => {
    if (getInformationCondition) {
      fetchPost();
      fetchAssets();
      fetchRanking();
      fetchHomeGraphInformation();
    }
    // userPortfolios?.length ?? 0 - This should run only once when the user creates its
    // first portfolio
  }, [userPortfolios?.length ?? 0]);

  useEffect(() => {
    if (isVisible) {
      if (!throttleTimeout.current) {
        setPostFetchParams((prevState) => ({
          ...prevState,
          page: prevState.page + 1,
        }));

        // Set a timeout to prevent frequent triggering
        throttleTimeout.current = setTimeout(() => {
          throttleTimeout.current = null;
        }, 2000);
      }
    }

    // Cleanup function to clear the timeout when the component unmounts
    return () => {
      if (throttleTimeout.current) {
        clearTimeout(throttleTimeout.current);
      }
    };
  }, [isVisible]);

  useEffect(() => {
    if (postFetchParams.page && postFetchParams.page > 0) {
      fetchPost();
    }
  }, [postFetchParams.page]);

  useEffect(() => {
    if (getInformationCondition && selectedTimeFrame) {
      fetchRanking();
      fetchHomeGraphInformation();
    }
  }, [selectedTimeFrame]);

  useEffect(() => {
    if (user?.id) {
      fetchPortfolios();
    }
  }, [user?.id]);

  const handleCreatePortfolio = async (name: string, benchmarkId: string) => {
    try {
      await apiClient.portfolio.portfolioCreate({
        userId: user?.id as string, name, benchmarkId,
      });
      enqueueSnackbar(intl.formatMessage({ id: 'home.success.portfolioCreated' }), { variant: 'success' });
      await fetchPortfolios();
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'home.error.unableToCreatePortfolio' }), { variant: 'error' });
    }
  };

  const handleLikeButton = async ({ postId }: { postId: string }) => {
    try {
      const { data } = await apiClient.postLikes.postLikesUpdate({
        userId: user?.id as string, postId,
      });
      setIsPostLikedByUser((prevState) => ({
        ...prevState, [postId]: Boolean(data.isLikedByUser),
      }));
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'home.error.unableToGetDailyMovers' }), { variant: 'error' });
    }
  };

  const mainContentLoader = Object.values(mainContentLoading).every((value) => !value);

  return (
    <Page
      title="Home"
    >
      {!mainContentLoader
        ? <Box className={classes.mainContentLoader}>
          <CircularProgress className={classes.mainContentCircularProgress} size={100} />
        </Box>
        : (
          <>
            <Box className={classes.dailyMover}>
              <NewsTicker assets={dailyMoverAsset ?? []}></NewsTicker>
            </Box>
            <Box className={classes.mainHomeContainer}>
              <Box className={classes.leftArea}>
                <ProfileCard />
                <GroupRanking />
              </Box>
              <Box className={classes.mainHomePostContainer}>
                <Box className={classes.createPostBox}>
                  <CreatePost />
                </Box>
                <Box className={classes.mainHomeGraphContainer}>
                  <PortfolioGraph
                    portfolios={homeGraphPortfolios}
                    timerFrame={selectedTimeFrame}
                    userNickname={user?.nickname ?? ''}
                  />
                </Box>
                {posts
                  && posts.length > 0
                  && posts.map((post) => (
                    <Box key={post.id} className={classes.postBox}>
                      <Post
                        key={post.id}
                        postInfo={post}
                        numOfComments={post.commentCount}
                        numOfLikes={post.likeCount
                          + Number(Boolean(isPostLikedByUser[post.id]))
                          - Number(Boolean(post.likedByCurrentUser))}
                        handleLikeButton={handleLikeButton}
                        likedByUser={isPostLikedByUser[post.id] || false}
                      />
                    </Box>
                  ))
                }
                <Box ref={containerRef as React.MutableRefObject<null>} className={classes.mainContentLoader} marginBottom='50px'>
                  {isVisible && !noMorePosts && <Box>
                    <CircularProgress size={40} />
                  </Box>}
                  {isVisible && noMorePosts && <Box>
                    <Typography><FormattedMessage id="home.noMorePosts" /></Typography>
                  </Box>}
                </Box>
              </Box>
              <Box className={classes.rightArea}>
                <RankingList
                  title="Top Til's"
                  titleIcon={<LocalFireDepartment fontSize='medium' />}
                  portfolios={rankingPortfolios}
                />
              </Box>
            </Box>
          </>
        )}
      {userPortfoliosLoading
        ? (<CircularProgress />)
        : (
          <CustomModal open={(userPortfolios || []).length === 0} handleClose={() => { }}>
            <PortfolioModalContent handleCreatePortfolio={handleCreatePortfolio} />
          </CustomModal>
        )}
    </Page>
  );
};

export default Home;
