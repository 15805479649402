import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: '#fff',
    borderRadius: 5,
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0)',
    padding: theme.spacing(3),
    textAlign: 'center',
    maxWidth: 300,
    margin: 'auto',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
  name: {
    fontFamily: 'Inter',
    fontSize: '25px',
    fontWeight: '700',
    lineHeight: '30.26px',
  },
  username: {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '18.15px',
    marginBottom: theme.spacing(1),
  },
  rank: {
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '600',
    lineHeight: '18.15px',
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22.4px',
    marginBottom: theme.spacing(0.5),
  },
  purpleNumber: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontFamily: 'Inter',
    fontSize: '30px',
    lineHeight: '30px',
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
