import React from 'react';
import PropTypes from 'prop-types';
// import NavBar from './NavBar';
import TopBar from './TopBar';

import useStyles from './styles';
import { ChildrenProps } from '../../types';

function DashboardLayout({ children }: ChildrenProps) {
  const classes = useStyles();
  // const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar />
      {/* <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      /> */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
