import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: '#fff',
    borderRadius: 5,
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0)',
    padding: theme.spacing(3),
    textAlign: 'center',
    maxWidth: 300,
    maxHeight: 700,
    margin: '20px 0px 20px 0px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  avatar: {
    width: '80px',
    height: '80px',
  },
  avatarGroup: {
    margin: '20px 0px 10px 0px',
  },
  groupName: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '24.2px',
    textAlign: 'center',
  },
  rankingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rankingPosition: {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '18.15px',
    color: '#BDBDBD',
  },
}));

export default useStyles;
