import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: 20,
    borderRadius: 5,
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0)',
    flex: 1,
    width: '100%',
  },
  userName: {
    fontFamily: 'Inter',
    fontSize: '25px',
    fontWeight: 'bold',
    lineHeight: '30.26px',
    color: '#000002',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      lineHeight: '26.63px',
    },
  },
  userIdentifier: {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '18.15px',
    color: '#000002',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '18.2px',
    },
  },
  avatar: {
    height: '80px',
    width: '80px',
  },
  content: {
    marginBottom: 10,
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '18.15px',
    color: '#000002',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '19.6px',
    },
  },
  media: {
    height: 300,
    padding: '0px 20px',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      objectFit: 'fill',
    },
  },
  dataPost: {
    display: 'flex',
    justifyContent: 'start',
    padding: '10px 16px',
  },
  dataPostText: {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '18.15px',
    color: '#000002',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '16.94px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  icons: {
    width: '22px',
    marginRight: '10px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      marginRight: '3px',
    },
  },
  iconButton: {
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0px',
      lineHeight: '19.6px',
    },
  },
  iconsNotActive: {
    width: '22px',
    marginRight: '10px',
  },
  actionText: {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '21px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '19.6px',
    },
  },
  horizontalDivider: {
    borderBottomWidth: 2,
  },
  verticalDivider: {
    borderRightWidth: 2,
  },
  shareButtonsContainer: {
    display: 'flex',
    padding: '12px',
    gap: '15px',
  },
}));

export default useStyles;
