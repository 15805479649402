import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  // IconButton,
  Toolbar,
  Button,
  // SvgIcon,
} from '@mui/material';
// import { Menu as MenuIcon } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import Logo from '../../Layout/Logo';
import Account from './Account';
// import Settings from './Settings';

import useStyles from './styles';
import { TopBarProps } from '../../../types';
import Navigation from './Navigation';

function TopBar({
  className,
  ...rest
}: TopBarProps) {
  const classes = useStyles();
  const location = useLocation();

  const isPublicShareUrl = location.pathname.includes('/share/');

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        {/* <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden> */}
        <Hidden lgDown>
          <RouterLink to="/home">
            <Logo className={classes.logo} />
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        {/* <Settings /> */}
        {!isPublicShareUrl && <Navigation />}
        {isPublicShareUrl && <Box className={classes.goToLoginButtonContainer}>
          <Button
            variant="contained"
            className={classes.goToLoginButton}
          >
            <RouterLink className={classes.goToLoginButtonText} to="/login">
              <FormattedMessage id="topbar.goToLogin" />
            </RouterLink>
          </Button>
        </Box>}
        <Box ml={2}>
          {!isPublicShareUrl && <Account />}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
