import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from '../styles';

const TermsAndConditionsContentModal = ({ handleClose, handleAcceptTerms }: {
  handleClose: React.Dispatch<React.SetStateAction<boolean>>,
  handleAcceptTerms: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.termsAndConditionsModalContainer}>
      <Typography variant='h4' className={classes.termsAndConditionsTitle} mb={2}>
        <FormattedMessage id='termsAndConditions.title' />
      </Typography>
      <Typography className={classes.termsAndConditionsTextTitles} mb={1}>
        <FormattedMessage id='termsAndConditions.disclaimer' />
      </Typography>
      <Box className={classes.termsAndConditonsTextTitlesParagraphContainer} mb={1}>
        <Typography className={classes.termsAndConditionsTextTitles}>
          <FormattedMessage id='termsAndConditions.noFinancialAdvice' /><span className={classes.termsAndConditionsTextParagraph}><FormattedMessage id='termsAndConditions.noFinancialAdviceParagraph' /></span>
        </Typography>
      </Box>
      <Box className={classes.termsAndConditonsTextTitlesParagraphContainer} mb={1}>
        <Typography className={classes.termsAndConditionsTextTitles}>
          <FormattedMessage id='termsAndConditions.simulationEducation' /><span className={classes.termsAndConditionsTextParagraph}><FormattedMessage id='termsAndConditions.simulationEducationParagraph' /></span>
        </Typography>
      </Box>
      <Box className={classes.termsAndConditonsTextTitlesParagraphContainer} mb={1}>
        <Typography className={classes.termsAndConditionsTextTitles}>
          <FormattedMessage id='termsAndConditions.userResponsibility' /><span className={classes.termsAndConditionsTextParagraph}><FormattedMessage id='termsAndConditions.userResponsibilityParagraph' /></span>
        </Typography>
      </Box>
      <Typography className={classes.termsAndConditionsTextParagraph}>
        <FormattedMessage id='termsAndConditions.beforeUsing' />
      </Typography>
      <Box className={classes.termsAndConditionsButtonsContainer}>
        <Button variant='outlined' className={classes.termsAndConditionsCancelButton} onClick={() => handleClose(false)}>
          <FormattedMessage id='termsAndConditions.cancelButton' />
        </Button>
        <Button variant='outlined' className={classes.termsAndConditionsAgreeButton} onClick={() => { handleAcceptTerms(true); handleClose(false); }}>
          <FormattedMessage id='termsAndConditions.agreeButton' />
        </Button>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsContentModal;
