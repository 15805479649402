import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'start',
    boxShadow: '-3px 0px 4px 0px #00000033',
  },
  dailyMoverBox: {
    width: '21.5%',
    padding: '6px',
    height: '30px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    minWidth: '120px',
    boxShadow: '3px 0px 4px 0px #00000033',
    zIndex: '1',
    textAlign: 'center',
  },
  dailyMoverText: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '18.15px',
  },
  loopMainBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '30px',
    overflow: 'hidden',
    position: 'relative',
  },
  loopWrapper: {
    display: 'flex',
    whiteSpace: 'nowrap',
    animation: '$loop 30s linear infinite',
  },
  loopItem: {
    display: 'flex',
    padding: '0 20px',
    color: theme.palette.text.primary,
  },
  assetName: {
    fontSize: '15px',
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '18.15px',
    color: 'black',
  },
  assetPrice: {
    fontSize: '15px',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '18.15px',
  },
  '@keyframes loop': {
    '0%': {
      transform: 'translateX(0%)',
    },
    '100%': {
      transform: 'translateX(-50%)',
    },
  },
  priceUp: {
    color: 'green',
  },
  priceDown: {
    color: 'red',
  },
  priceEqual: {
    color: 'black',
  },
}));

export default useStyles;
