import English from './en';
import Spanish from './es';
import Portuguese from './pr';

const supportedLanguages = ['en', 'es', 'pr'] as const;

export type LanguageCode = typeof supportedLanguages[number];

const localeDef = {
  en: 'en-US',
  es: 'es-ES',
  pr: 'pt-BR',
} as const;

export default {
  en: English,
  es: Spanish,
  pr: Portuguese,
  def: { ...localeDef },
} as const;
