import {
  Box,
  Button,
  Card,
  CardContent,
} from '@mui/material';

import ReactDOM from 'react-dom';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

import React, { useRef } from 'react';
import { TrendingUp } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';
import { GetPortfolioHomeGraphInformationResponse } from '../../../server/schemas/apiTypes';
import { currencyFormatter } from '../../utils/usefulFormatters';

const PortfolioGraph = (
  {
    portfolios,
    timerFrame,
    userNickname,
  }:
  { portfolios: GetPortfolioHomeGraphInformationResponse['portfolios'], timerFrame: string, userNickname: string },
  props: HighchartsReact.Props,
) => {
  exporting(Highcharts);
  const classes = useStyles();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const getPortfolioName = () => Object.keys(portfolios ?? {})?.[0] ?? '';

  // TODO: if necessary, think about more than one portfolio
  // Reverse the portfolios as the graph is going from old to recent
  const getXAxisCategories = () => {
    const auxXAxis = Object.keys(Object.values(portfolios ?? {})?.[0] ?? {}).reverse();
    if (auxXAxis.length === 0) {
      return [];
    }

    if (timerFrame === '1d') {
      return auxXAxis.map((date) => {
        const dateObj = new Date(date);
        return `${`${dateObj.getHours()}`.padStart(2, '0')}:${`${dateObj.getMinutes()}`.padStart(2, '0')}`;
      });
    }
    return auxXAxis.map((date) => {
      const dateObj = new Date(date);
      return `${`${dateObj.getDate()}`.padStart(2, '0')}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}`;
    });
  };
  const getYAxisValues = () => {
    const auxYAxis = Object.values(Object.values(portfolios ?? {})?.[0] ?? {}).reverse();
    return {
      portfolio: auxYAxis.map((value) => value.portfolio),
      benchmark: auxYAxis.map((value) => value.benchmark),
    };
  };

  const options: Highcharts.Options = {
    chart: {
      backgroundColor: 'white',
      height: 300,
      style: {
        fontFamily: 'Poppins, sans-serif',
      },
      spacing: [25, 25, 25, 25],
      events: {
        load() {
          const titleElement = document.getElementById('ranking-icon');
          if (titleElement) {
            ReactDOM.render(<TrendingUp style={{ color: '#8346FF', fontSize: '20px' }} />, titleElement);
          }
        },
      },
    },
    title: {
      text: '<span id="ranking-icon" style="display: inline-block; vertical-align: middle; margin-right: 8px;"></span>'
        + '<span style="font-size: 20px; font-weight: bold; color: #8346FF;">Ranking</span>',
      align: 'left',
      useHTML: true,
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 0,
      itemStyle: {
        color: '#000',
        fontWeight: 'bold',
      },
      itemMarginTop: -45,
      itemMarginBottom: 10,
    },
    xAxis: {
      categories: getXAxisCategories(),
      lineWidth: 1,
      tickWidth: 0,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        formatter() {
          return `$${this.value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        },
        style: {
          fontSize: '12px',
        },
      },
      gridLineWidth: 0,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter() {
        return `<strong>${this.x}</strong><br/><span style="color:${this.points?.[0].color}">\u25CF</span> ${this.points?.[0].series.name}: <strong>${currencyFormatter(this.points?.[0].y ?? 0)}</strong>`;
      },
      style: {
        pointerEvents: 'auto',
      },
    },
    plotOptions: {
      series: {
        marker: {
          radius: 6,
          symbol: 'circle',
        },
        lineWidth: 2,
      },
    },
    series: [
      {
        name: `@${userNickname} - ${getPortfolioName()}`,
        type: 'line',
        color: '#8346FF',
        data: getYAxisValues().portfolio as number[],
        marker: {
          symbol: 'circle',
          fillColor: '#8346FF',
        },
      },
      {
        name: 'S&P 500',
        type: 'line',
        color: '#D10000',
        dashStyle: 'ShortDot',
        data: getYAxisValues().benchmark as number[],
        marker: {
          symbol: 'square',
          fillColor: '#D10000',
          radius: 3,
        },
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  return (
    <Card
      elevation={0}
    >
      <CardContent
        className={classes.portfolioGraphCardContent}
      >
        <Box position="relative" width='100%'>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
            containerProps={{
              style: {
                width: '99%',
              },
            }}
            {...props}
          />
          <Button
            variant="contained"
            style={{
              position: 'absolute',
              top: '25px',
              right: '30px',
              backgroundColor: '#8346FF',
              color: 'white',
              padding: '5px 15px',
              borderRadius: '20px',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
            onClick={() => {}}
          >
            <FormattedMessage id='portfolioGraph.seeMore.button' />
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PortfolioGraph;
