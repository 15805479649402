import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    width: '78.05px',
    height: '47px',
    [theme.breakpoints.down('md')]: {
      width: '54.8px',
      height: '33px',
    },
  },
  navLinks: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  navButton: {
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  navButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '24px',
    fontSize: '16px',
  },
  navButtonLanguageChoice: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '24px',
    fontSize: '16px',
  },
  navButtonLanguageActiveOption: {
    color: '#0BF5D9',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  navButtonLanguageOption: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuIcon: {
    color: 'white',
    width: '40px',
    height: '40px',
  },
  iconButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    margin: '30px 29px 40px 0px',
  },
  closeIcon: {
    color: 'white',
    width: '40px',
    height: '40px',
  },
  drawerContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoContainer: {
    paddingTop: '43px',
    paddingLeft: '33px',
  },
  list: {
    padding: '29px',
  },
  mobileNavButtonLanguageOption: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    textAlign: 'end',
    paddingBottom: '30px',
  },
  mobileNavButtonLanguageActiveOption: {
    textAlign: 'end',
    paddingBottom: '30px',
    color: '#0BF5D9',
  },
}));

export default useStyles;
