/* General Components Styling */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  portfolioGraphCardContent: {
    padding: '0px !important',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
