import React, { MouseEvent, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  IconButton,
  Box,
  Divider,
  Popover,
} from '@mui/material';

import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';

import {
  ThumbUp, Comment, Share, ContentCopy,
} from '@mui/icons-material';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { GetCommentsByPostResponse, GetPostsPerPageResponse } from '../../requests/api/apiTypes';
import CreateComment from './components/CreateComment';
import apiClient from '../../requests/api/apiClient';
import CommentList from './components/CommentList';

type ExtendedPostType = { postInfo: Pick<GetPostsPerPageResponse['posts'][number], 'id' | 'user' | 'text' | 'imageUrl' | 'videoUrl' | 'createdAt'> } & {
  numOfLikes: number,
  numOfComments: number,
  likedByUser?: boolean,
  handleLikeButton?: ({ postId }: { postId: string }) => void,
  sharedView?: boolean,
};

const Post: React.FC<ExtendedPostType> = ({
  postInfo,
  numOfComments,
  numOfLikes,
  likedByUser,
  handleLikeButton,
  sharedView,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [comments, setComments] = useState<GetCommentsByPostResponse['comments'] | undefined>([]);
  const [commentQty, setCommentQty] = useState<number>(numOfComments);
  const {
    user, text, imageUrl, id: postId, createdAt,
  } = postInfo;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleShareClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/share/${postId}`);
    enqueueSnackbar(intl.formatMessage({ id: 'post.linkCopied' }), { variant: 'success' });
    handleClose();
  };

  const fetchComments = async () => {
    try {
      const payload = {
        postId: postInfo.id,
      };
      const { data } = await apiClient.comment.getCommentsByPostList(payload);
      setComments(data.comments);
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'post.error.getComments' }), { variant: 'error' });
    }
  };

  const handleShowComments = async () => {
    await fetchComments();
    setShowCommentSection(true);
  };

  const fetchCommentQty = async () => {
    try {
      // TODO - manage pagination
      const payload = {
        postId: postInfo.id,
      };
      const { data } = await apiClient.comment.getCommentQtyList(payload);
      setCommentQty(data.commentQty);
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'post.error.getCommentQty' }), { variant: 'error' });
    }
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar src={user.avatarUrl || ''} alt={user.name} className={classes.avatar} />}
        title={
          <Box>
            <Typography className={classes.userName}>
              {user.name}
            </Typography>
            <Typography className={classes.userIdentifier}>
              @{user.nickname}
            </Typography>
          </Box>
        }
        subheader={intl.formatDate(createdAt, { year: 'numeric', month: 'long', day: 'numeric' })}
        subheaderTypographyProps={{
          fontFamily: 'Inter',
          fontSize: '15px',
          fontWeight: '500',
          lineHeight: '18.15px',
          color: '#BDBDBD',
        }}
      />
      <Divider variant='middle' className={classes.horizontalDivider} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.content}>
          {text}
        </Typography>
      </CardContent>
      {imageUrl && (
        <CardMedia
          component="img"
          image={imageUrl}
          className={classes.media}
        />
      )}
      <Box className={classes.dataPost}>
        <IconButton aria-label={intl.formatMessage({ id: 'post.like' })}>
          <ThumbUp className={likedByUser ? classes.icons : classes.iconsNotActive} />
          <Typography className={classes.dataPostText}>
            {numOfLikes} <FormattedMessage id="post.likes" />
          </Typography>
        </IconButton>
        <IconButton aria-label={intl.formatMessage({ id: 'post.comment' })}>
          <Comment className={classes.icons} />
          <Typography className={classes.dataPostText}>
            {commentQty} <FormattedMessage id="post.comments" />
          </Typography>
        </IconButton>
      </Box>
      {!sharedView && <>
        <Divider variant='middle' className={classes.horizontalDivider} />
        <Box className={classes.actions}>
          <IconButton aria-label={intl.formatMessage({ id: 'post.like' })} className={classes.iconButton} onClick={handleLikeButton ? () => handleLikeButton({ postId }) : undefined}>
            <ThumbUp className={classes.icons} />
            <Typography className={classes.actionText}>
              <FormattedMessage id="post.like" />
            </Typography>
          </IconButton>
          <Divider variant='middle' orientation='vertical' flexItem className={classes.verticalDivider} />
          <IconButton aria-label={intl.formatMessage({ id: 'post.comment' })} className={classes.iconButton} onClick={() => handleShowComments()}>
            <Comment className={classes.icons} />
            <Typography className={classes.actionText}>
              <FormattedMessage id="post.comment" />
            </Typography>
          </IconButton>
          <Divider variant='middle' orientation='vertical' flexItem className={classes.verticalDivider} />
          <IconButton aria-label={intl.formatMessage({ id: 'post.share' })} aria-describedby={id} className={classes.iconButton} onClick={handleShareClick}>
            <Share className={classes.icons} />
            <Typography className={classes.actionText}>
              <FormattedMessage id="post.share" />
            </Typography>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box className={classes.shareButtonsContainer}>
              <FacebookShareButton url={`${process.env.REACT_APP_BASE_URL}/share/${postId}`} title={'teste'}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton url={`${process.env.REACT_APP_BASE_URL}/share/${postId}`} title={'tests'}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <WhatsappShareButton url={`${process.env.REACT_APP_BASE_URL}/share/${postId}`} title={'tests'}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>

              <LinkedinShareButton url={`${process.env.REACT_APP_BASE_URL}/share/${postId}`} title={'tests'}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <IconButton onClick={handleCopy}><ContentCopy /></IconButton>
            </Box>
          </Popover>
        </Box>
      </>}
      {showCommentSection && <CreateComment
        postId={postInfo.id}
        handleUpdatePost={fetchComments}
        fetchCommentQty={fetchCommentQty}
      />}
      {showCommentSection && comments && comments.length > 0 && (
        <CommentList comments={comments} handleUpdatePost={fetchComments} />
      )}
    </Card>
  );
};

export default Post;
