const currencyFormatter = (num: number) => {
  if (num !== 0) {
    return `$ ${num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
  return '$ 0';
};

export {
  currencyFormatter,
};
