import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const backgroundWelcomeImage = require('../../assets/images/background-login-logo-purple.jpg');
const aboutImage = require('../../assets/images/home-2.png');
const howWorksImage = require('../../assets/images/devices.png');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 'Poppins',
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: '14px',
    },
  },
  purpleBackground: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
  },
  whiteBackground: {
    backgroundColor: theme.palette.primary.white,
    color: '#605A5A',
  },
  textColorGrey: {
    color: '#605A5A',
  },
  purpleColorText: {
    color: theme.palette.primary.main,
  },
  backgroundImageBox: {
    backgroundImage: `url(${backgroundWelcomeImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '786px',
    borderRadius: '0px 0px 30px 30px',
    [theme.breakpoints.down('md')]: {
      height: '629px',
    },
  },
  navbarBox: {
    paddingLeft: '11vh',
    paddingRight: '6vh',
    paddingTop: '4vh',
    [theme.breakpoints.down('md')]: {
      paddingTop: '40px',
      paddingLeft: '33px',
    },
  },
  welcomeTextBox: {
    paddingTop: '15vh',
    paddingLeft: '13vh',
    fontFamily: 'Poppins, sans-serif',
    [theme.breakpoints.down('md')]: {
      paddingTop: '80px',
      paddingLeft: '33px',
    },
  },
  welcomeTitle: {
    fontSize: '70px',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '74.2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '38px',
      lineHeight: '40.28px',
    },
  },
  welcomeTitleExpansion: {
    fontSize: '70px',
    color: '#0BF5D9',
    fontFamily: 'IBM Plex Mono',
    lineHeight: '74.2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '38px',
      lineHeight: '40.28px',
    },
  },
  welcomeTitleSlogan: {
    marginTop: '25px',
    fontSize: '25px',
    fontWeight: '600',
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '35px',
    [theme.breakpoints.down('md')]: {
      marginTop: '19px',
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
  welcomeTitleText: {
    marginTop: '31px',
    fontSize: '20px',
    fontWeight: 'normal',
    color: 'white',
    maxWidth: '588px',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '29px',
    [theme.breakpoints.down('md')]: {
      marginTop: '13px',
      fontSize: '16px',
      lineHeight: '23.2px',
    },
  },
  getStartedButton: {
    marginTop: '31px',
    borderRadius: '100px',
    textTransform: 'capitalize',
    fontWeight: '700',
    background: '#0BF5D9',
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    color: '#8346FF',
    [theme.breakpoints.down('md')]: {
      marginTop: '36px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  rowBox: {
    paddingTop: '13vh',
    paddingLeft: '13vh',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingTop: '33px',
      paddingLeft: '33px',
      flexDirection: 'column',
    },
  },
  aboutSection: {
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column-reverse',
    },
  },
  aboutTextBox: {
    alignItems: 'center',
  },
  aboutQuestionText: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '65px',
    fontWeight: 'bold',
    lineHeight: '91px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '44.8px',
    },
  },
  aboutResponseText: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    maxWidth: '517px',
    fontWeight: '400',
    lineHeight: '24.8px',
    color: '#605A5A',
    paddingTop: '4px',
    [theme.breakpoints.down('lg')]: {
      paddingRight: '40px',
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: '0px',
      fontSize: '15px',
      lineHeight: '23.25px',
    },
  },
  aboutImage: {
    // eslint-disable-next-line global-require
    backgroundImage: `url(${aboutImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '538px',
    width: '50%',
    borderRadius: '7.22px 0px 0px 7.22px',
    borderColor: 'black',
    boxShadow: '-4.12px -4.12px 4.12px rgba(0, 0, 0, 0.07)',
    [theme.breakpoints.down('md')]: {
      borderRadius: '7.22px',
      width: '330px',
      height: '206px',
      alignSelf: 'center',
      marginBottom: '40px',
      marginTop: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'start',
    },
  },
  featureBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '16vh',
  },
  featureTitle: {
    width: '100%',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '55px',
    lineHeight: '77px',
    color: '#605A5A',
    paddingTop: '4px',
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: '700',
    maxWidth: '950px',
    [theme.breakpoints.down('md')]: {
      fontSize: '35px',
      lineHeight: '45.5px',
    },
  },
  featureCardsBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, max(304.99px, 100%/4)), 1fr))',
    justifyItems: 'center',
    gap: '20px',
    paddingTop: '4vh',
    paddingLeft: '11.7%',
    paddingRight: '11.7%',
    [theme.breakpoints.down('md')]: {
      padding: '33px',
      gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, max(323px, 100%/4)), 1fr))',
    },
  },
  featureCard: {
    padding: '40px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  icon: {
    width: '53.2px',
    height: '53.2px',
  },
  titleFeatureCard: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '19.37px',
    lineHeight: '28.09px',
    fontWeight: '700',
  },
  contentFeatureCard: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
    fontSize: '15.07px',
    lineHeight: '21.85px',
  },
  howWorksSection: {
    paddingLeft: '0px',
    justifyContent: 'center',
  },
  mainTitle: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '700',
    fontSize: '45px',
    lineHeight: '63px',
    marginBottom: '21px',
    marginLeft: '15%',
    [theme.breakpoints.down('md')]: {
      margin: '0px',
      textAlign: 'center',
      fontSize: '30px',
      lineHeight: '42px',
    },
  },
  howWorksCardSection: {
    marginRight: '15%',
    marginLeft: '15%',
    [theme.breakpoints.down('md')]: {
      marginRight: '20px',
      marginLeft: '20px',
      marginTop: '40px',
    },
  },
  howWorksBox: {
    width: '100%',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    justifyContent: 'start',
    gap: '10.72px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  howWorksShadow: {
    boxShadow: '0px 0px 4px rgba(168, 167, 167, 0.25)',
  },
  howWorksImage: {
    // eslint-disable-next-line global-require
    backgroundImage: `url(${howWorksImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'fill',
    height: '650px',
    width: '800px',
    [theme.breakpoints.down('md')]: {
      height: '616.58px',
      width: '100%',
      backgroundSize: '490px',
      paddingBottom: '20px',
      backgroundPosition: 'center',
    },
  },
  howWorksTitle: {
    width: '100%',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '26.1px',
    paddingTop: '2px',
    textAlign: 'start',
  },
  howWorksContent: {
    width: '100%',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    lineHeight: '20.3px',
    paddingTop: '2px',
    textAlign: 'start',
  },
  howWorksNumberBox: {
    paddingTop: '3px',
    paddingRight: '11px',
    paddingLeft: '11px',
    paddingBottom: '3px',
    width: '39.71px',
    height: '39.71px',
    borderRadius: '32.07px',
    alignSelf: 'start',
  },
  howWorksNumber: {
    width: '17px',
    height: '35px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    fontSize: '24.62px',
    lineHeight: '35.43px',
  },
  whiteBackgroundPurpleText: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.white,
  },
  testimonialSection: {
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {
      padding: '36px 26px 90px 26px',
    },
  },
  testimonialPurleBox: {
    width: '60%',
    height: '648px',
    borderRadius: '20px 0px 0px 20px',
    backgroundColor: theme.palette.primary.main,
    zIndex: '-1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [theme.breakpoints.down('lg')]: {
      height: '800px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'None',
    },
  },
  doubleQuote: {
    padding: '60px',
  },
  testimonialCard: {
    width: '100%',
    padding: '30px 20px',
    boxShadow: '0px 0px 4px rgba(168, 167, 167, 0.25)',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      width: '22%',
      height: '527px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '357px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '327px',
    },
  },
  testimonialCardContent: {
    alignItems: 'center',
    width: '100%',
  },
  testimonialName: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'center',
    color: 'black',
  },
  testimonialText: {
    fontFamily: 'Poppins, sans-serif',
    paddingTop: '5%',
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#000002',
  },
  testimonialTitleBox: {
    paddingTop: '5%',
    width: '90%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px',
      width: '100%',
    },
  },
  testimonialsBox: {
    width: '100%',
    paddingTop: '250px',
    alignSelf: 'start',
    position: 'absolute',
    right: '0px',
    flexWrap: 'wrap',
    justifyContent: 'start',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      padding: '0px',
      marginTop: '36px',
    },
  },
  avatar: {
    width: '95px',
    height: '95px',
    margin: '0 auto',
  },
  paddingZero: {
    padding: '0px',
  },
  joinUsSection: {
    paddingTop: '145px',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  joinUsTitle: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '700',
    fontSize: '45px',
    lineHeight: '63px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '41.6px',
    },
  },
  joinUsText: {
    fontFamily: 'Poppins, sans-serif',
    width: '693px',
    paddingTop: '22px',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '29px',
    color: '#605A5A',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '16px',
      lineHeight: '23.2px',
    },
  },
  joinUsButton: {
    marginTop: '58px',
    borderRadius: '100px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    padding: '19px 24px',
    textTransform: 'capitalize',
  },
  clausesSection: {
    marginTop: '100px',
    background: '#F0F0F0',
    padding: '36px 100px 36px 70px',
    [theme.breakpoints.down('md')]: {
      padding: '46px 27px 50px 27px',
    },
  },
  clausesText: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '14.85px',
    color: '#727272',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 0px 0px 0px',
    },
  },
  footerSection: {
    backgroundColor: '#2B2B2B',
    display: 'flex',
    justifyContent: 'space-around',
    color: theme.palette.primary.white,
    padding: '60px 40px 100px',
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {

    },
  },
  footerImage: {
    display: 'flex',
    alignItems: 'center',
    width: '76.89px',
    height: '46.41px',
    [theme.breakpoints.down('md')]: {
      width: '54.8px',
      height: '33px',
    },
  },
  footerBox: {
    width: '23%',
    color: theme.palette.primary.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingTop: '30px',
    },
  },
  footerTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '26.1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '23.2px',
    },
  },
  footerText: {
    marginTop: '5px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    lineHeight: '20.3px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
      lineHeight: '18.85px',
    },
  },
  socialMediaIcon: {
    height: '36px',
    width: '36px',
    margin: '10px',
    [theme.breakpoints.down('md')]: {
      height: '30px',
      width: '30px',
      margin: '0px',
      marginRight: '20px',
    },
  },
  socialContainer: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'start',
      marginTop: '23px',
    },
  },
  logoContainer: {
    width: '19%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px',
      marginBottom: '19px',
    },
  },
}));

export default useStyles;
