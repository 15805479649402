import {
  DEFAULT_API_ERROR_MESSAGE,
  EXPIRED_LINK,
  GMAIL_CONNECTION_ERROR,
  GMAIL_USER_NOT_FOUND,
  INCORRECT_LOGIN,
  INVALID_ARGUMENT,
  INVALID_ROLE,
  INVALID_TOKEN,
  LOGIN_WITH_EMAIL,
  NO_TOKEN,
  NO_USERS_FOUND,
  PASSWORDS_DO_NOT_MATCH,
  PROHIBITED,
  REQUIRED_EMAIL,
  REQUIRED_TOKEN,
  TOKEN_NOT_GENERATED,
  USED_INVALID_TOKEN,
  USER_ALREADY_EXIST,
  USER_DOES_NOT_EXIST,
  USER_INACTIVE,
  USER_NOT_REGISTERED,
  VERIFICATION_MAIL_ERROR,
  WRONG_CREDENTIALS,
} from '../utils/errors';

import {
  PASSWORD_UPDATED,
  RECOVER_PASSWORD_MAIL_SENT,
  SUCCESSFUL_LOGIN,
  USERS_DELETED,
  USERS_FOUND,
  USER_CREATED,
  USER_EDITED,
  USER_FOUND,
  USER_VERIFIED,
} from '../utils/success';

export default {
  'login.header': 'Welcome to TiL!',
  'login.header.subtitle': 'The Social Network of Finance',
  'login.title': 'Sign in',
  'login.subtitle': 'Login to the backoffice platform',
  'login.email': 'Email',
  'login.email.required': 'Email required',
  'login.email.invalidFormat': 'Invalid email format',
  'login.password': 'Password',
  'login.password.required': 'Password required',
  'login.login': 'Sign in',
  'login.termsAndConditions': 'Terms and Conditions',
  'login.missingAccount': 'Don\'t have an account?',
  'login.signUp': 'Sign up',
  'login.or': 'or',
  'login.continueGoogle': 'Sign in with Google',
  'login.forgottenPassword': 'Forgot Password?',
  'login.readTermsConditions.required': 'You need to accept terms and conditions before sign in',
  'login.registerUser.checkEmail': 'User created correctly. Check your email to activate your account',
  'login.registerUser.invalidEmail': 'Invalid email format',
  'login.registerUser.cannotBeEmpty': 'can\'t be empty',
  'navigation.navbar.admin.header.title': 'Administration',
  'navigation.navbar.admin.subheader.title.users': 'Users',
  'navigation.topbar.account.account': 'Account',
  'navigation.topbar.account.closeSession': 'Close Session',
  'navigation.topbar.account.error.unableCloseSession': 'Session couldn\'t be closed',
  'navigation.topbar.settings.title': 'Settings',
  'navigation.topbar.settings.theme.title': 'Theme',
  'navigation.topbar.settings.language.title': 'Language',
  'navigation.topbar.settings.language.option.english': 'English',
  'navigation.topbar.settings.language.option.spanish': 'Spanish',
  'navigation.topbar.settings.save.button': 'Save',
  'noAuthorization.title': 'No authorization',
  'noAuthorization.firstMessage': 'Oops! You don\'t have permission to access this page. Please request permission from your account administrator, click ',
  'noAuthorization.here': 'here ',
  'noAuthorization.secondMessage': ' to go to your main page, or click ',
  'noAuthorization.thirdMessage': ' to change user.',
  'userInformation.signUpSaveButton': 'Sign up',
  'userInformation.googleSignUpSaveButton': 'Sign up with Google',
  'userInformation.editButton': 'Edit information',
  'userInformation.error.name': 'Name',
  'userInformation.error.lastname': 'Last Name',
  'userInformation.error.nickname': 'Nickname',
  'userInformation.error.country': 'Country',
  'userInformation.error.email': 'Email',
  'userInformation.error.password': 'Password',
  'userInformation.error.repeatedNickname': 'Nickname already in use. Choose another one',
  'userInformation.textFieldLabel.name': 'Name *',
  'userInformation.textFieldLabel.lastname': 'Last Name *',
  'userInformation.textFieldLabel.nickname': 'Nickname *',
  'userInformation.textFieldLabel.country': 'Country *',
  'userInformation.textFieldLabel.jobTitle': 'Job Title',
  'userInformation.textFieldLabel.company': 'Company',
  'userInformation.textFieldLabel.personalUrl': 'Personal URL',
  'userInformation.textFieldLabel.phone': 'Phone',
  'userInformation.textFieldLabel.email': 'Email *',
  'userInformation.textFieldLabel.description': 'Description',
  'userInformation.randomAvatar.button': 'New avatar',
  'termsAndConditions.title': 'Terms and Conditions',
  'termsAndConditions.disclaimer': 'Disclaimers:',
  'termsAndConditions.noFinancialAdvice': 'No Financial Advice Provided: ',
  'termsAndConditions.noFinancialAdviceParagraph': 'The information provided on this platform, including any simulations or educational materials, is for informational purposes only. It does not constitute financial advice or recommendations to buy, sell, or trade any digital assets. Users are solely responsible for their investment decisions and should seek independent financial advice before engaging in any trading activities.',
  'termsAndConditions.simulationEducation': 'Simulations for Educational Purposes: ',
  'termsAndConditions.simulationEducationParagraph': 'Any simulations or trading scenarios presented on this platform are for educational purposes only. They are not indicative of actual market conditions or guaranteed outcomes. Users should not rely solely on these simulations when making investment decisions. The platform does not guarantee the accuracy, completeness, or reliability of the simulations, and users acknowledge that actual trading results may vary.',
  'termsAndConditions.userResponsibility': 'User Responsibility: ',
  'termsAndConditions.userResponsibilityParagraph': 'Users of this platform are solely responsible for their own trading activities and investment decisions. The platform and its owners do not assume any liability for losses incurred as a result of trading digital assets. Users should conduct thorough research, consider their risk tolerance, and seek professional advice before engaging in any trading activities. The platform and its owners are not liable for any financial losses or damages arising from user actions or decisions made based on the information provided on the platform.',
  'termsAndConditions.beforeUsing': 'Before using TiL, this platform, you acknowledge and agree that TiL Simulations are intended solely for educational and simulation purposes and do not constitute investment advice or recommendations. Although the simulations are based on real data, they are educational in nature and should not be acted upon without prior review by a qualified financial advisor before implementing any investment idea. Past performance is not indicative of future results, and our platform is not liable for any losses resulting from reliance on any TiL Simulation. Users accept full responsibility for their own investment choices and must refrain from providing financial advice in the platform. By using our platform, you understand and agree to these terms, acknowledging the limitations of the tools and content provided.',
  'termsAndConditions.agreeButton': 'Agree',
  'termsAndConditions.cancelButton': 'Cancel',
  'userVerification.password.required': 'Password required',
  'userVerification.password.oneLetter': 'Password must contain at least one letter',
  'userVerification.password.oneNumber': 'Password must contain at least one number',
  'userVerification.password.tooShort': 'Password must be at least 8 characters long',
  'userVerification.ConfirmPassword.required': 'Password confirmation required',
  'userVerification.ConfirmPassword.match': 'Passwords must match',
  'topbar.navigation.home': 'Home',
  'topbar.navigation.video': 'Video',
  'topbar.navigation.myPortfolio': 'My Portfolio',
  'topbar.navigation.assets': 'Assets',
  'topbar.navigation.network': 'Network',
  'topbar.navigation.ranking': 'Ranking',
  'topbar.navigation.messages': 'Messages',
  'topbar.navigation.notifications': 'Notifications',
  'landing.nav.home': 'Home',
  'landing.nav.about': 'About',
  'landing.nav.features': 'Features',
  'landing.nav.testimonials': 'Testimonials',
  'landing.nav.contact': 'Contact',
  'landing.welcome.title': 'Welcome to TiL',
  'landing.welcome.expansion': 'TradesiLike',
  'landing.welcome.slogan': 'The Social Network of Finance',
  'landing.welcome.message': 'Join us in this financial knowledge journey. Manage virtual portfolios, compete and engage with others and learn how to implement effective financial strategies',
  'landing.welcome.getStarted': 'Get started now',
  'landing.about.question': 'What is TiL?',
  'landing.about.response': 'TiL is the Social Networking of Finance and Money learning. Our name reflects our mission to make learning finance and virtual trading accessible and enjoyable for everyone. Our platform allows users to manage virtual portfolios of stocks, cryptocurrencies, and commodities with real-time prices. TiL\'s mission is to socialize and democratize personal finance, through a platform where users can engage with other users, share strategies, play to learn finance, and compete with others in an ongoing simulated game.',
  'landing.features.title.part1': 'Play and learn with ',
  'landing.features.title.part2': ' Features',
  'landing.features.firstFeature.title': 'Social Components',
  'landing.features.firstFeature.text': 'Stay connected with friends, follow their progress, chat, share updates, and more.',
  'landing.features.secondFeature.title': 'Competitive Rankings',
  'landing.features.secondFeature.text': 'Compete with other users and see how your strategies stack up.',
  'landing.features.thirdFeature.title': 'Social Components',
  'landing.features.thirdtFeature.text': 'Experience real-time portfolio management with live price updates.',
  'landing.features.fourthFeature.title': 'Fair Comparison',
  'landing.features.fourthFeature.text': 'Our unique time-based formula ensures fair comparison of portfolio performance.',
  'landing.features.fifthFeature.title': 'Diverse Assets',
  'landing.features.fifthFeature.text': 'Manage a portfolio that includes stocks, cryptocurrencies, and commodities.',
  'landing.features.sixthFeature.title': 'Educational Focus',
  'landing.features.sixthFeature.text': 'Learn to achieve consistent returns by understanding the right timing and strategies for portfolio recalibration.',
  'landing.howWorks.title': 'How Does TiL Work?',
  'landing.howWorks.firstStep.title': 'Sign Up:',
  'landing.howWorks.firstStep.content': 'Create your free account in minutes.',
  'landing.howWorks.secondStep.title': 'Build Your Portfolio:',
  'landing.howWorks.secondStep.content': 'Choose from a wide range of assets including stocks, cryptos, and commodities.',
  'landing.howWorks.thirdStep.title': 'Compete & Improve:',
  'landing.howWorks.thirdStep.content': 'Compare your performance with others using our fair ranking system.',
  'landing.howWorks.fourthStep.title': 'Get New Ideas:',
  'landing.howWorks.fourthStep.content': 'Compare the trades you like vs the top performing players. Learn new insights and get new ideas from their most successful strategies.',
  'landing.testimonial.title': 'What Our Users Are Saying:',
  'landing.testimonial.first.name': 'Gabriel Souza',
  'landing.testimonial.first.testimonial': '"TiL has given me the confidence to make informed investment decisions. The community is fantastic, and learning here feels natural and enjoyable."',
  'landing.testimonial.second.name': 'Sarah Miller',
  'landing.testimonial.second.content': '"TiL changed how I view personal finance. Learning from others and applying what I learn has made financial literacy enjoyable and empowering."',
  'landing.testimonial.third.name': 'James Cooper',
  'landing.testimonial.third.content': '"TiL keeps me engaged with its interactive features. Joining financial clubs and competing with others has helped me learn more than I expected."',
  'landing.testimonial.fourth.name': 'Priya Sharma',
  'landing.testimonial.fourth.content': '"Learning finance on TiL is practical and fun. The clubs and rankings motivate me to keep improving my understanding of money management."',
  'landing.joinUs.title': 'Ready to Elevate Your Financial Game?',
  'landing.joinUs.content': 'Join us on the TiL network today and start your financial journey towards a better potential of learning, gaming and financial future.',
  'landing.joinUs.buttonText': 'Create Your Free Account Now',
  'landing.contactUs.title': 'Contact Us:',
  'landing.contactUs.text': 'For any queries or support, please reach out to us at support@tradesilike.com.',
  'landing.followUs.title': 'Follow Us:',
  'landing.followUs.text': 'Stay updated by following us on',
  'landing.clauses.start': 'Any TiL information provided is solely for educational and entertainment purposes and does not constitute a financial advice.',
  'landing.clauses.1': '1. Prohibition of False Statements: Users are strictly prohibited from making false statements or providing misleading information about any digital assets listed on the platform. This includes spreading false news, or any information intended to manipulate the market. Users found guilty of making false statements will face immediate account suspension.',
  'landing.clauses.2': '2. Insider Trading Prohibition: Users are prohibited from engaging in insider trading or using non-public information to trade digital assets on the platform. Any user found participating in insider trading activities will face termination of their account.',
  'landing.clauses.3': '3. Front Running Prohibition: Users are strictly prohibited from engaging in front-running activities on the back of TiL data, which involve placing orders to benefit from advance knowledge of pending transactions. This includes trading ahead of customer orders or using privileged information to gain an unfair advantage. Users found engaging in front-running will face strict penalties, including permanent suspension of the account.',
  'landing.clauses.4': '4. Wash Trading Prohibition: Users are prohibited from engaging in wash trading, which involves creating artificial trading activity by simultaneously buying and selling the same digital asset. Wash trading is strictly prohibited as it distorts market prices and misleads other users.',
  'landing.clauses.5': '5. Spoofing Prohibition: Users are prohibited from engaging in spoofing, which involves placing a large order to create a false impression of supply or demand, and then canceling the order. This manipulative tactic is strictly prohibited, and users found engaging in spoofing will face severe penalties, including account suspension.',
  'landing.clauses.6': '6. Market Cornering Prohibition: Users are prohibited from attempting to manipulate the market by cornering or controlling a significant portion of a specific asset. Any user found attempting to corner the market could face strict penalties, including account suspension.',
  'landing.clauses.7': '7. Reporting Obligations: Users are required to promptly report any suspicious activities or market manipulation attempts they come across while using the platform. This includes reporting any instances of potential attempt of market manipulation, insider trading, or other manipulative activities. Failure to report such activities may result in penalties, including potential liability for damages caused by the manipulation.',
  'landing.clauses.8': '8. Cooperation with Regulatory Authorities: Users must fully cooperate with regulatory authorities in any investigations related to market manipulation or fraudulent activities. This includes providing requested information and assisting in any investigation process.',
  'landing.clauses.9': '9. Continuous Monitoring: The platform will implement robust systems and procedures to continuously monitor user activities for any signs of market manipulation, even this simulation being a virtual game. This may include the use of advanced surveillance technologies and data analysis techniques to identify suspicious patterns or behavior.',
  'landing.clauses.10': '10. User Education and Awareness: The platform will actively educate users about market manipulation risks and the importance of maintaining fair and transparent markets. This may include providing educational resources, guidelines, and regular communication to ensure users are aware of their responsibilities and the consequences of engaging in market manipulation.',
  'landing.clauses.dataProtection': 'Data Protection: The TIL platform is committed to ensuring the protection of the user data while maintaining an appropriate level of privacy. TIL operates in strict compliance with all applicable local regulations. Furthermore, TIL is dedicated to preventing any anti-competitive practices, thereby upholding the highest standards of legal and ethical conduct.',
  'post.likes': 'Likes',
  'post.like': 'Like',
  'post.comments': 'Comments',
  'post.comment': 'Comment',
  'post.share': 'Share',
  'post.add': 'Add post...',
  'post.error.sendComment': 'Could not send your comment. Please try again later.',
  'post.error.sendReply': 'Could not send your reply. Please try again later.',
  'post.error.getCommentQty': 'Could not get the number of comments. Please try again later.',
  'post.error.getComments': 'Could not get the comments. Please try again later.',
  'post.error.publishFailed': 'An error occurred while publishing your post. Please try again in a moment',
  'post.success.published': 'Your post has been published successfully!',
  'post.error.invalidImage': 'Please upload an image',
  'post.linkCopied': 'Link copied to clipboard!',
  'portfolioGraph.seeMore.button': 'See More',
  'home.error.unableToGetPosts': 'Latest posts couldn\'t be loaded. Please try again in a moment.',
  'home.error.unableToGetPortfolios': 'Portfolios couldn\'t be loaded. Please try again in a moment',
  'home.error.unableToGetDailyMovers': 'Prices from Daily Movers could not be retrieved',
  'home.portfolio.createPortfolio': 'Create Portfolio',
  'home.portfolio.createPortfolioSubtitle': 'To start your trading journey, you need to create a portfolio',
  'home.portfolio.portfolioName': 'Portfolio Name',
  'home.portfolio.benchmark': 'Benchmark',
  'home.portfolio.noBenchmarks': 'No benchmarks found',
  'home.error.unableToCreatePortfolio': 'Portfolio couldn\'t be created. Please try again in a moment',
  'home.error.unableToGetRankingPortfolios': 'Ranking portfolios couldn\'t be loaded. Please try again in a moment',
  'home.error.unableToGetHomeGraphInformation': 'Home graph information couldn\'t be loaded. Please try again in a moment',
  'home.success.portfolioCreated': 'Portfolio created. You are ready to start!',
  'home.noMorePosts': 'No more posts',
  'groups.of': 'of',
  'groups.error': 'Groups cloudn\'t be loaded. Please try again in a moment.',
  'comment.add': 'Add comment...',
  'comment.reply': 'Reply',
  'rankingList.noPortfoliosFound': 'No portfolios found for this timeframe',
  'share.title': 'Shared Post',
  'topbar.goToLogin': 'Go to Login',

  // API ERRORS
  [NO_TOKEN]: 'Token not found',
  [INVALID_TOKEN]: 'Failed to authenticate token',
  [INVALID_ROLE]: 'Invalid role',
  [REQUIRED_TOKEN]: 'Token required',
  [EXPIRED_LINK]: 'Link expired',
  [PROHIBITED]: 'Prohibited',
  [USED_INVALID_TOKEN]: 'Token already used or invalid',
  [USER_INACTIVE]: 'Inactive user',
  [USER_DOES_NOT_EXIST]: 'User does not exist',
  [WRONG_CREDENTIALS]: 'Incorrect username or password',
  [PASSWORDS_DO_NOT_MATCH]: 'Passwords do not match',
  [TOKEN_NOT_GENERATED]: 'Token could not be generated',
  [REQUIRED_EMAIL]: 'Email required',
  [USER_NOT_REGISTERED]: 'User not registered',
  [VERIFICATION_MAIL_ERROR]: 'Error sending verification email',
  [GMAIL_USER_NOT_FOUND]: 'Gmail: user not found. Please check',
  [GMAIL_CONNECTION_ERROR]: 'Gmail connection error',
  [USER_ALREADY_EXIST]: 'Email already exists',
  [INVALID_ARGUMENT]: 'Invalid argument',
  [NO_USERS_FOUND]: 'No users found',
  [LOGIN_WITH_EMAIL]: 'User created without google. Please login with email and password',
  [INCORRECT_LOGIN]: 'Incorrect login',
  [DEFAULT_API_ERROR_MESSAGE]: 'Internal server error',
  // API SUCCESS
  [USER_VERIFIED]: 'User verified successfully',
  [SUCCESSFUL_LOGIN]: 'Successful login',
  [RECOVER_PASSWORD_MAIL_SENT]: 'Recovery password email sent',
  [PASSWORD_UPDATED]: 'Password updated',
  [USER_CREATED]: 'User created successfully',
  [USERS_FOUND]: 'Users found successfully',
  [USER_EDITED]: 'User edited successfully',
  [USER_FOUND]: 'User found',
  [USERS_DELETED]: 'User(s) deleted successfully',
};
