import {
  DEFAULT_API_ERROR_MESSAGE,
  EXPIRED_LINK,
  GMAIL_CONNECTION_ERROR,
  GMAIL_USER_NOT_FOUND,
  INCORRECT_LOGIN,
  INVALID_ARGUMENT,
  INVALID_ROLE,
  INVALID_TOKEN,
  LOGIN_WITH_EMAIL,
  NO_TOKEN,
  NO_USERS_FOUND,
  PASSWORDS_DO_NOT_MATCH,
  PROHIBITED,
  REQUIRED_EMAIL,
  REQUIRED_TOKEN,
  TOKEN_NOT_GENERATED,
  USED_INVALID_TOKEN,
  USER_ALREADY_EXIST,
  USER_DOES_NOT_EXIST,
  USER_INACTIVE,
  USER_NOT_REGISTERED,
  VERIFICATION_MAIL_ERROR,
  WRONG_CREDENTIALS,
} from '../utils/errors';

import {
  PASSWORD_UPDATED,
  RECOVER_PASSWORD_MAIL_SENT,
  SUCCESSFUL_LOGIN,
  USERS_DELETED,
  USERS_FOUND,
  USER_CREATED,
  USER_EDITED,
  USER_FOUND,
  USER_VERIFIED,
} from '../utils/success';

export default {
  'login.header': '¡Bienvenido a TiL!',
  'login.header.subtitle': 'La Red Social de Finanzas',
  'login.title': 'Ingresa',
  'login.subtitle': 'Ingresa a la plataforma de backoffice',
  'login.email': 'Correo',
  'login.email.required': 'Correo requerido',
  'login.email.invalidFormat': 'Formato de email inválido',
  'login.password': 'Contraseña',
  'login.password.required': 'Contraseña requerida',
  'login.login': 'Iniciar sesión',
  'login.termsAndConditions': 'Términos y Condiciones',
  'login.missingAccount': '¿No tienes cuenta?',
  'login.signUp': '¡Regístrate!',
  'login.or': 'o',
  'login.continueGoogle': 'Continua con Google',
  'login.forgottenPassword': '¿Olvidaste tu contraseña?',
  'login.readTermsConditions.required': 'Necesitas aceptar términos y condiciones antes de ingresar',
  'login.registerUser.checkEmail': 'Usuario creado correctamente. Revisa tu correo para activarlo',
  'login.registerUser.invalidEmail': 'Formato de email inválido',
  'login.registerUser.cannotBeEmpty': 'no puede estar vacío',
  'navigation.navbar.admin.header.title': 'Administración',
  'navigation.navbar.admin.subheader.title.users': 'Usuarios',
  'navigation.topbar.account.account': 'Cuenta',
  'navigation.topbar.account.closeSession': 'Cerrar Sesión',
  'navigation.topbar.account.error.unableCloseSession': 'No se pudo cerrar sesión',
  'navigation.topbar.settings.title': 'Ajustes',
  'navigation.topbar.settings.theme.title': 'Tema',
  'navigation.topbar.settings.language.title': 'Idioma',
  'navigation.topbar.settings.language.option.english': 'Inglés',
  'navigation.topbar.settings.language.option.spanish': 'Español',
  'navigation.topbar.settings.save.button': 'Guardar',
  'noAuthorization.title': 'Sin autorizacion',
  'noAuthorization.firstMessage': '¡Ups! No tienes permiso para acceder esta página. Por favor pide permiso al administrador de tu cuenta, clickea ',
  'noAuthorization.here': 'aquí',
  'noAuthorization.secondMessage': ' para ir a tu página principal, o clickea ',
  'noAuthorization.thirdMessage': ' para cambiar de usuario.',
  'userInformation.signUpSaveButton': 'Registrate',
  'userInformation.googleSignUpSaveButton': 'Registrate con Google',
  'userInformation.editButton': 'Editar información',
  'userInformation.error.name': 'Nombre',
  'userInformation.error.lastname': 'Apellido',
  'userInformation.error.nickname': 'Apodo',
  'userInformation.error.country': 'País',
  'userInformation.error.email': 'Correo Electrónico',
  'userInformation.error.password': 'Contraseña',
  'userInformation.error.repeatedNickname': 'Apodo en uso. Escoger otro apodo',
  'userInformation.textFieldLabel.name': 'Nombre *',
  'userInformation.textFieldLabel.lastname': 'Apellido *',
  'userInformation.textFieldLabel.nickname': 'Apodo *',
  'userInformation.textFieldLabel.country': 'País *',
  'userInformation.textFieldLabel.jobTitle': 'Título del trabajo',
  'userInformation.textFieldLabel.company': 'Compañía',
  'userInformation.textFieldLabel.personalUrl': 'URL Personal',
  'userInformation.textFieldLabel.phone': 'Teléfono',
  'userInformation.textFieldLabel.email': 'Correo Electrónico *',
  'userInformation.textFieldLabel.description': 'Descripción',
  'userInformation.randomAvatar.button': 'Nuevo avatar',
  'termsAndConditions.title': 'Términos y Condiciones',
  'termsAndConditions.disclaimer': 'Avisos Legales: ',
  'termsAndConditions.noFinancialAdvice': 'No se Proporciona Asesoramiento Financiero: ',
  'termsAndConditions.noFinancialAdviceParagraph': 'La información proporcionada en esta plataforma, incluidas cualquier simulación o material educativo, es solo para fines informativos. No constituye asesoramiento financiero ni recomendaciones para comprar, vender o negociar activos digitales. Los usuarios son los únicos responsables de sus decisiones de inversión y deben buscar asesoramiento financiero independiente antes de participar en cualquier actividad de comercio.',
  'termsAndConditions.simulationEducation': 'Simulaciones con Fines Educativos: ',
  'termsAndConditions.simulationEducationParagraph': 'Cualquier simulación o escenario de comercio presentado en esta plataforma es únicamente para fines educativos. No son indicativos de las condiciones reales del mercado ni de resultados garantizados. Los usuarios no deben confiar únicamente en estas simulaciones al tomar decisiones de inversión. La plataforma no garantiza la precisión, integridad o fiabilidad de las simulaciones, y los usuarios reconocen que los resultados reales de comercio pueden variar.',
  'termsAndConditions.userResponsibility': 'Responsabilidad del Usuario: ',
  'termsAndConditions.userResponsibilityParagraph': 'Los usuarios de esta plataforma son los únicos responsables de sus actividades comerciales y decisiones de inversión. La plataforma y sus propietarios no asumen ninguna responsabilidad por pérdidas incurridas como resultado de la negociación de activos digitales. Los usuarios deben realizar una investigación exhaustiva, considerar su tolerancia al riesgo y buscar asesoramiento profesional antes de participar en cualquier actividad de comercio. La plataforma y sus propietarios no son responsables de ninguna pérdida financiera o daño derivado de las acciones o decisiones de los usuarios basadas en la información proporcionada en la plataforma.',
  'termsAndConditions.beforeUsing': 'Antes de usar TiL, esta plataforma, usted reconoce y acepta que las Simulaciones TiL están destinadas únicamente a fines educativos y de simulación y no constituyen asesoramiento de inversión ni recomendaciones. Aunque las simulaciones se basan en datos reales, son de naturaleza educativa y no deben ejecutarse sin revisión previa por un asesor financiero calificado antes de implementar cualquier idea de inversión. El rendimiento pasado no es indicativo de resultados futuros, y nuestra plataforma no es responsable por ninguna pérdida resultante de la confianza en cualquier Simulación TiL. Los usuarios aceptan la plena responsabilidad de sus propias decisiones de inversión y deben abstenerse de brindar asesoramiento financiero en la plataforma. Al usar nuestra plataforma, usted entiende y acepta estos términos, reconociendo las limitaciones de las herramientas y el contenido proporcionado.',
  'termsAndConditions.agreeButton': 'Acepto',
  'termsAndConditions.cancelButton': 'Cancelar',
  'userVerification.password.required': 'Contraseña requerida',
  'userVerification.password.oneLetter': 'La contraseña debe contener al menos una letra',
  'userVerification.password.oneNumber': 'La contraseña debe contener al menos un número',
  'userVerification.password.tooShort': 'La contraseña debe tener al menos 8 caracteres',
  'userVerification.ConfirmPassword.required': 'Confirmación de contraseña requerida',
  'userVerification.ConfirmPassword.match': 'Las contraseñas deben coincidir',
  'topbar.navigation.home': 'Home',
  'topbar.navigation.video': 'Video',
  'topbar.navigation.myPortfolio': 'Mi Portafolio',
  'topbar.navigation.assets': 'Activos',
  'topbar.navigation.network': 'Network',
  'topbar.navigation.ranking': 'Ranking',
  'topbar.navigation.messages': 'Mensajes',
  'topbar.navigation.notifications': 'Notificaciones',
  'landing.nav.home': 'Inicio',
  'landing.nav.about': 'Nosotros',
  'landing.nav.features': 'Características',
  'landing.nav.testimonials': 'Testimonios',
  'landing.nav.contact': 'Contáctanos',
  'landing.welcome.title': 'Bienvenido a TiL',
  'landing.welcome.expansion': 'TradesiLike',
  'landing.welcome.slogan': 'La red social de finanzas',
  'landing.welcome.message': 'Únete a nosotros en este viaje de conocimiento financiero. Administra portafolios virtuales, compite e interactúa con otros y aprende cómo implementar estrategias financieras efectivas.',
  'landing.welcome.getStarted': 'Empieza ahora',
  'landing.about.question': '¿Qué es TiL?',
  'landing.about.response': 'TiL es la red social de finanzas y aprendizaje sobre dinero. Nuestro nombre refleja nuestra misión de hacer que el aprendizaje de finanzas y trading sea accesible y agradable para todos. Nuestra plataforma permite a los usuarios gestionar carteras virtuales de acciones, criptomonedas y materias primas con precios en tiempo real. La misión de TiL es socializar y democratizar las finanzas personales, a través de una plataforma donde los usuarios pueden interactuar con otros, compartir estrategias, aprender sobre finanzas jugando y competir con otros en un juego simulado continuo.',
  'landing.features.title.part1': 'Juega y aprende con ',
  'landing.features.title.part2': ' y sus características',
  'landing.features.firstFeature.title': 'Componentes sociales',
  'landing.features.firstFeature.text': 'Mantente conectado con amigos, sigue su progreso, chatea, comparte actualizaciones y más.',
  'landing.features.secondFeature.title': 'Clasificaciones competitivas',
  'landing.features.secondFeature.text': 'Compite con otros usuarios y compara cómo se desempeñan tus estrategias.',
  'landing.features.thirdFeature.title': 'Simulación en tiempo real',
  'landing.features.thirdtFeature.text': 'Experimenta la gestión de carteras en tiempo real con actualizaciones de precios en vivo.',
  'landing.features.fourthFeature.title': 'Comparación justa',
  'landing.features.fourthFeature.text': 'Nuestra fórmula única basada en el tiempo asegura una comparación justa del rendimiento de las carteras.',
  'landing.features.fifthFeature.title': 'Activos diversos',
  'landing.features.fifthFeature.text': 'Administra una cartera que incluye acciones, criptomonedas y materias primas.',
  'landing.features.sixthFeature.title': 'Enfoque educativo',
  'landing.features.sixthFeature.text': 'Aprende a obtener rendimientos consistentes entendiendo el momento y las estrategias correctas para recalibrar tu cartera.',
  'landing.howWorks.title': '¿Cómo Funciona TiL?',
  'landing.howWorks.firstStep.title': 'Regístrate:',
  'landing.howWorks.firstStep.content': 'Crea tu cuenta gratuita en minutos.',
  'landing.howWorks.secondStep.title': 'Construye Tu Portafolio:',
  'landing.howWorks.secondStep.content': 'Elige entre una amplia gama de activos, incluyendo acciones, criptomonedas y materias primas.',
  'landing.howWorks.thirdStep.title': 'Compite y Mejora:',
  'landing.howWorks.thirdStep.content': 'Compara tu rendimiento con otros utilizando nuestro sistema de clasificación justo.',
  'landing.howWorks.fourthStep.title': 'Obtén Nuevas Ideas:',
  'landing.howWorks.fourthStep.content': 'Compara las operaciones que te gustan con las de los jugadores de mejor desempeño. Aprende nuevos conocimientos y obtén nuevas ideas de sus estrategias más exitosas.',
  'landing.testimonial.title': 'Lo que dicen nuestros usuarios:',
  'landing.testimonial.first.name': 'Gabriel Souza',
  'landing.testimonial.first.testimonial': '"TiL me ha dado la confianza para tomar decisiones de inversión informadas. La comunidad es fantástica, y aprender aquí se siente natural y agradable."',
  'landing.testimonial.second.name': 'Sarah Miller',
  'landing.testimonial.second.content': '"TiL cambió la forma en que veo las finanzas personales. Aprender de otros y aplicar lo que aprendo ha hecho que la educación financiera sea agradable y empoderadora."',
  'landing.testimonial.third.name': 'James Cooper',
  'landing.testimonial.third.content': '"TiL me mantiene comprometido con sus funciones interactivas. Unirme a clubes financieros y competir con otros me ha ayudado a aprender más de lo que esperaba."',
  'landing.testimonial.fourth.name': 'Priya Sharma',
  'landing.testimonial.fourth.content': '"Aprender finanzas en TiL es práctico y divertido. Los clubes y las clasificaciones me motivan a seguir mejorando mi comprensión de la gestión del dinero."',
  'landing.joinUs.title': '¿Listo para llevar tus finanzas al siguiente nivel?',
  'landing.joinUs.content': 'Únete hoy a la red de TiL y comienza tu viaje financiero hacia un mejor potencial de aprendizaje, juegos y futuro financiero.',
  'landing.joinUs.buttonText': 'Crea tu cuenta gratuita ahora',
  'landing.contactUs.title': 'Contáctanos:',
  'landing.contactUs.text': 'Para cualquier consulta o soporte, por favor contáctanos en support@til.com.',
  'landing.followUs.title': 'Síguenos:',
  'landing.followUs.text': 'Mantente actualizado siguiéndonos en',
  'landing.clauses.start': 'Cualquier información proporcionada por TiL es únicamente con fines educativos y de entretenimiento y no constituye asesoramiento financiero.',
  'landing.clauses.1': '1. Prohibición de Declaraciones Falsas: Se prohíbe estrictamente a los usuarios hacer declaraciones falsas o proporcionar información engañosa sobre cualquier activo digital listado en la plataforma. Esto incluye la difusión de noticias falsas o cualquier información destinada a manipular el mercado. Los usuarios que se encuentren culpables de hacer declaraciones falsas enfrentarán la suspensión inmediata de su cuenta.',
  'landing.clauses.2': '2. Prohibición de Operaciones con Información Privilegiada: Se prohíbe a los usuarios realizar operaciones con información privilegiada o utilizar información no pública para comerciar con activos digitales en la plataforma. Cualquier usuario que participe en actividades de operaciones con información privilegiada enfrentará la terminación de su cuenta.',
  'landing.clauses.3': '3. Prohibición de Front Running: Se prohíbe estrictamente a los usuarios participar en actividades de "front running" utilizando datos de TiL, lo cual implica realizar órdenes para beneficiarse de conocimiento anticipado de transacciones pendientes. Esto incluye operar antes de órdenes de clientes o utilizar información privilegiada para obtener una ventaja injusta. Los usuarios que se encuentren involucrados en "front running" enfrentarán sanciones estrictas, incluida la suspensión permanente de su cuenta.',
  'landing.clauses.4': '4. Prohibición de Wash Trading: Se prohíbe a los usuarios realizar "wash trading", que consiste en crear actividad comercial artificial comprando y vendiendo simultáneamente el mismo activo digital. El "wash trading" está estrictamente prohibido, ya que distorsiona los precios del mercado y engaña a otros usuarios.',
  'landing.clauses.5': '5. Prohibición de Spoofing: Se prohíbe a los usuarios participar en "spoofing", que implica realizar una orden grande para crear una impresión falsa de oferta o demanda, y luego cancelar la orden. Esta táctica manipuladora está estrictamente prohibida, y los usuarios que participen en "spoofing" enfrentarán sanciones severas, incluida la suspensión de la cuenta.',
  'landing.clauses.6': '6. Prohibición de Cornering del Mercado: Se prohíbe a los usuarios intentar manipular el mercado mediante el acaparamiento o control de una porción significativa de un activo específico. Cualquier usuario que intente acaparar el mercado podría enfrentar sanciones estrictas, incluida la suspensión de la cuenta.',
  'landing.clauses.7': '7. Obligaciones de Reporte: Los usuarios deben informar de inmediato cualquier actividad sospechosa o intento de manipulación del mercado que encuentren mientras usan la plataforma. Esto incluye reportar cualquier instancia de posible intento de manipulación del mercado, operaciones con información privilegiada u otras actividades manipuladoras. La falta de reporte de dichas actividades puede resultar en sanciones, incluida la posible responsabilidad por daños causados por la manipulación.',
  'landing.clauses.8': '8. Cooperación con las Autoridades Regulatorias: Los usuarios deben cooperar plenamente con las autoridades regulatorias en cualquier investigación relacionada con manipulación de mercado o actividades fraudulentas. Esto incluye proporcionar la información solicitada y asistir en cualquier proceso de investigación.',
  'landing.clauses.9': '9. Monitoreo Continuo: La plataforma implementará sistemas y procedimientos robustos para monitorear continuamente las actividades de los usuarios en busca de cualquier señal de manipulación del mercado, incluso si esta simulación es un juego virtual. Esto puede incluir el uso de tecnologías avanzadas de vigilancia y técnicas de análisis de datos para identificar patrones o comportamientos sospechosos.',
  'landing.clauses.10': '10. Educación y Conciencia del Usuario: La plataforma educará activamente a los usuarios sobre los riesgos de manipulación del mercado y la importancia de mantener mercados justos y transparentes. Esto puede incluir proporcionar recursos educativos, directrices y comunicación regular para garantizar que los usuarios sean conscientes de sus responsabilidades y las consecuencias de participar en la manipulación del mercado.',
  'landing.clauses.dataProtection': 'Protección de Datos: La plataforma TiL está comprometida en garantizar la protección de los datos de los usuarios, manteniendo un nivel adecuado de privacidad. TiL opera en estricto cumplimiento con todas las regulaciones locales aplicables. Además, TiL está dedicada a prevenir cualquier práctica anticompetitiva, manteniendo los más altos estándares de conducta legal y ética.',
  'post.likes': 'Me gusta',
  'post.like': 'Me gusta',
  'post.comment': 'Comentar',
  'post.comments': 'Comentarios',
  'post.share': 'Compartir',
  'post.add': 'Añadir publicación...',
  'post.error.sendComment': 'No se pudo enviar su comentario. Por favor intentelo en otro momento.',
  'post.error.sendReply': 'No se pudo enviar su respuesta. Por favor intentelo en otro momento.',
  'post.error.getCommentQty': 'No se obtener la cantidad de comentarios. Por favor intentelo en otro momento.',
  'post.error.getComments': 'No se pudo obtener la cantidad de comentarios. Por favor intentelo en otro momento.',
  'post.error.publishFailed': 'Ocurrió un error al publicar tu post. Por favor, inténtalo de nuevo en otro momento',
  'post.success.published': '¡Tu post se ha publicado con éxito!',
  'post.error.invalidImage': 'Por favor suba una imagen',
  'post.linkCopied': '¡Enlace copiado al portapapeles!',
  'portfolioGraph.seeMore.button': 'Ver más',
  'home.error.unableToGetPosts': 'Los últimos publicaciones no se pudieron cargar. Por favor, inténtalo de nuevo en un momento',
  'home.error.unableToGetDailyMovers': 'Precios de los movimientos diarios no están disponibles',
  'home.error.unableToGetPortfolios': 'Portafolios no se pudieron cargar. Por favor, inténtalo de nuevo en un momento.',
  'home.portfolio.createPortfolio': 'Crear Portafolio',
  'home.portfolio.createPortfolioSubtitle': 'Para empezar tu viaje de trading, necesitas crear un portafolio',
  'home.portfolio.portfolioName': 'Nombre del Portafolio',
  'home.portfolio.benchmark': 'Benchmark',
  'home.portfolio.noBenchmarks': 'Benchmarks no encontrados',
  'home.error.unableToCreatePortfolio': 'Portafolio no se pudo crear. Por favor, inténtalo de nuevo en un momento',
  'home.error.unableToGetRankingPortfolios': 'Portafolios del ranking no se pudieron cargar. Por favor, inténtalo de nuevo en un momento',
  'home.error.unableToGetHomeGraphInformation': 'Información del gráfico de inicio no se pudieron cargar. Por favor, inténtalo de nuevo en un momento',
  'home.success.portfolioCreated': 'Portafolio creado. ¡Estás listo para empezar!',
  'home.noMorePosts': 'No hay más publicaciones',
  'groups.of': 'de',
  'groups.error': 'No se pudieron cargar los grupos. Por favor inténtalo en otro momento',
  'comment.add': 'Añadir comentario...',
  'comment.reply': 'Responder',
  'rankingList.noPortfoliosFound': 'No hay portafolios para este rango de tiempo',
  'share.title': 'Post Compartido',
  'topbar.goToLogin': 'Ir a Login',
  // API ERRORS
  [NO_TOKEN]: NO_TOKEN,
  [INVALID_TOKEN]: INVALID_TOKEN,
  [INVALID_ROLE]: INVALID_ROLE,
  [REQUIRED_TOKEN]: REQUIRED_TOKEN,
  [EXPIRED_LINK]: EXPIRED_LINK,
  [PROHIBITED]: PROHIBITED,
  [USED_INVALID_TOKEN]: USED_INVALID_TOKEN,
  [USER_INACTIVE]: USER_INACTIVE,
  [USER_DOES_NOT_EXIST]: USER_DOES_NOT_EXIST,
  [WRONG_CREDENTIALS]: WRONG_CREDENTIALS,
  [PASSWORDS_DO_NOT_MATCH]: PASSWORDS_DO_NOT_MATCH,
  [TOKEN_NOT_GENERATED]: TOKEN_NOT_GENERATED,
  [REQUIRED_EMAIL]: REQUIRED_EMAIL,
  [USER_NOT_REGISTERED]: USER_NOT_REGISTERED,
  [VERIFICATION_MAIL_ERROR]: VERIFICATION_MAIL_ERROR,
  [GMAIL_USER_NOT_FOUND]: GMAIL_USER_NOT_FOUND,
  [GMAIL_CONNECTION_ERROR]: GMAIL_CONNECTION_ERROR,
  [USER_ALREADY_EXIST]: USER_ALREADY_EXIST,
  [INVALID_ARGUMENT]: INVALID_ARGUMENT,
  [NO_USERS_FOUND]: NO_USERS_FOUND,
  [LOGIN_WITH_EMAIL]: LOGIN_WITH_EMAIL,
  [INCORRECT_LOGIN]: INCORRECT_LOGIN,
  [DEFAULT_API_ERROR_MESSAGE]: DEFAULT_API_ERROR_MESSAGE,
  // API SUCCESS
  [USER_VERIFIED]: USER_VERIFIED,
  [SUCCESSFUL_LOGIN]: SUCCESSFUL_LOGIN,
  [RECOVER_PASSWORD_MAIL_SENT]: RECOVER_PASSWORD_MAIL_SENT,
  [PASSWORD_UPDATED]: PASSWORD_UPDATED,
  [USER_CREATED]: USER_CREATED,
  [USERS_FOUND]: USERS_FOUND,
  [USER_EDITED]: USER_EDITED,
  [USER_FOUND]: USER_FOUND,
  [USERS_DELETED]: USERS_DELETED,
};
