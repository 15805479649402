import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: 'white',
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: '14px',
    },
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: '#22335E',
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '100px',
  },
  googleButtonDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  googleButton: {
    color: 'black',
    textTransform: 'none',
    width: '100%',
    backgroundColor: '#E2D7F5',
    borderRadius: '12px',
    padding: '10px',
    '&:hover': {
      backgroundColor: '#c3a6f7',
    },
  },
  resetPassword: {
    display: 'flex',
    textTransform: 'none',
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    margin: '1rem auto 0px auto',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  termsAndConditions: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  signUpButton: {
    display: 'inline-block',
    textTransform: 'none',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  missingAccountText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  signInButton: {
    borderRadius: '100px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '20px',
    // fontFamily: 'Inter, sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  loginAndPhotoContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },
  loginContainer: {
    flex: 2,
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 25px',
    maxWidth: '610px',
  },
  form: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px',
    },
  },
  betaVersionContainer: {
    width: '100%',
    textAlign: 'center',
    margin: '10px 0px 40px 0px',
    color: 'rgba(131, 70, 255, 0.5)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  welcomeTitle: {
    fontSize: '58px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    // fontFamily: 'Poppins, sans-serif',
    [theme.breakpoints.down('xl')]: {
      textAlign: 'center',
      whiteSpace: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
    },
  },
  welcomeTitleSubheader: {
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // fontFamily: 'Poppins, sans-serif',
    [theme.breakpoints.down('xl')]: {
      whiteSpace: 'normal',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  photoContainer: {
    flex: 3,
    display: 'flex',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '12px',
    overflow: 'hidden',
  },
  backgroundImageContainer: {
    margin: 'auto',
    height: '95%',
    width: '97%',
    position: 'relative',
  },
  logoImageTil: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '45%',
    maxWidth: '40%',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '53%',
    },
  },
  logoImageLegend: {
    position: 'absolute',
    top: '85%',
    left: '53%',
    maxHeight: '16%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '33%',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '42%',
      top: '83%',
    },
  },
  // Terms and conditions
  termsAndConditionsButtonsContainer: {
    display: 'flex',
    marginTop: '2rem',
    justifyContent: 'center',
    [theme.breakpoints.down(530)]: {
      flexDirection: 'column-reverse',
    },
  },
  termsAndConditionsModal: {
    maxWidth: '700px',
    maxHeight: '85%',
    overflow: 'auto',
    padding: '10px',
    margin: '15px',
  },
  termsAndConditionsModalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  termsAndConditionsTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  termsAndConditionsTextTitles: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  termsAndConditionsTextParagraph: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 'normal',
  },
  termsAndConditonsTextTitlesParagraphContainer: {
    display: 'flex',
  },
  termsAndConditionsCancelButton: {
    backgroundColor: '#F6F5FF',
    color: theme.palette.text.secondary,
    borderRadius: '100px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    textTransform: 'capitalize',
    minWidth: '200px',
    border: 'none',
    marginRight: '28px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#eae8fc',
      border: 'none',
    },
    [theme.breakpoints.down(530)]: {
      margin: '0px',
    },
  },
  termsAndConditionsAgreeButton: {
    backgroundColor: 'white',
    color: theme.palette.text.secondary,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '100px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1)',
    textTransform: 'capitalize',
    minWidth: '200px',
    fontWeight: 'bold',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      border: '2px solid',
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down(530)]: {
      marginBottom: '25px',
    },
  },
}));

export default useStyles;
