import React, { useState } from 'react';
import {
  Avatar,
  IconButton,
  Box,
  TextField,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ReduxState } from '../../../../types';
import apiClient from '../../../../requests/api/apiClient';
import useStyles from './styles';
import { CreateCommentProps } from './types';

type ExtendedCreateCommentType = CreateCommentProps & {
  handleUpdatePost: () => Promise<void>,
  fetchCommentQty : () => Promise<void>,
};

const CreateComment: React.FC<ExtendedCreateCommentType> = (
  {
    postId, groupId, parentCommentId, handleUpdatePost, fetchCommentQty,
  },
) => {
  const intl = useIntl();
  const classes = useStyles();
  const [content, setContent] = useState<string>('');
  const { user } = useSelector((state: ReduxState) => state.account);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleSend = async () => {
    try {
      if (content.trim()) {
        const payload = {
          userId: user?.id,
          ...(postId && { postId }),
          ...(groupId && { groupId }),
          ...(parentCommentId && { parentCommentId }),
          content,
        };
        await apiClient.comment.commentCreate(payload);
        await handleUpdatePost();
        await fetchCommentQty();
        setContent('');
      }
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'post.error.publishFailed' }), { variant: 'error' });
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Avatar className={classes.avatar} src={user?.avatarUrl || ''} />
        <TextField
          className={classes.input}
          placeholder={intl.formatMessage({ id: 'comment.add' })}
          aria-label={intl.formatMessage({ id: 'comment.add' })}
          multiline
          value={content}
          onChange={handleChange}
        />
        <IconButton
          className={`${classes.iconButton} ${!content.trim() ? classes.iconButtonDisabled : ''}`}
          aria-label="send"
          disabled={!content.trim()}
          onClick={handleSend}
        >
          <Send fontSize='small'/>
        </IconButton>
      </Box>
    </Box>
  );
};

export default CreateComment;
