import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  shareContainerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  shareContainer: {
    maxWidth: '700px',
    display: 'flex',
    flex: 1,
  },

}));

export default useStyles;
