import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
} from '@mui/material';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import Page from '../../components/Layout/Page';
import LoginForm from './components/LoginForm';
import AuthService from '../../services/authService';
import useStyles from './styles';
import { ReduxState } from '../../types';
import CustomModal from '../../components/General/CustomModal';
import UserInformation from '../../components/Auth/UserInformation';
import apiClient from '../../requests/api/apiClient';
import { RegisterRequest } from '../../requests/api/apiTypes';
import { FetchError } from '../types';
import TermsAndConditionsContentModal from './components/TermsAndConditionsContentModal';
import { ExtendedRegisterRequest } from './types';
import { setUserData } from '../../actions/accountActions';

const REQUIRED_SIGN_UP_FIELDS = new Set(['name', 'lastname', 'nickname', 'country', 'email']);
const getSignUpInformationMapping = (intl: IntlShape): { [key: string]: string } => ({
  name: intl.formatMessage({ id: 'userInformation.error.name' }),
  lastname: intl.formatMessage({ id: 'userInformation.error.lastname' }),
  nickname: intl.formatMessage({ id: 'userInformation.error.nickname' }),
  country: intl.formatMessage({ id: 'userInformation.error.country' }),
  email: intl.formatMessage({ id: 'userInformation.error.email' }),
});
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function LoginView() {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const account = useSelector((state: ReduxState) => state.account);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [signUpInformation, setSignUpInformation] = useState<ExtendedRegisterRequest>({
    email: '',
    name: '',
    lastname: '',
    personalUrl: '',
    nickname: '',
    jobTitle: '',
    company: '',
    country: '',
    phone: '',
    description: '',
    avatarUrl: '',
    action: 'signUp',
  });
  const [errors, setErrors] = useState<Pick<RegisterRequest, 'name' | 'lastname' | 'nickname' | 'country' | 'email'>>({
    name: '',
    lastname: '',
    email: '',
    nickname: '',
    country: '',
  });
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [openTermsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false);

  const SIGN_UP_INFORMATION_MAPPING = getSignUpInformationMapping(intl);

  useEffect(() => {
    window.location.href = '/';
  }, []);

  useEffect(() => {
    if (account?.user && AuthService.isAuthenticated()) {
      history.push('/home');
    }
  }, [account.user]);

  const handleCloseSignUpModal = () => {
    setOpenSignUpModal(false);
    setSignUpInformation({
      email: '',
      name: '',
      lastname: '',
      personalUrl: '',
      nickname: '',
      jobTitle: '',
      company: '',
      country: '',
      phone: '',
      description: '',
      avatarUrl: '',
      action: 'signUp',
    });
    setErrors({
      name: '',
      lastname: '',
      email: '',
      nickname: '',
      country: '',
    });
  };

  const handleUpsertUser = async () => {
    let counter = 0;
    Object.entries(signUpInformation).forEach(([key, value]) => {
      if (REQUIRED_SIGN_UP_FIELDS.has(key) && !value) {
        setErrors((prevState) => ({ ...prevState, [key]: `${SIGN_UP_INFORMATION_MAPPING[key] as string} ${intl.messages['login.registerUser.cannotBeEmpty']}` }));
        counter += 1;
      }
    });

    if (!EMAIL_REGEX.test(signUpInformation.email)) {
      setErrors((prevState) => ({ ...prevState, email: intl.messages['login.registerUser.invalidEmail'] as string }));
      return;
    }

    if (counter > 0) {
      return;
    }

    const { action, ...rest } = signUpInformation;

    try {
      if (action === 'signUp') {
        await apiClient.register.registerCreate(rest);
        enqueueSnackbar(intl.messages['login.registerUser.checkEmail'], { variant: 'success' });
        handleCloseSignUpModal();
      } else {
        const { data } = await apiClient.register.registerCreate(rest);
        handleCloseSignUpModal();
        dispatch(setUserData(data.user));
        AuthService.setSession(data?.token ?? '');
        history.replace('/home');
      }
    } catch (err) {
      // TODO / REVISAR - ver tema de i18n
      const { error: fetchError } = err as FetchError;
      if (fetchError?.message) {
        enqueueSnackbar(fetchError?.message, { variant: 'error' });
      } else {
        const auxError = err as Error;
        enqueueSnackbar(auxError.message, { variant: 'error' });
      }
    }
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box className={classes.loginAndPhotoContainer}>
        <Box sx={{
          display: 'flex', flexDirection: 'column',
        }}>
          <Box className={classes.loginContainer}>
            <Typography className={classes.welcomeTitle} >
              <FormattedMessage id='login.header' />
            </Typography>
            <Typography className={classes.welcomeTitleSubheader}>
              <FormattedMessage id='login.header.subtitle' />
            </Typography>
            <Box mt={3}>
              <LoginForm
                setOpenSignUpModal={setOpenSignUpModal}
                setUserInformation={setSignUpInformation}
                termsAndConditions={termsAndConditions}
                setOpenTermsAndConditionsModal={setOpenTermsAndConditionsModal}
              />
            </Box>
          </Box>
          <Box className={classes.betaVersionContainer}>Beta Version</Box>
        </Box>
        <Box className={classes.photoContainer}>
          <Box
            className={classes.backgroundImageContainer}
          >
            <img
              // eslint-disable-next-line global-require
              src={require('../../assets/images/background-login-logo-purple.jpg')}
              className={classes.backgroundImage}
            />
          </Box>
          <img
            alt="Logo"
            // eslint-disable-next-line global-require
            src={require('../../assets/images/logo.png')}
            className={classes.logoImageTil}
          />
        </Box>
      </Box>
      <CustomModal open={openSignUpModal} handleClose={handleCloseSignUpModal}>
        <UserInformation
          userInformation={signUpInformation}
          setUserInformation={setSignUpInformation}
          handleUpsertUser={handleUpsertUser}
          errors={errors}
          setErrors={setErrors}
          requiredValues={REQUIRED_SIGN_UP_FIELDS}
        />
      </CustomModal>
      <CustomModal
        open={openTermsAndConditionsModal}
        handleClose={() => setOpenTermsAndConditionsModal(false)}
        cardClassName={classes.termsAndConditionsModal}
      >
        <TermsAndConditionsContentModal
          handleClose={setOpenTermsAndConditionsModal}
          handleAcceptTerms={setTermsAndConditions}
        />
      </CustomModal>
    </Page>
  );
}

export default LoginView;
