import React, { useState } from 'react';
import {
  Avatar, Badge, Box, Button, Grid, InputAdornment, MenuItem, TextField, Typography,
} from '@mui/material';
import { Lock, Refresh } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import useStyles from '../styles';
import useFetchApi from '../../hooks/useFetchApi';
import { GetUsersNicknamesResponse } from '../../requests/api/apiTypes';
import { ExtendedRegisterRequest } from '../../views/Login/types';
import axios from '../../utils/axios';
import { FetchError } from '../../views/types';

type RequiredKeys = 'name' | 'lastname' | 'nickname' | 'country' | 'email';

const UserInformation = ({
  userInformation,
  setUserInformation,
  handleUpsertUser,
  errors,
  setErrors,
  requiredValues,
}: {
  userInformation: ExtendedRegisterRequest,
  setUserInformation: React.Dispatch<React.SetStateAction<ExtendedRegisterRequest>>,
  handleUpsertUser: VoidFunction,
  errors: Pick<ExtendedRegisterRequest, RequiredKeys>,
  setErrors: React.Dispatch<React.SetStateAction<Pick<ExtendedRegisterRequest, RequiredKeys>>>,
  requiredValues: Set<string>,

}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [avatarImage, setAvatarImage] = useState<string | null>(null);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    source: string,
  ) => {
    setUserInformation((prevState) => ({ ...prevState, [source]: e.target.value }));
    if (requiredValues.has(source) && errors[source as RequiredKeys] !== '') {
      setErrors((prevState) => ({ ...prevState, [source]: '' }));
    }
  };

  let allNicknames: string[] = [];

  const getSourceLegend = () => {
    if (userInformation.action === 'signUp') {
      return 'userInformation.signUpSaveButton';
    }

    if (userInformation.action === 'googleSignUp') {
      return 'userInformation.googleSignUpSaveButton';
    }

    return 'userInformation.editButton';
  };

  const { data } = useFetchApi<GetUsersNicknamesResponse>({
    modelName: 'usersNicknames',
    modelEndpoint: 'usersNicknamesList',
  });

  if (data?.allUsersNicknames) {
    allNicknames = data.allUsersNicknames.map((user) => user.nickname ?? '');
  }

  const checkUpsert = () => {
    if (allNicknames.includes(userInformation.nickname)) {
      setErrors((prevState) => ({ ...prevState, nickname: intl.messages['userInformation.error.repeatedNickname'] as string }));
      return;
    }

    handleUpsertUser();
  };

  const getRandomAvatar = async () => {
    setLoadingAvatar(true);
    const randomize = (Math.random() * 100000000000000000000).toString();
    try {
      const { data: avatarPng } = await axios.get(`https://api.multiavatar.com/${randomize}.png`, {
        responseType: 'blob',
      });

      const objectUrl = URL.createObjectURL(avatarPng);
      setAvatarImage(objectUrl);
      setUserInformation((prevState) => ({ ...prevState, avatarUrl: `https://api.multiavatar.com/${randomize}.png` }));
    } catch (err) {
      const { error: fetchError } = err as FetchError;
      if (fetchError?.message) {
        enqueueSnackbar(fetchError?.message, { variant: 'error' });
      } else {
        const auxError = err as Error;
        enqueueSnackbar(auxError.message, { variant: 'error' });
      }
    } finally {
      setLoadingAvatar(false);
    }
  };

  return (
    <Box
      className={classes.userInformationContainer}
    >
      {/* Header */}
      <Box display="flex" alignItems="center" mb={2}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<Refresh className={loadingAvatar
            ? classes.userInformationRefreshIconDisabled
            : classes.userInformationRefreshIcon
          } />}
          onClick={loadingAvatar ? undefined : getRandomAvatar}
        >
          <Avatar
            alt="User Avatar"
            sx={{ width: 70, height: 70 }}
            src={avatarImage || ''}
          />
        </Badge>
        <Box ml={2} display='flex' flexDirection='column'>
          <Typography className={classes.avatarName}>
            {(!userInformation.name && !userInformation.lastname) ? <span className={classes.userInformationTypographyPlaceholder}>Full name</span> : `${userInformation.name} ${userInformation.lastname ?? ''}`}
          </Typography>
          <Typography className={classes.avatarNickname}>{userInformation.nickname ? `@${userInformation.nickname}` : <span className={classes.userInformationTypographyPlaceholder}>@nickname</span>}</Typography>
        </Box>
      </Box>

      {/* Form Fields */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={intl.messages['userInformation.textFieldLabel.name']}
            variant="outlined"
            value={userInformation.name}
            onChange={(e) => handleChangeValue(e, 'name')}
            error={errors.name !== ''}
            helperText={errors.name !== '' && errors.name}
            disabled={userInformation.action === 'googleSignUp'}
            InputProps={{
              endAdornment: userInformation.action === 'googleSignUp' ? (
                <InputAdornment position="end">
                  <Lock />
                </InputAdornment>
              ) : undefined,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={intl.messages['userInformation.textFieldLabel.lastname']}
            variant="outlined"
            value={userInformation.lastname}
            onChange={(e) => handleChangeValue(e, 'lastname')}
            error={errors.lastname !== ''}
            helperText={errors.lastname !== '' && errors.lastname}
            disabled={userInformation.action === 'googleSignUp' && userInformation.lastname !== undefined}
            InputProps={{
              endAdornment: userInformation.action === 'googleSignUp' && userInformation.lastname === undefined ? (
                <InputAdornment position="end">
                  <Lock />
                </InputAdornment>
              ) : undefined,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={intl.messages['userInformation.textFieldLabel.nickname']}
            variant="outlined"
            value={userInformation.nickname}
            onChange={(e) => handleChangeValue(e, 'nickname')}
            error={errors.nickname !== ''}
            helperText={errors.nickname !== '' && errors.nickname}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            label={intl.messages['userInformation.textFieldLabel.country']}
            value={userInformation.country}
            onChange={(e) => handleChangeValue(e, 'country')}
            error={errors.country !== ''}
            helperText={errors.country !== '' && errors.country}
          >
            <MenuItem value="United States">United States</MenuItem>
            <MenuItem value="Canada">Canada</MenuItem>
            <MenuItem value="Mexico">Mexico</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={intl.messages['userInformation.textFieldLabel.jobTitle']}
            variant="outlined"
            value={userInformation.jobTitle}
            onChange={(e) => handleChangeValue(e, 'jobTitle')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={intl.messages['userInformation.textFieldLabel.company']}
            variant="outlined"
            value={userInformation.company}
            onChange={(e) => handleChangeValue(e, 'company')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={intl.messages['userInformation.textFieldLabel.personalUrl']}
            variant="outlined"
            value={userInformation.personalUrl}
            onChange={(e) => handleChangeValue(e, 'personalUrl')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={intl.messages['userInformation.textFieldLabel.phone']}
            variant="outlined"
            value={userInformation.phone}
            onChange={(e) => handleChangeValue(e, 'phone')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={intl.messages['userInformation.textFieldLabel.email']}
            variant="outlined"
            value={userInformation.email}
            onChange={(e) => handleChangeValue(e, 'email')}
            error={errors.email !== ''}
            helperText={errors.email !== '' && errors.email}
            disabled={userInformation.action === 'googleSignUp'}
            InputProps={{
              endAdornment: userInformation.action === 'googleSignUp' ? (
                <InputAdornment position="end">
                  <Lock />
                </InputAdornment>
              ) : undefined,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label={intl.messages['userInformation.textFieldLabel.description']}
            variant="outlined"
            value={userInformation.description}
            onChange={(e) => handleChangeValue(e, 'description')}
          />
        </Grid>
      </Grid>

      {/* Save Button */}
      <Box mt={3}>
        <Button fullWidth variant="contained" color="primary" className={classes.saveButton} onClick={checkUpsert}>
          <FormattedMessage id={getSourceLegend()} />
        </Button>
      </Box>
    </Box>
  );
};

export default UserInformation;
