import React, {
  createContext,
  useState,
  useEffect,
  Context,
} from 'react';
import _ from 'lodash';
import { THEMES } from '../constants';
import { storeSettings } from '../utils/settings';
import { ContextSettings } from '../types';

const defaultSettings: ContextSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
  lang: navigator.language.split('-')[0] ?? 'en',
};

const SettingsContext: Context<ContextSettings> = createContext(defaultSettings);

export function SettingsProvider({ settings, children }: {
  settings: ContextSettings, children: React.ReactNode
}) {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  const changeLanguage = (newLang: string) => {
    handleSaveSettings({ lang: newLang });
  };

  useEffect(() => {
    document.dir = currentSettings.direction || 'ltr';
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
        changeLanguage,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export const SettingsConsumer = SettingsContext.Consumer;
export default SettingsContext;
