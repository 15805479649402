import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { useGoogleLogin } from '@react-oauth/google';

import { FormattedMessage, useIntl } from 'react-intl';
import apiClient from '../../../requests/api/apiClient';
import useStyles from '../styles';
import { ClassNameProps, FetchError } from '../../types';
import authService from '../../../services/authService';
import { loginSuccess, setUserData } from '../../../actions/accountActions';
import { ExtendedRegisterRequest } from '../types';

function LoginForm({
  className,
  setOpenSignUpModal,
  setUserInformation,
  termsAndConditions,
  setOpenTermsAndConditionsModal,
  ...rest
}: ClassNameProps & {
  setOpenSignUpModal: React.Dispatch<React.SetStateAction<boolean>>,
  setUserInformation: React.Dispatch<React.SetStateAction<ExtendedRegisterRequest>>,
  termsAndConditions: boolean,
  setOpenTermsAndConditionsModal: React.Dispatch<React.SetStateAction<boolean>>,
}) {
  const intl = useIntl();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = async (email: string) => {
    if (!email) {
      enqueueSnackbar('Debe ingresar un email.', { variant: 'error' });
      return;
    }

    try {
      const response = await apiClient.recoverPassword.recoverPasswordCreate({ email });
      enqueueSnackbar(response.data.message, { variant: 'error' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const onSuccessGoogleLogin = async (accessToken: string) => {
    try {
      const response = await authService.loginWithGmail(
        accessToken,
      );

      const { gmailInformation, success, user } = response;

      if (success && gmailInformation) {
        // Handle case when user is not created, so I need to create one with additional fields
        setUserInformation({
          name: gmailInformation.given_name,
          lastname: gmailInformation.family_name,
          googleId: gmailInformation.id,
          email: gmailInformation.email,
          nickname: '',
          country: '',
          action: 'googleSignUp',
        });
        setOpenSignUpModal(true);
      } else if (success) {
        dispatch(setUserData(user));
      }
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const termsAndConditionRestriction = () => {
    enqueueSnackbar(intl.messages['login.readTermsConditions.required'] as string, { variant: 'error' });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (info) => onSuccessGoogleLogin(info.access_token),
    // eslint-disable-next-line no-console
    onError: (error) => console.log('Login Failed:', error),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(intl.messages['login.email.invalidFormat'] as string)
          .required(intl.messages['login.email.required'] as string),
        password: Yup.string().max(255).required(intl.messages['login.password.required'] as string),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          if (!termsAndConditions) {
            termsAndConditionRestriction();
            setSubmitting(false);
            return;
          }
          const response = await authService.loginWithEmailAndPassword(
            values.email, values.password,
          );
          if (response.success) {
            dispatch(loginSuccess(response.user));
          }
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className, classes.form)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            color="primary"
            fullWidth
            helperText={touched.email && errors.email}
            label={intl.messages['login.email']}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.email}
            variant="outlined"
            // InputLabelProps={{ style: { fontFamily: 'Poppins, sans-serif' } }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            color="primary"
            helperText={touched.password && errors.password}
            label={intl.messages['login.password']}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            // InputLabelProps={{ style: { fontFamily: 'Poppins, sans-serif' } }}
          />
          <Box mt={2} textAlign='center'>
            <FormControlLabel
              label={<span className={classes.termsAndConditions} onClick={() => setOpenTermsAndConditionsModal(true)}>{intl.messages['login.termsAndConditions']}</span>}
              control={
                <Checkbox
                  checked={termsAndConditions}
                />
              }
            />
          </Box>
          <Box mt={2}>
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              <FormattedMessage id='login.login' />
            </Button>
          </Box>
          <Button
            className={classes.resetPassword}
            onClick={() => handleResetPassword(values.email)}
          >
            <FormattedMessage id='login.forgottenPassword' />
          </Button>
          <Box textAlign='center' display='flex' alignItems='center' justifyContent='center'>
            <Typography variant='body2' className={classes.missingAccountText}><FormattedMessage id='login.missingAccount' /></Typography>
            <Button
              className={classes.signUpButton}
              onClick={() => setOpenSignUpModal(true)}
            >
              <FormattedMessage id='login.signUp' />
            </Button>
          </Box>

          <Box my={2}>
            <Divider>
              <FormattedMessage id='login.or' />
            </Divider>
          </Box>
          <Box className={classes.googleButtonDiv}>
            <Button
              className={classes.googleButton}
              onClick={() => (termsAndConditions
                ? handleGoogleLogin()
                : termsAndConditionRestriction())}
              disabled={isSubmitting}
            >

              <img
                // eslint-disable-next-line global-require
                src={require('../../../assets/images/google_icon.png')}
                alt="Google Icon"
              />

              <Box component="span" ml={1}>
                <FormattedMessage id='login.continueGoogle' />
              </Box>
            </Button>
          </Box>
        </form>
      )
      }
    </Formik >
  );
}

export default LoginForm;
