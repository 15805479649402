/* General Components Styling */
import { Theme, lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  // CustomModal
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // User Information
  userInformationContainer: {
    maxWidth: 600,
    padding: '2rem',
  },
  userInformationRandomAvatarButton: {
    borderRadius: '100px',
    textTransform: 'capitalize',
    fontSize: '10px',
  },
  userInformationRefreshIcon: {
    color: 'white',
    borderRadius: '100px',
    backgroundColor: theme.palette.primary.main,
  },
  userInformationRefreshIconDisabled: {
    color: 'white',
    borderRadius: '100px',
    backgroundColor: lighten(theme.palette.text.primary, 0.5),
  },
  saveButton: {
    borderRadius: '100px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '20px',
    // fontFamily: 'Inter, sans-serif',
  },
  avatarName: {
    fontSize: '18px',
    color: 'black',
    fontWeight: 'bold',
  },
  avatarNickname: {
    fontSize: '15px',
    color: '#828282',
  },
  userInformationTypographyPlaceholder: {
    color: lighten(theme.palette.text.primary, 0.5),
  },
}));

export default useStyles;
