import { makeStyles } from '@mui/styles';
import { darken, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    marginBottom: '20px',
  },
  containerImage: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    padding: '0px 0px 15px 0px',
    margin: '-10px 20px 20px 20px',
    [theme.breakpoints.up('md')]: {
      margin: '20px',
      padding: '0px 20px 15px 5px',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    padding: '15px 0px 15px 0px',
    gap: '14px',
    margin: '20px',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      padding: '15px 20px 15px 5px',
      gap: '20px',
    },
  },
  avatar: {
    width: '40px',
    height: '40px',
    [theme.breakpoints.up('sm')]: {
      width: '80px',
      height: '80px',
    },
  },
  inputContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '9px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px',
    },
  },
  input: {
    width: '100%',
    padding: '0px',
    fontSize: '16px',
    fontFamily: 'Inter',
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '4px 8px',
    },
  },
  iconBox: {
    display: 'flex',
    gap: '8px',
    [theme.breakpoints.up('md')]: {
      gap: '20px',
    },
  },
  iconButton: {
    width: '30px',
    height: '30px',
    padding: '13px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
    '& svg': {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      width: '50px',
      height: '50px',
      '& svg': {
        fontSize: 24,
      },
    },
  },
  iconButtonDisabled: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'rgba(0, 0, 0, 0.4)',
    '&.Mui-disabled': {
      background: '#BDBDBD',
      color: 'white',
    },
  },
  imagePreviewContainer: {
    position: 'relative',
    width: '100%',
    maxHeight: '300px',
    borderRadius: '5px',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
  },
  imagePreview: {
    width: '100%',
    maxHeight: '300px',
    height: 'auto',
    objectFit: 'contain',
  },
  removeImageButton: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '24px',
    height: '24px',
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.2),
    },
  },
  removeImageIcon: {
    fontSize: '16px',
  },
}));

export default useStyles;
