import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties } from 'react';

const rankingListWidth = '275px';

const individualTimeframeChip = {
  width: '40px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  '&:hover': {
    cursor: 'pointer',
  },
};

const rankingTileContainer = {
  display: 'flex',
  alignItems: 'center',
  padding: '10px 16px',
};

const rankingTileNicknameText = {
  fontFamily: 'Inter, sans-serif',
  fontWeight: 700,
  color: 'black',
  fontSize: '15px',
};

const rankingTileCountryText = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 500,
  color: 'black',
  fontSize: '15px',
};

const rankingTilePortfolioName = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 500,
  color: 'black',
  fontSize: '15px',
};

const rankingTilePosition: CSSProperties = {
  margin: '0px 10px',
  fontSize: '15px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  color: 'black',
};

const rankingTileMobilePosition: CSSProperties = {
  fontFamily: 'Inter, sans-serif',
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: '18.15px',
  textAlign: 'center',
  color: '#BDBDBD',
};

const useStyles = makeStyles((theme: Theme) => ({
  // #region ranking list
  rankingListContainer: {
    width: '94.5vw',
    [theme.breakpoints.up('md')]: {
      width: rankingListWidth,
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  rankingListChipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: rankingListWidth,
  },
  rankingListTitleBalanceAndFilterBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
  },
  rankingListTitleBalanceBox: {
    display: 'flex',
    color: theme.palette.text.secondary,
    alignItems: 'center',
  },
  rankingListTitleIcon: {
    marginRight: '8px',
  },
  rankingListCardLeaderBoard: {
    borderRadius: 5,
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0)',
    maxHeight: '1000px',
    overflowY: 'auto',
  },
  rankingListCardContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      padding: '0px !important',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflowX: 'auto',
      padding: '20px 0px 20px 20px !important',
    },
  },
  individualTimeframeChip: {
    ...individualTimeframeChip,
  },
  activeIndividualTimeframeChip: {
    ...individualTimeframeChip,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    transition: theme.transitions.create(['background-color', 'filter'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
      filter: 'brightness(90%)',
    },
  },
  // #endregion
  // #region ranking tile
  rankingTileContainer: {
    ...rankingTileContainer,
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  rankingActiveTileContainer: {
    ...rankingTileContainer,
    borderLeft: '3px solid',
    borderLeftColor: theme.palette.primary.main,
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  rankingTilenameAndTextContainer: {
    display: 'flex',
    marginBottom: '10px',
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
      marginTop: '0px',
    },
  },
  rankingTileAvatar: {
    width: 94,
    height: 94,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: 'auto',
    },
  },
  rankingTileNicknameText: {
    ...rankingTileNicknameText,
  },
  rankingTileActiveNicknameText: {
    ...rankingTileNicknameText,
    color: theme.palette.primary.main,
  },
  rankingTileBalanceContainer: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  rankingTileCountryText: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      ...rankingTileCountryText,
      display: 'flex',
    },
  },
  rankingTileMobilePosition: {
    ...rankingTileMobilePosition,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  rankingActiveTileMobilePosition: {
    ...rankingTileMobilePosition,
    color: theme.palette.primary.main,
  },
  rankingTileMobilePositionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  rankingTileActiveCountryText: {
    ...rankingTileCountryText,
    color: theme.palette.primary.main,
  },
  verticalDivider: {
    border: '1.5px solid',
    borderColor: theme.palette.text.primary,
    margin: '0px 5px',
  },
  purpleVerticalDivider: {
    border: '1.5px solid',
    borderColor: theme.palette.text.secondary,
    margin: '0px 5px',
  },
  rankingTileTextContainer: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column',
  },
  rankingTileBalance: {
    fontSize: '15px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    minWidth: '100px',
  },
  rankingTilePercentage: {
    fontSize: '15px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  rankingTilePortfolioName: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      ...rankingTilePortfolioName,
    },
  },
  rankingTileActivePortfolioName: {
    ...rankingTilePortfolioName,
    color: theme.palette.primary.main,
  },
  rankingTilePosition: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      ...rankingTilePosition,
    },
  },
  rankingActiveTilePosition: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      ...rankingTilePosition,
      color: theme.palette.primary.main,
    },
  },
  rankingListTitle: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  // #endregion
}));

export default useStyles;
