/* eslint no-param-reassign: ["error", { "props": false }] */
import produce, { Draft } from 'immer';
import { CustomTimeFrameAction, TimeFrameState } from '../types';
import { SET_TIME_FRAME } from '../actions/timeFrameActions';

const initialState: TimeFrameState = {
  selectedTimeFrame: '1d',
};

const timeFrameReducer = (state = initialState, action: CustomTimeFrameAction): TimeFrameState => {
  switch (action.type) {
    case SET_TIME_FRAME: {
      return produce(state, (draft: Draft<TimeFrameState>) => {
        // Ensure we clear current session
        draft.selectedTimeFrame = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default timeFrameReducer;
