import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import {
  Folder,
  Home,
  Message,
  Notifications,
  Paid,
  Person,
  PlayCircle,
  ShowChart,
} from '@mui/icons-material';
import { useIntl } from 'react-intl';
import useStyles from './styles';

const Navigation = () => {
  const intl = useIntl();
  const classes = useStyles();

  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <Tabs value={tab}
      onChange={handleChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: 'white',
          height: '2px',
        },
      }}
    >
      <Tab className={classes.individualTab} icon={<Home/>} label={intl.messages['topbar.navigation.home']}></Tab>
      <Tab className={classes.individualTab} icon={<PlayCircle />} label={intl.messages['topbar.navigation.video']}></Tab>
      <Tab className={classes.individualTab} icon={<Folder />} label={intl.messages['topbar.navigation.myPortfolio']}></Tab>
      <Tab className={classes.individualTab} icon={<Paid />} label={intl.messages['topbar.navigation.assets']}></Tab>
      <Tab className={classes.individualTab} icon={<Person />} label={intl.messages['topbar.navigation.network']}></Tab>
      <Tab className={classes.individualTab} icon={<ShowChart />} label={intl.messages['topbar.navigation.ranking']}></Tab>
      <Tab className={classes.individualTab} icon={<Message />} label={intl.messages['topbar.navigation.messages']}></Tab>
      <Tab className={classes.individualTab} icon={<Notifications />} label={intl.messages['topbar.navigation.notifications']}></Tab>
    </Tabs>
  );
};

export default Navigation;
