import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import { CombinedStateType } from '../types';
import timeFrameReducer from './timeFrameReducer';

const rootReducer: Reducer<CombinedStateType> = combineReducers({
  account: accountReducer,
  timeFrame: timeFrameReducer,
  form: formReducer,
});

export default rootReducer;
