import {
  Box,
  Typography,
  Divider,
  Avatar,
} from '@mui/material';
import { EmojiEvents } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ReduxState } from '../../types';
import useStyles from './styles';
import { currencyFormatter } from '../../utils/usefulFormatters';
import getOrdinalSuffix from '../../utils/translation';

// TO DO - revisar supuesto nuevamente
const INITIAL_BALANCE = 100000;

const RankingTile = ({
  id,
  userInfo,
  balance,
  change,
  position,
  portfolioName,
}: {
  id: string,
  userInfo: {
    id: string;
    avatarUrl?: string;
    nickname: string;
    country: string;
  },
  balance: number,
  change: number,
  position: number,
  portfolioName: string,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { user } = useSelector((state: ReduxState) => state.account);

  const colorToDisplay = balance > INITIAL_BALANCE ? '#00D293'
    : [balance < INITIAL_BALANCE ? '#D42F2F' : '#000000'];

  return (
    <Box
      key={id}
      className={userInfo.id === user?.id
        ? classes.rankingActiveTileContainer
        : classes.rankingTileContainer
      }
    >
      {userInfo.avatarUrl && <Avatar
        alt={`${userInfo.nickname}-profile-image`}
        src={userInfo.avatarUrl}
        sx={{ width: 30, height: 30 }}
        className={classes.rankingTileAvatar}
        />
      }
      <Typography
        className={userInfo.id === user?.id
          ? classes.rankingActiveTilePosition
          : classes.rankingTilePosition
        }
      >
        {`# ${position}`}
      </Typography>
      <Box
        className={classes.rankingTileTextContainer}
      >
        <Box className={classes.rankingTilenameAndTextContainer}>
          <Typography className={userInfo.id === user?.id
            ? classes.rankingTileActiveNicknameText
            : classes.rankingTileNicknameText
          }>
            {/* TO DO - ver caso donde yo sea el del ranking */}
            {userInfo.id === user?.id ? '@Me - ' : `@${userInfo.nickname} `}
          </Typography>
          <Typography
            className={userInfo.id === user?.id
              ? classes.rankingTileActiveCountryText
              : classes.rankingTileCountryText}
          >
            {` - ${userInfo.country.slice(0, 3).toUpperCase()}`}
          </Typography>
        </Box>
        <Box>
          <Typography className={userInfo.id === user?.id
            ? classes.rankingTileActivePortfolioName
            : classes.rankingTilePortfolioName
          }>
            {portfolioName}
          </Typography>
        </Box>
        <Box className={classes.rankingTileBalanceContainer}>
          <Box display='flex'>
            <Typography
              color={colorToDisplay}
              className={classes.rankingTileBalance}
            >
              {currencyFormatter(balance)}
            </Typography>
            <Divider
              className={userInfo.id === user?.id
                ? classes.purpleVerticalDivider
                : classes.verticalDivider
              }
              orientation='vertical'
            />
            <Typography
              color={colorToDisplay}
              className={classes.rankingTilePercentage}
            >
              {change === 0 && ` ${(change * 100).toFixed(0)}%`}
              {change > 0 && `+${(change * 100).toFixed(0)}%`}
              {change < 0 && `${(change * 100).toFixed(0)}%`}
            </Typography>
          </Box>

        </Box>
        <Box className={classes.rankingTileMobilePositionContainer}>
          <EmojiEvents sx={{ color: '#F1821BA3', fontSize: '13px' }}/>
          <Typography
            className={userInfo.id === user?.id
              ? classes.rankingActiveTileMobilePosition
              : classes.rankingTileMobilePosition
            }
          >
            {getOrdinalSuffix(position, intl.locale.startsWith('es') ? 'es' : 'en')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RankingTile;
