import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Button,
  Typography,
} from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import useStyles from './styles';
import { NavbarProps } from './types';
import useSettings from '../../../../hooks/useSettings';

const backgroundImage = require('../../../../assets/images/background-login-logo-purple.jpg');

const Navbar: React.FC<NavbarProps> = ({ links }) => {
  const classes = useStyles();
  const { settings, changeLanguage } = useSettings();
  const [mobileOpen, setMobileOpen] = useState(false);

  const scrollToSection = (href: string) => {
    const section = document.querySelector(href);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLanguage = (selectedLang: 'es' | 'en' | 'pr') => {
    if (changeLanguage) {
      changeLanguage(selectedLang);
    }
  };

  const drawerContent = (
    <Box className={classes.drawerContent}>
      <Box className={classes.logoContainer}>
        <img
          className={classes.logo}
          alt="Logo"
          // eslint-disable-next-line global-require
          src={require('../../../../assets/images/logo.png')}
        />
      </Box>
      <Box
        onClick={handleDrawerToggle}>
        <Box className={classes.iconButtonContainer}>
          <IconButton>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>
        <List className={classes.list}>
          {links.map((link) => (
            <ListItem key={link.label} onClick={() => scrollToSection(link.href)}>
              <ListItemText
                primary={link.label}
                primaryTypographyProps={{
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                  textAlign: 'end',
                  paddingBottom: '30px',
                }}
              />
            </ListItem>
          ))}
          <ListItem sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
            <ListItemText
              onClick={() => handleLanguage('en')}
              primary='EN'
              primaryTypographyProps={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
              }}
              className={settings?.lang === 'en' ? classes.mobileNavButtonLanguageActiveOption : classes.mobileNavButtonLanguageOption}
            />
            <ListItemText
              primary='|'
              primaryTypographyProps={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
                textAlign: 'end',
                paddingBottom: '30px',
              }}
            />
            <ListItemText
              onClick={() => handleLanguage('es')}
              primary='ES'
              primaryTypographyProps={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
              }}
              className={settings?.lang === 'es' ? classes.mobileNavButtonLanguageActiveOption : classes.mobileNavButtonLanguageOption}
            />
            <ListItemText
              primary='|'
              primaryTypographyProps={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
                textAlign: 'end',
                paddingBottom: '30px',
              }}
            />
            <ListItemText
              onClick={() => handleLanguage('pr')}
              primary='PT'
              primaryTypographyProps={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
              }}
              className={settings?.lang === 'pr' ? classes.mobileNavButtonLanguageActiveOption : classes.mobileNavButtonLanguageOption}
            />
          </ListItem>
        </List>
      </Box>
    </Box >
  );

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logo}>
            <img
              className={classes.logo}
              alt="Logo"
              // eslint-disable-next-line global-require
              src={require('../../../../assets/images/logo.png')}
            />
          </Box>
          <Hidden mdDown>
            <Box className={classes.navLinks}>
              {links.map((link) => (
                <Button key={link.label} className={classes.navButton}
                  onClick={() => scrollToSection(link.href)}>
                  <Typography className={classes.navButtonText}>
                    {link.label}
                  </Typography>
                </Button>
              ))}

              <Typography className={classes.navButtonLanguageChoice}>
                <Box className={settings?.lang === 'en' ? classes.navButtonLanguageActiveOption : classes.navButtonLanguageOption}
                  component='span' mr={0.5} key='locale-button-en'
                  onClick={() => handleLanguage('en')}>EN</Box>
                |<Box className={settings?.lang === 'es' ? classes.navButtonLanguageActiveOption : classes.navButtonLanguageOption}
                  component='span' mx={0.5} key='locale-button-es'
                  onClick={() => handleLanguage('es')}>ES</Box>
                |<Box className={settings?.lang === 'pr' ? classes.navButtonLanguageActiveOption : classes.navButtonLanguageOption}
                  component='span' ml={0.5} key='locale-button-pr'
                  onClick={() => handleLanguage('pr')}>PT</Box>
              </Typography>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon className={classes.menuIcon}
              />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          sx: {
            // eslint-disable-next-line global-require
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            boxShadow: 'none',
            width: '100%',
            color: 'white',
          },
        }}
        hideBackdrop={true}
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Navbar;
