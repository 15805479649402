/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ReduxState } from '../../../../types';
import apiClient from '../../../../requests/api/apiClient';
import useStyles from './styles';
import { GetCommentsByPostResponse } from '../../../../requests/api/apiTypes';

type ExtendedCommentType = Pick<GetCommentsByPostResponse, 'comments'> & {
  handleUpdatePost: () => void;
};

const CommentList: React.FC<ExtendedCommentType> = ({ comments, handleUpdatePost }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { user } = useSelector((state: ReduxState) => state.account);
  const { enqueueSnackbar } = useSnackbar();

  const [replyContents, setReplyContents] = useState<{ [key: string]: string }>({});
  const [visibleReplyBox, setVisibleReplyBox] = useState<{ [key: string]: boolean }>({});

  const handleReplyChange = (
    commentId: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setReplyContents((prev) => ({
      ...prev,
      [commentId]: event.target.value,
    }));
  };

  const handleSendReply = async (commentId: string) => {
    const replyContent = replyContents[commentId];
    if (!replyContent || !replyContent.trim()) return;

    try {
      const payload = {
        userId: user?.id,
        content: replyContent,
        parentCommentId: commentId,
      };
      await apiClient.comment.commentCreate(payload);
      handleUpdatePost();
      setReplyContents((prev) => ({
        ...prev,
        [commentId]: '',
      }));
      setVisibleReplyBox((prev) => ({
        ...prev,
        [commentId]: false,
      }));
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'post.error.sendReply' }), { variant: 'error' });
    }
  };

  const toggleReplyBox = (commentId: string) => {
    setVisibleReplyBox((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const handleReplyToReply = (commentId: string, nickname: string) => {
    setReplyContents((prev) => ({
      ...prev,
      [commentId]: `${prev[commentId] || ''}@${nickname} `,
    }));
    setVisibleReplyBox((prev) => ({
      ...prev,
      [commentId]: true,
    }));
  };
  const renderReplyContent = (content: string) => content.split(' ').map((word, index) => (
    word.startsWith('@') ? <strong key={index}>{word} </strong> : `${word} `
  ));

  return (
    <Box className={classes.mainContainer}>
      {comments && comments.map((comment, index) => (
        <Box key={index} className={classes.container}>
          <Box className={classes.userInfo}>
            <Avatar className={classes.avatar} src={comment.userDetails?.avatarUrl || ''} />
            <Box className={classes.commentContainer}>
              <Typography className={classes.username}>
                {comment.userDetails?.name} {comment.userDetails?.lastname}
              </Typography>
              <Typography className={classes.nickname}>
                @{comment.userDetails?.nickname}
              </Typography>
              <Typography className={classes.content}>
                {comment.content}
              </Typography>
              <Button className={classes.replyComment} onClick={() => toggleReplyBox(comment._id)}>
                Responder
              </Button>
              {comment.replies.map((reply, i) => (
                <Box key={i} className={classes.userInfo} sx={{ marginTop: '10px' }}>
                  <Avatar className={classes.avatar} src={reply.replyUserDetails?.avatarUrl || ''} />
                  <Box className={classes.commentContainer}>
                    <Typography className={classes.username}>
                      {reply.replyUserDetails?.name} {reply.replyUserDetails?.lastname}
                    </Typography>
                    <Typography className={classes.nickname}>
                      @{reply.replyUserDetails?.nickname}
                    </Typography>
                    <Typography className={classes.content}>
                      {renderReplyContent(reply.content)}
                    </Typography>
                    <Button
                      className={classes.replyComment}
                      onClick={() => handleReplyToReply(
                        comment._id,
                        reply.replyUserDetails?.nickname ?? 'undefined',
                      )}
                    >
                      Responder
                    </Button>
                  </Box>
                </Box>
              ))}
              {visibleReplyBox[comment._id] && (
                <Box className={classes.userInfo} sx={{ alignItems: 'center' }}>
                  <Avatar className={classes.avatar} src={user?.avatarUrl || ''} />
                  <TextField
                    size='small'
                    inputProps={{ style: { fontSize: '15px', fontFamily: 'Inter' } }}
                    className={classes.input}
                    value={replyContents[comment._id] || ''}
                    onChange={(e) => handleReplyChange(comment._id, e)}
                    multiline
                  />
                  <IconButton
                    className={`${classes.iconButton} ${!(replyContents[comment._id]?.trim()) ? classes.iconButtonDisabled : ''}`}
                    aria-label="send"
                    disabled={!(replyContents[comment._id]?.trim())}
                    onClick={() => handleSendReply(comment._id)}
                  >
                    <Send sx={{ fontSize: '15px' }} />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CommentList;
