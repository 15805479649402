import React from 'react';
import {
  Typography, Box, Avatar, Divider,
} from '@mui/material';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { ReduxState } from '../../../../types';

const ProfileCard: React.FC = () => {
  const classes = useStyles();
  const account = useSelector((state: ReduxState) => state.account);

  return (
    <Box className={classes.card}>
      <Avatar
        src={account.user?.avatarUrl}
        alt={account.user?.name}
        className={classes.avatar}
      />
      <Typography className={classes.name}>
        {account.user?.name} {account.user?.lastname}
      </Typography>
      <Typography className={classes.username}>
        @{account.user?.nickname}
      </Typography>
      <Typography className={classes.rank}>
        #3 on Global TiL ranking
      </Typography>
      <Divider className={classes.divider} />

      <Typography className={classes.sectionTitle}>
        Portfolio Simulation
      </Typography>
      <Typography className={classes.purpleNumber}>
        $ 55,194.77
      </Typography>

      <Typography className={classes.sectionTitle}>
        All time Performance (230 days)
      </Typography>
      <Typography className={classes.purpleNumber}>
        -4%
      </Typography>

      <Typography className={classes.sectionTitle}>
        S&P (230 Days)
      </Typography>
      <Typography className={classes.purpleNumber}>
        -23.88%
      </Typography>
    </Box>
  );
};

export default ProfileCard;
