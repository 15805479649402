import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Icon,
  Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';
import RankingTile from './RankingTile';
import { ReduxState, TimeFrameOptions } from '../../types';
import { setTimeFrame } from '../../actions/timeFrameActions';
import { GetPortfoliosForRankingResponse } from '../../requests/api/apiTypes';

const CHIPS_OPTIONS: TimeFrameOptions[] = ['1d', '1w', '1m', '1y', 'TIL'];

function RankingList({
  title,
  titleIcon,
  portfolios,
}: {
  title?: string,
  titleIcon?: JSX.Element,
  portfolios: GetPortfoliosForRankingResponse['portfolios'],
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedTimeFrame } = useSelector((state: ReduxState) => state.timeFrame);

  const handleTimeFrameChange = (timeFrame: TimeFrameOptions) => {
    dispatch(setTimeFrame(timeFrame));
  };

  return (
    <Box className={classes.rankingListContainer}>
      <Box className={classes.rankingListTitleBalanceAndFilterBox}>
        {title && <Box className={classes.rankingListTitleBalanceBox}>
          {titleIcon && <Icon className={classes.rankingListTitleIcon}>
            {titleIcon}
          </Icon>}
          <Typography
            className={classes.rankingListTitle}
          >
            {title}
          </Typography>
        </Box>}
        <Box display='flex' marginTop='14px'>
          <Box className={classes.rankingListChipContainer}>
            {CHIPS_OPTIONS.map((tFrame) => <Chip
              onClick={() => handleTimeFrameChange(tFrame)}
              label={tFrame.toUpperCase()}
              key={tFrame}
              size='small'
              className={selectedTimeFrame === tFrame
                ? classes.activeIndividualTimeframeChip
                : classes.individualTimeframeChip
              }
            />)}
          </Box>
        </Box>
      </Box>
      <Box>
        <Card className={classes.rankingListCardLeaderBoard}>
          <CardContent className={classes.rankingListCardContent}>
            {portfolios && portfolios.length > 0 && portfolios.map((data, index) => <RankingTile
              key={data.id}
              id={data.id}
              portfolioName={data.name}
              userInfo={data.user}
              balance={data.balance}
              change={data.change}
              position={index + 1}
            />)}
            {portfolios && portfolios.length === 0 && <Box padding='16px'>
              <Typography variant='body1'><FormattedMessage id="rankingList.noPortfoliosFound" /></Typography>
              </Box>}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default RankingList;
