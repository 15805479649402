import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import useStyles from './styles';
import useFetchApi from '../../../../hooks/useFetchApi';
import { GetAssetsForBenchmarkResponse } from '../../../../requests/api/apiTypes';

const PortfolioModalContent = ({
  handleCreatePortfolio,
}: { handleCreatePortfolio: (name: string, benchmarkId: string) => void }) => {
  const classes = useStyles();
  const [portfolioInfo, setPortfolioInfo] = useState({
    name: '',
    benchmarkId: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPortfolioInfo({ ...portfolioInfo, [e.target.name]: e.target.value });
  };

  const { data } = useFetchApi<GetAssetsForBenchmarkResponse>({
    modelEndpoint: 'assetsBenchmarkList',
    modelName: 'assetsBenchmark',
  });

  const benchmarkOptions = data?.assets.map((benchmark) => ({
    id: benchmark.id,
    name: benchmark.name,
  }));

  return (
    <Box>
      <Typography variant='h2' className={classes.portfolioModalContentTitle}><FormattedMessage id='home.portfolio.createPortfolio' /></Typography>
      <Typography className={classes.portfolioModalContentSubtitle}>
        <FormattedMessage id='home.portfolio.createPortfolioSubtitle' />
      </Typography>
      <Divider className={classes.portfolioModalContentDivider} />
      <TextField
        label={<FormattedMessage id='home.portfolio.portfolioName' />}
        variant='outlined'
        fullWidth
        sx={{ marginBottom: '15px' }}
        name='name'
        value={portfolioInfo.name}
        onChange={handleChange}
      />
      <TextField
        label={<FormattedMessage id='home.portfolio.benchmark' />}
        variant='outlined'
        fullWidth
        select
        name='benchmarkId'
        value={portfolioInfo.benchmarkId}
        onChange={handleChange}
      >
        {benchmarkOptions ? benchmarkOptions.map((benchmark) => (
          <MenuItem key={benchmark.id} value={benchmark.id}>{benchmark.name}</MenuItem>
        )) : <MenuItem disabled><FormattedMessage id='home.portfolio.noBenchmarks' /></MenuItem>}
      </TextField>
      <Box className={classes.portfolioModalContentButtonContainer}>
        <Button
          fullWidth
          variant='contained'
          color='primary'
          onClick={() => handleCreatePortfolio(portfolioInfo.name, portfolioInfo.benchmarkId)}
          className={classes.portfolioModalContentButton}>
          <FormattedMessage id='home.portfolio.createPortfolio' />
        </Button>
      </Box>
    </Box>
  );
};

export default PortfolioModalContent;
