import React, { useEffect, useState } from 'react';
import {
  Typography, Box, Avatar,
  AvatarGroup,
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import useStyles from './styles';
import { ReduxState } from '../../../../types';
import apiClient from '../../../../requests/api/apiClient';
import { GetUserGroupsRankingResponse } from '../../../../requests/api/apiTypes';
import getOrdinalSuffix from '../../../../utils/translation';

const GroupRanking: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const intl = useIntl();
  const [groupRanking, setGroupRanking] = useState<GetUserGroupsRankingResponse['groups'] | undefined>();
  const { user } = useSelector((state: ReduxState) => state.account);

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const payload = {
          userId: user?.id,
        };
        const { data } = await apiClient.groups.userGroupsRankingList(payload);
        setGroupRanking(data.groups);
      } catch (err) {
        enqueueSnackbar(intl.formatMessage({ id: 'groups.error' }), { variant: 'error' });
      }
    };
    fetchGroup();
  }, []);

  if (!groupRanking || groupRanking.length === 0) {
    return null;
  }

  return (
    <Box className={classes.card}>
      {groupRanking.map((group, index) => {
        const rankingPlace = getOrdinalSuffix(1, intl.locale.startsWith('es') ? 'es' : 'en');
        return (
          <Box key={index}>
            <Box className={classes.rankingContainer}>
              <AvatarGroup className={classes.avatarGroup} spacing={50}>
                {group.members?.map((member, i) => (
                  <Avatar key={i} className={classes.avatar} src={member.avatarUrl}/>
                ))}
              </AvatarGroup>
            </Box>
            <Typography className={classes.groupName}>
              {group.name}
            </Typography>
            <Box className={classes.rankingContainer}>
              <EmojiEventsIcon sx={{ color: '#F1821BA3', fontSize: '13px' }}/>
              <Typography className={classes.rankingPosition}>
                {rankingPlace} {intl.formatMessage({ id: 'groups.of' })} {group.groupSize}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default GroupRanking;
