import React, { useState } from 'react';
import {
  Avatar,
  IconButton,
  Box,
  TextField,
} from '@mui/material';
import { Send, Image, Close } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ReduxState } from '../../../../types';
import apiClient from '../../../../requests/api/apiClient';
import useStyles from './styles';

const CreatePost: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [text, setText] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { user } = useSelector((state: ReduxState) => state.account);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (validImageTypes.includes(file.type)) {
        setSelectedImage(file);
      } else {
        enqueueSnackbar(intl.formatMessage({ id: 'post.error.invalidImage' }), {
          variant: 'error',
        });
      }
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    const fileInput = document.getElementById('image-upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleSend = async () => {
    try {
      if (text.trim()) {
        const payload = {
          ...(selectedImage && { file: selectedImage }),
          data: JSON.stringify({
            userId: user?.id,
            text,
          }),
        };
        const { data } = await apiClient.posts.postsCreate(payload);
        enqueueSnackbar(intl.formatMessage({ id: data.success ? 'post.success.published' : 'post.error.publishFailed' }), { variant: 'success' });
        setSelectedImage(null);
        setText('');
      }
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({ id: 'post.error.publishFailed' }), { variant: 'success' });
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Avatar className={classes.avatar} src={user?.avatarUrl || ''} />
        <Box className={classes.inputContainer}>
          <TextField
            className={classes.input}
            placeholder="Add post..."
            inputProps={{ 'aria-label': 'Add post' }}
            multiline
            value={text}
            onChange={handleChange}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            id="image-upload"
            style={{ display: 'none' }}
          />
          <Box className={classes.iconBox}>
            <IconButton
              className={classes.iconButton}
              aria-label="upload picture"
              component="label"
              htmlFor="image-upload"
            >
              <Image />
            </IconButton>
            <IconButton
              className={`${classes.iconButton} ${!text.trim() ? classes.iconButtonDisabled : ''}`}
              aria-label="send"
              disabled={!text.trim()}
              onClick={handleSend}
            >
              <Send />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box>
    </Box>
      {selectedImage && (
        <Box className={classes.containerImage}>
          <Box className={classes.imagePreviewContainer}>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              className={classes.imagePreview}
            />
            <IconButton
              className={classes.removeImageButton}
              aria-label="remove image"
              onClick={handleRemoveImage}
            >
              <Close className={classes.removeImageIcon}/>
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreatePost;
