import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import Post from '../../components/Post';
import Page from '../../components/Layout/Page';
import useFetchApi from '../../hooks/useFetchApi';
import { GetPostByIdResponse } from '../../requests/api/apiTypes';
import useStyles from './styles';

const SharePostView = () => {
  const { postId } = useParams<{ postId: string }>();
  const intl = useIntl();
  const classes = useStyles();

  const { data } = useFetchApi<GetPostByIdResponse>({
    modelEndpoint: 'postsDetail',
    modelName: 'posts',
    id: postId,
  });

  const localPost = data?.post;

  return (
    <Page
      title={`TiL | ${intl.formatMessage({ id: 'share.title' })}`}
    >
      <Box className={classes.shareContainerWrapper}>
      <Box className={classes.shareContainer}>
        {localPost && localPost && <Post
          key={localPost.id}
          postInfo={localPost}
          numOfComments={localPost.commentCount}
          numOfLikes={localPost.likeCount}
          sharedView={true}
        />}
        {(localPost === undefined
          || localPost === null) && <Box>No post found</Box>}
      </Box>
      </Box>

    </Page>
  );
};

export default SharePostView;
